import { CSSProperties } from 'react';
import { COLORS, SIZES } from '../../style';
import { IButtonStyles, IPersonaStyles } from '@fluentui/react';

export const resultRowStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  paddingLeft: '4px',
};

export const resultRowContentContainerStyle: CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  width: '100%',
};

export const resultRowContentStyle: CSSProperties = {
  fontSize: SIZES.defaultFontSize,
  backgroundColor: 'transparent',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginLeft: '4px',
  width: '100%',
};

export const centerDivStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
};

export const resultRowContainerStyle = (isHovered: boolean): CSSProperties => ({
  display: 'flex',
  alignItems: 'center',
  borderTop: `1px solid ${COLORS.lightGray}`,
  height: '32px',
  width: '100%',
  backgroundColor: isHovered ? COLORS.lightGray : 'white',
});

export const resultRowIconButtonStyle: IButtonStyles = {
  root: {
    backgroundColor: 'transparent',
    borderRadius: 'none',
    height: '32px',
    width: '32px',
  },
  rootHovered: {
    backgroundColor: COLORS.lightestGray,
  },
  rootPressed: {
    backgroundColor: COLORS.lightGray,
  },
  rootDisabled: {
    backgroundColor: 'transparent',
  },
};

export const buttonIconStyle = (disabled: boolean): CSSProperties => ({
  color: 'gray',
  opacity: disabled ? '0.25' : 1,
});

export const showMoreContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  width: '100%',
  height: SIZES.searchBarHeight,
  backgroundColor: 'white',
};

export const showMoreIconStyle: CSSProperties = {
  color: 'gray',
  right: '3px',
  top: '0.5px',
  position: 'absolute',
  fontWeight: 'bold',
  fontSize: '5px',
};

export const showMoreStyles: IButtonStyles = {
  root: {
    backgroundColor: COLORS.lightGray,
    color: COLORS.globalGrayColor,
    height: `${parseInt(SIZES.searchBarHeight, 10) / 2}px`,
    width: '70px',
    fontSize: '9px',
    marginTop: '16px',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '3px 3px 0 0',
  },
  rootHovered: {
    backgroundColor: COLORS.lightGray,
    color: COLORS.globalGrayColor,
  },
  rootPressed: {
    backgroundColor: COLORS.lightGray,
    color: COLORS.globalGrayColor,
    paddingRight: '0px',
  },
};

export const noResultsStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: SIZES.searchBarHeight,
  backgroundColor: 'white',
  fontSize: '12px',
  color: 'gray',
};

export const resultPersonaStyle: Partial<IPersonaStyles> = {
  root: {
    width: '20px',
  },
  primaryText: {
    display: 'none',
  },
};
