import { getConfig } from '../config';
import { BillingTerms } from '../consts';
import { PricePlan1, PricePlan2, PricePlan3, PricePlan4 } from '../pages/Pricing/data';

const { clientUrl, checkoutUrl } = getConfig();

interface IRedirectToCheckoutParams {
  email: string;

  serialNumber: string;

  billingTerm: string;
}

export const redirectToCheckout = ({
  email,
  serialNumber,
  billingTerm
}: IRedirectToCheckoutParams) => {
  const url = `#/purchase?sn=${encodeURIComponent(
    serialNumber
  )}&billingTerm=${encodeURIComponent(billingTerm)}&email=${encodeURIComponent(
    email
  )}&returnUri=${encodeURI(`${clientUrl}`)}`;

  window.location.assign(`${checkoutUrl}web/${url}`);
  // window.open(`${checkoutUrl}web/${url}`);
  // window.location.href = '/';
};

const MONTHLY_RECURRING_MONTHS = 1;
const ANNUAL_RECURRING_MONTHS = 12;

export const getBillingTerm = (recurringMonths: number) => {
  switch (recurringMonths) {
    case MONTHLY_RECURRING_MONTHS:
      return BillingTerms.MONTHLY;
    case ANNUAL_RECURRING_MONTHS:
      return BillingTerms.ANNUAL;
    default:
      return BillingTerms.MONTHLY;
  }
};

export const getCurrentPlanLevel = (
  recurringMonths: number,
  isTrial = false
) => {
  if (isTrial) return PricePlan1.level;
  if (recurringMonths === MONTHLY_RECURRING_MONTHS) return PricePlan2.level;
  if (recurringMonths === ANNUAL_RECURRING_MONTHS) return PricePlan3.level;

  return PricePlan4.level;
};

export const getPlanLevelFromStorage = () => {
  return Number(localStorage.getItem('planLevel')) || 0;
};
