/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-react';
import { History, Location } from 'history';
// @ts-ignore
import { NotificationManager } from 'react-notifications';
import { CONTACT_US, DestineLocations } from '../components/Modals/constants';
import { closeModal, getReturnUri, redirectMobileAction, setModal } from './appReducer';
import { DOCUMENT_TITLES } from './constants';
import { AppDispatch } from './store';
import mixpanelService from '../services/MixpanelService';

export const createNotification = (text: string, type: string) => {
  mixpanelService.sendCustomEvent('NOTIFICATION_ERROR', { ErrorType: type, ErrorMessage: text });
  switch (type) {
    case 'info':
      return NotificationManager.info(text, 5000);
    case 'success':
      return NotificationManager.success(text, 5000);
    case 'warning':
      return NotificationManager.warning(text, 5000);
    case 'error':
      return NotificationManager.error(text, 'Error', 5000);
  }
};

export const clearUrlSearch = (
  location: Location,
  history: History,
  deleteParam: 'returnUri' | 'modalType',
  width?: number
): void => {
  const queryParams = new URLSearchParams(location.search);

  if (deleteParam === 'returnUri') {
    queryParams.delete('returnUri');
    history.replace({
      search: queryParams.toString()
    });
  }

  if (deleteParam === 'modalType' && width && width > 768) {
    history.replace({
      state: queryParams.toString(),
      search: location.search
    });
  }
};

export const getModalTypes = (): {
  contactUs: string;
} => {
  return {
    contactUs: CONTACT_US
  };
};

export const getCurrentModalType = (
  pathname: string,
  isMobile: boolean,
  modalType: string,
  dispatch: AppDispatch
) => {
  const modalTypes = getModalTypes();

  if (isMobile && !modalType && pathname) {
    const modalTypeKey = pathname.replace('/', '');

    const currentModalType =
      modalTypes[
        modalTypeKey as keyof {
          contactUs: string;
        }
      ];

    if (currentModalType) {
      const currentActionName = getActionFromType(currentModalType);

      dispatch(
        setModal({
          type: currentModalType,
          action: currentActionName
        })
      );
    }
  }
};

export const handleModalResizing = (
  dispatch: AppDispatch,
  location: Location,
  history: History,
  routerAction: string,
  isMobile: boolean,
  modalType: string
) => {
  const backClicked = routerAction === 'POP' ?? false;

  if (backClicked) {
    clearUrlSearch(location, history, 'returnUri');

    history.push(location.pathname);
    dispatch(closeModal());
  }

  if (!backClicked && isMobile && modalType) {
    dispatch(
      // @ts-ignore
      redirectMobileAction(
        Object(DestineLocations)[modalType],
        true,
        window.pageYOffset
      )
    );
  }
};

export const handleErrorReturn = (
  location: Location,
  history: History,
  logout: (options?: LogoutOptions | undefined) => void,
  loginWithRedirect: (
    options?: RedirectLoginOptions | undefined
  ) => Promise<void>,
  dispatch: AppDispatch,
  width: number,
  retryLogin?: boolean,
  currentModalType?: string
) => {
  // Handle return from error page after failed login attempt
  if (retryLogin) {
    logout({ returnTo: window.location.origin });
    loginWithRedirect();
  }

  // Re-open modal if coming back from error page
  if (currentModalType) {
    setTimeout(() => {
      dispatch(
        setModal({
          type: currentModalType,
          action: getActionFromType(currentModalType)
        })
      );

      clearUrlSearch(location, history, 'modalType');
    }, 500);
  }
};

export const handleRouterAction = (
  routerAction: string,
  isMobile: boolean,
  location: Location,
  history: History
) => {
  const backClicked = routerAction === 'POP' ?? false;
  const isModalRouting = checkIsModalRouting(location.pathname);

  if (backClicked && isModalRouting && !isMobile) {
    const returnUri = getReturnUri(location.search);
    history.push(returnUri!);
  }
};

export const checkIsModalRouting = (pathname: string): boolean => {
  return (
    pathname === '/contactUs'
  );
};

export const showFooter = (pathname: string): boolean => {
  return (
    pathname !== '/contactUs'
  );
};

export const getCurrentSelectedItem = (pathname: string): string =>
  pathname.replace('/', '');
export const getActionFromType = (modalType: string): string =>
  modalType.replace('_', ' ').toLowerCase();

export const toggleScroll = (isMobileMenuOpen?: boolean): void => {
  if (isMobileMenuOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
};

export const generateDocumentTitle = (pathname: string): string => {
  switch (pathname) {
    case '/':
      return DOCUMENT_TITLES.profile;
    case '/integrations/outlook':
      return DOCUMENT_TITLES.outlook;
    case '/integrations/chrome':
      return DOCUMENT_TITLES.chrome;
    case '/integrations/adobe':
      return DOCUMENT_TITLES.adobe;
    case '/integrations/teams':
      return DOCUMENT_TITLES.teams;
    case '/integrations/mobile':
      return DOCUMENT_TITLES.mobile;
    case '/integrations/all':
      return DOCUMENT_TITLES.integrations;
    case '/features':
      return DOCUMENT_TITLES.features;
    case '/about':
      return DOCUMENT_TITLES.aboutUs;
  }
  return '';
};

export const checkOverflow = (
  checkedEl: HTMLAnchorElement | HTMLDivElement | null
) => {
  if (checkedEl === undefined || checkedEl === null) {
    return false;
  }

  const curOverflow = checkedEl.style.overflow;

  if (!curOverflow || curOverflow === 'visible') {
    checkedEl.style.overflow = 'hidden';
  }

  const isOverflow = checkedEl.clientWidth < checkedEl.scrollWidth;
  checkedEl.style.overflow = curOverflow;

  return isOverflow;
};

export default clearUrlSearch;
