import React from 'react';
import { NewOrgInputContainer, WarningMessage } from './style';

export interface OrgInputWrapperProps {
  warningText?: string | null;
  children: React.ReactNode;
}

const OrgInputWrapper: React.FC<OrgInputWrapperProps> = (props) => {
  const { warningText, children } = props;
  return (
    <NewOrgInputContainer>
      {children}
      {warningText && <WarningMessage>{warningText}</WarningMessage>}
    </NewOrgInputContainer>
  );
};

export default OrgInputWrapper;
