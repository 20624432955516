/* eslint-disable import/no-cycle */
import React from 'react';
import { ActionFlexSection, SeparatorSection } from '../../components/Sections';
import { SimpleForm } from '../../components/Forms';
import { AuthorizeOAuthSection } from './AuthorizeOAuthSection';
import { UserCredentialsSection } from './UserCredentialsSection';
import { ConnectionHeaderSection } from './ConnectionHeaderSection';
import { ConnectionSettingsOutputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { IConnectionCredentials } from '../../utils/reducers/connection';
import Button from '../../components/StyledButton';

interface IEasyProjectsAuthorizeForm {
  serviceProvider: ServiceProvider;
  showAuthorizeOAuthForm?: boolean;
  errorOAuth?: unknown;
  showPasswordCredentialsForm?: boolean;
  verifyErrorMessage?: unknown;
  userName?: string;
  password?: string;
  passwordLength?: number;
  editData?: ConnectionSettingsOutputDto;

  connectCredentials?: () => void;
  connectOAuth?: () => void;
  onChange?: (args: IConnectionCredentials | undefined) => void;
}

export const EasyProjectsAuthorizeForm: React.FC<IEasyProjectsAuthorizeForm> = (
  props
) => {
  return (
    <SimpleForm>
      <ConnectionHeaderSection
        {...props}
        userName={props.editData?.userName}
        webRoot={props.editData?.webRoot}
      />
      {props.showAuthorizeOAuthForm && (
        <>
          <AuthorizeOAuthSection {...props} connect={props.connectOAuth} />
          <br />
          <SeparatorSection>or</SeparatorSection>
          <br />
        </>
      )}

      <>
        <UserCredentialsSection
          {...props}
          passwordLength={props.passwordLength}
          label="E-mail"
          onChange={props.onChange}
          connectCredentials={props.connectCredentials}
          secondfieldLabel="Password"
        />
        <ActionFlexSection>
          <Button title="Connect" onClick={props.connectCredentials} />
        </ActionFlexSection>
      </>
    </SimpleForm>
  );
};
