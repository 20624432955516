/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { DefaultButton, Dialog, DialogFooter, IDialogContentProps, IModalProps, PrimaryButton } from '@fluentui/react';
import { RED_COLOR } from '../consts/theme';

const modalProps: IModalProps = {
  isBlocking: false,
  containerClassName: 'ms-dialogMainOverride'
};

const DIALOG_MIN_WIDTH = 400;
const DIALOG_MAX_WIDTH = 500;

export interface IRemoveConnectionButtonProps {
  title: string;
  subTitle: string;

  providerName?: string;
  iconOnly?: boolean;

  onRemoveConnection?: () => void;
}

export const RemoveButton: React.FC<IRemoveConnectionButtonProps> = (props) => {
  const [isDialogOpen, openDialog] = useState(false);

  const dialogContentProps: IDialogContentProps = {
    title: props.title,
    subText: props.subTitle
  };

  const removeHandle = () => {
    if (props.onRemoveConnection) {
      openDialog(false);
      props.onRemoveConnection();
    }
  };

  const cancelHandler = () => {
    openDialog(false);
  };

  const removeIconHandle = (e: React.MouseEvent) => {
    openDialog(true);
    e.stopPropagation();
  };

  return (
    <>
      <p onClick={removeIconHandle}>Remove</p>
      <Dialog
        hidden={!isDialogOpen}
        onDismiss={cancelHandler}
        minWidth={DIALOG_MIN_WIDTH}
        maxWidth={DIALOG_MAX_WIDTH}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={removeHandle} text="Yes" style={{ background: `${RED_COLOR}E6` }} />
          <DefaultButton onClick={cancelHandler} text="No" style={{ background: 'transparent' }} />
        </DialogFooter>
      </Dialog>
    </>
  );
};
