import React from 'react';
import { Spinner } from 'react-bootstrap';
import { CSSProperties } from 'styled-components';
import { LoadingContainer, SpinnerContainer } from './style';
import { LoadingBtnContainer } from '../Organization/style';

interface ILoadingProps {
  isModalLoading?: boolean;
  extraStyles?: CSSProperties;
  hideText?: boolean;
}

export const LoadSpinner: React.FC<ILoadingProps> = (props) => {
  return (
    <SpinnerContainer
      isModalLoading={props.isModalLoading}
      extraStyles={props.extraStyles}
    >
      <Spinner as="div" animation="border" role="status" />
      {!props.hideText && (<span>Loading...</span>)}
    </SpinnerContainer>
  );
};

export const Loading: React.FC<ILoadingProps> = (props) => {
  return (
    <LoadingContainer>
      <LoadSpinner {...props} />
    </LoadingContainer>
  );
};

export const LoadingButton: React.FC<ILoadingProps> = (props) => {
  return (
    <LoadingBtnContainer style={props.extraStyles}>
      <Spinner as="div" animation="border" role="status" size="sm" color="blue" />
      <span>Processing...</span>
    </LoadingBtnContainer>
  );
};
