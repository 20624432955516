/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import routerHistory from './history';

import accountsListReducer from './reducers/accounts';
import appReducer from './appReducer';
import connectionReducer from './reducers/connection';
import settingsReducer from './reducers/settings';
import organizationReducer from './reducers/organizations';
import workspacesListReducer from './reducers/workspaces';
import searchDataReducer from './reducers/searchData';

const appPersistConfig = {
  key: 'appReducer',
  storage,
  whitelist: ['successMessage', 'licenseData', 'connectionsData']
};

const rootReducer = combineReducers({
  appReducer: persistReducer(appPersistConfig, appReducer),
  router: connectRouter(routerHistory),
  accounts: combineReducers({
    list: accountsListReducer
  }),
  connection: connectionReducer,
  organizations: organizationReducer,
  settings: settingsReducer,
  workspaces: workspacesListReducer,
  searchData: searchDataReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
