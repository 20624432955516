import React from 'react';
import { IStackTokens, Persona, Stack } from '@fluentui/react';
import { DataGridButton } from './DataGridButton';
import { filterPersonaStyle, mainFiltersContainerStyle } from './style';
import {
  ClipboardTaskRegular,
  ContactCardRegular,
  DocumentRegular,
  SlideMultipleRegular,
} from '@fluentui/react-icons';
import { ItemType } from '../../../gen/src/ahauOpenAPI';
import { useSelector } from 'react-redux';
import { selectWorkspacesList } from '../../../utils/reducers/workspaces';
import DefaultTooltip from '../DefaultTooltip';

const stackTokens: Partial<IStackTokens> = { childrenGap: 8 };

interface MainFiltersProps {
  setFilter: React.Dispatch<React.SetStateAction<ItemType>>;
  filter: ItemType;
}

// Your main component
const MainFilters: React.FC<MainFiltersProps> = ({ setFilter, filter }) => {
  const handleSelect = (newFilter: ItemType) => {
    setFilter(newFilter);
  };
  const { currentWorkspaceData } = useSelector(selectWorkspacesList);

  return (
    <Stack horizontalAlign="start" verticalAlign="end" tokens={stackTokens}>
      <div style={mainFiltersContainerStyle}>
        <DataGridButton
          label="All"
          selected={filter === ItemType.NotSet}
          onSelect={() => {
            handleSelect(ItemType.NotSet);
          }}
          icon={<SlideMultipleRegular />}
        />
        <DataGridButton
          label="Documents"
          selected={filter === ItemType.Document}
          onSelect={() => {
            handleSelect(ItemType.Document);
          }}
          icon={<DocumentRegular />}
        />
        <DataGridButton
          label="Contacts"
          selected={filter === ItemType.Contact}
          onSelect={() => {
            handleSelect(ItemType.Contact);
          }}
          icon={<ContactCardRegular />}
        />
        <DataGridButton
          label="Tasks"
          selected={filter === ItemType.Task}
          onSelect={() => {
            handleSelect(ItemType.Task);
          }}
          icon={<ClipboardTaskRegular />}
        />
        {currentWorkspaceData && (
          <DefaultTooltip
            text={currentWorkspaceData.workspace?.name || 'Selected Workspace'}
          >
            <Persona
              text={currentWorkspaceData.workspace?.name}
              coinSize={24}
              styles={filterPersonaStyle}
            />
          </DefaultTooltip>
        )}
      </div>
    </Stack>
  );
};

// Export your main component
export default MainFilters;
