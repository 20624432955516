import React, { useEffect, useState } from 'react';
import {
  centerDivStyle,
  resultRowContentContainerStyle,
  resultRowContentStyle,
  resultRowIconButtonStyle,
  resultRowStyle,
} from '../style';
import { FontIcon, IconButton } from '@fluentui/react';
import {
  linkUnlinkTaskToWorkspace,
  selectWorkspacesList,
} from '../../../../../utils/reducers/workspaces';
import { useDispatch, useSelector } from 'react-redux';
import { buttonIconStyle } from './../style';

interface TaskResultProps {
  name: string | undefined;
  url: string | undefined;
  fullId: string | undefined;
  accountIconUrl: string | undefined;
}

const TaskResult: React.FC<TaskResultProps> = ({
  name,
  url,
  accountIconUrl,
  fullId,
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { currentWorkspaceData } = useSelector(selectWorkspacesList);

  useEffect(() => {
    if (currentWorkspaceData && currentWorkspaceData.tasks && fullId) {
      setDisabled(
        currentWorkspaceData.tasks.some((task) => task.activityId === fullId)
      );
    }
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(url, '_blank');
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...resultRowStyle,
        ...{
          borderLeft: isHovered ? '2px solid DeepSkyBlue' : '2px solid white',
        },
      }}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <div style={resultRowContentContainerStyle}>
        <div style={centerDivStyle}>
          {accountIconUrl ? (
            <img
              src={accountIconUrl}
              style={{ display: 'flex', width: '22px', height: '22px' }}
            />
          ) : (
            <FontIcon
              aria-label="Search"
              iconName={'SurveyQuestions'}
              style={{ display: 'flex' }}
            />
          )}
        </div>
        <div style={resultRowContentStyle}>{name}</div>
        <div style={{ display: 'flex' }}>
          {fullId && (
            <IconButton
              onClick={(e) => {
                dispatch(linkUnlinkTaskToWorkspace(fullId));
                setDisabled(true);
                e.stopPropagation();
              }}
              disabled={disabled}
              iconProps={{
                iconName: 'AddTo',
                style: buttonIconStyle(disabled),
              }}
              title='Add to Workspace'
              styles={resultRowIconButtonStyle}
            />
          )}
          <IconButton
            iconProps={{
              iconName: 'TaskSolid',
              style: { color: 'DeepSkyBlue' },
            }}
            title='Open Task'
            styles={resultRowIconButtonStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskResult;
