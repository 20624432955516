import { getConfig } from '../config';
import { CRM_PROVIDERS, DATA_SOURCE_PROVIDERS } from '../consts';
import { AuthMethods, ConnectionSettingsInputDto, ServiceProvider } from '../gen/src/ahauOpenAPI';
import { ASANA_DEFAULT_WEBROOT, HUBSPOT_DEFAULT_WEBROOT, OFFICE365_DEFAULT_WEBROOT } from '../pages/Profile/data';

const { apiUrl, clientUrl } = getConfig();

const connectionHelper = {
  validateFields: (connectionSettings: ConnectionSettingsInputDto) => {
    if (connectionSettings.authMethod === AuthMethods.ApiKey) {
      return connectionSettings.apiKey && connectionSettings.apiKey.length > 0;
    }

    return connectionSettings.userName;
  }
};

const getAuthorizeUrl = (accountId: number, profileSection: string) => {
  return `${apiUrl}/api/serviceAuth/account/${accountId}/authorize?returnUrl=${encodeURIComponent(
    `${clientUrl}connection/${accountId}?accountType=${profileSection}`
  )}`;
};

const isValidDefaulWebRoot = (webRoot?: string) => {
  return [
    ASANA_DEFAULT_WEBROOT,
    HUBSPOT_DEFAULT_WEBROOT,
    OFFICE365_DEFAULT_WEBROOT
  ].includes(webRoot || '');
};

const isProviderWithOauth = (provider: ServiceProvider) => {
  return [
    ServiceProvider.Asana,
    ServiceProvider.HubSpot,
    ServiceProvider.Office365,
    ServiceProvider.Google
  ].includes(provider);
};

const isCRMProvider = (provider?: ServiceProvider) => {
  if (!provider) return false;

  return CRM_PROVIDERS.includes(provider);
};

const isDataSourceProvider = (provider?: ServiceProvider) => {
  if (!provider) return false;

  return DATA_SOURCE_PROVIDERS.includes(provider);
};

const getConnectionType = (search: string) => {
  const searchUrl = new URLSearchParams(search);
  const PARAM_NAME = 'accountType';
  return searchUrl.get(PARAM_NAME) ?? 'account';
};

const isCrmConnectionType = (search: string) => {
  const searchUrl = new URLSearchParams(search);
  const PARAM_NAME = 'accountType';

  return searchUrl.has(PARAM_NAME) && searchUrl.get(PARAM_NAME) === 'crm';
};

const isDataSourceConnectionType = (search: string) => {
  const searchUrl = new URLSearchParams(search);
  const PARAM_NAME = 'accountType';

  return (
    searchUrl.has(PARAM_NAME) && searchUrl.get(PARAM_NAME) === 'cloud_platform'
  );
};

export {
  connectionHelper,
  getAuthorizeUrl,
  isValidDefaulWebRoot,
  isProviderWithOauth,
  getConnectionType,
  isCRMProvider,
  isCrmConnectionType,
  isDataSourceProvider,
  isDataSourceConnectionType
};
