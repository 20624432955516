/* eslint-disable max-len */
import { getConfig } from '../config';

export const COMPANY_TEL = '351925849380';
export const COMPANY_EMAIL = 'hello@ahau260.com';

const { webAppUrl } = getConfig();

export const STATIC_APP_URL = webAppUrl;

export const COMPANY_LOCATION = `${STATIC_APP_URL}/company_location`;
export const COMPANY_KB = `${STATIC_APP_URL}/kb`;
export const GET_SUPPORT = `${STATIC_APP_URL}/get-support`;
export const COMPANY_TERMS = `${STATIC_APP_URL}/terms-of-use`;
export const COMPANY_LICENSE = `${STATIC_APP_URL}/license-agreement`;
export const COMPANY_PRIVACY_POLICY = `${STATIC_APP_URL}/privacy-policy`;

export const EASY_PROJECTS_LINK = 'https://ahausoftware.go.easyprojects.net/';
export const REDMINE_LINK = 'https://ahausoftware.redmine.net/';

export const OFFICE365_DOWNLOAD_PAGE = `${STATIC_APP_URL}/office365`;
export const OUTLOOK_DOWNLOAD_PAGE = `${STATIC_APP_URL}/outlook`;
export const CHROME_DOWNLOAD_PAGE = `${STATIC_APP_URL}/chrome`;
export const ADOBE_DOWNLOAD_PAGE = `${STATIC_APP_URL}/adobe`;
export const TEAMS_DOWNLOAD_PAGE = `${STATIC_APP_URL}/teams`;
export const EDGE_DOWNLOAD_PAGE = `${STATIC_APP_URL}/outlook`;
export const FIREFOX_DOWNLOAD_PAGE = `${STATIC_APP_URL}/firefox`;
export const WORD_DOWNLOAD_PAGE = `${STATIC_APP_URL}/word`;
export const EXCEL_DOWNLOAD_PAGE = `${STATIC_APP_URL}/excel`;
export const POWERPOINT_DOWNLOAD_PAGE = `${STATIC_APP_URL}/powerpoint`;

export const INTEGRATION_OUTLOOK = `${STATIC_APP_URL}/integrations/outlook`;
export const INTEGRATION_CHROME = `${STATIC_APP_URL}/integrations/chrome`;
export const INTEGRATION_ADOBE = `${STATIC_APP_URL}/integrations/adobe`;
export const INTEGRATION_TEAMS = `${STATIC_APP_URL}/integrations/teams`;
export const INTEGRATION_MOBILE = `${STATIC_APP_URL}/integrations/mobile`;

export const REQUEST_DEMO = `${STATIC_APP_URL}/schedule`;
export const CONTACT_US = `${STATIC_APP_URL}/contactUs`;

export const FACEBOOK_PAGE = 'https://www.facebook.com/ahau260';
export const LINKEDIN_PAGE = 'https://www.linkedin.com/company/ahau-260';
export const TWITTER_PAGE = 'https://twitter.com/260Ahau';
export const YOUTUBE_PAGE = 'https://www.youtube.com/@ahau260';

export const DOCUMENT_TITLES: { [key: string]: string } = {
  home: 'AHAU 260',
  outlook: 'AHAU 260 | Microsoft Outlook',
  chrome: 'AHAU 260 | Chromium Browsers',
  adobe: 'AHAU 260 | Adobe Creative Cloud',
  teams: 'AHAU 260 | Microsoft Teams',
  features: 'AHAU 260 | Features',
  aboutUs: 'AHAU 260 | About Us',
  profile: 'AHAU 260 | Profile',
  tour: 'AHAU 260 | Tour',
  mobile: 'AHAU 260 | Mobile',
  integrations: 'AHAU 260 | Integrations',
  contactUs: 'AHAU 260 | Get In Touch'
};

export const USER_ACTIONS = {
  login: 'Login',
  signUp: 'Sign Up',
  logout: 'Logout'
};

export const INPUT_PATTERNS: {
  email: RegExp;
  phone: RegExp;
} = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
};

export enum ErrorCodes {
  Unknown = 0,
  System = 100,
  ServiceClientException = 400,
  ServiceConnectionException = 503,
  ServiceNotFound = 404,
  ServiceDeserializeError = 415,
  /** external api call unauthorized (need reverify account connection?)  */
  ServiceUnauthorized = 401,
  ServiceActionForbidden = 403,
  ServiceVersionUnsupported = 501,
  TenantNotFound = 1000,
  /** api call unauthorized (need login) */
  UnauthorizedRequest,
  UnauthorizedSessionRequest,

  TenantCreationError = 1001
}
