import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { UserSettings } from '../../gen/src/ahauOpenAPI';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { getApiClient } from '../api';
import { setError } from '../appReducer';
import { ERROR_MESSAGE } from '../../consts';

export interface SettingsState {
  isLoading: boolean;
  userSettings: UserSettings;
}

const initialState: SettingsState = {
  isLoading: true,
  userSettings: new UserSettings()
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.userSettings = action.payload;
    }
  }
});

const { startLoading, stopLoading } = settingsSlice.actions;

export const fetchUserSettings = (): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = await getApiClient(accessToken);

  dispatch(startLoading());

  try {
    const userSettingsResp = await api.settingsGetUserSettings();
    dispatch(settingsSlice.actions.setUserSettings(userSettingsResp));
  } catch (e) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const updateUserSettings = (
  syncCalendar: boolean,
  syncChecklists: boolean
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = await getApiClient(accessToken);

  dispatch(startLoading());

  try {
    const { userSettings } = getState().settings;

    const newUserSettings = new UserSettings({
      ...userSettings,
      syncCalendar,
      syncChecklists
    });

    const userSettingsResp = await api.settingsUpdateUserSettings(
      newUserSettings
    );
    dispatch(settingsSlice.actions.setUserSettings(userSettingsResp));
  } catch (e) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer;
