import { IChoiceGroupOption } from '@fluentui/react';
import React, { useState } from 'react';
import { AccountSelectorContainer } from './style';

export interface IAccountSelectorProps {
  isSelected?: boolean;
  item: IChoiceGroupOption;
  disabled?: boolean;

  onChange: (value: IChoiceGroupOption) => void;
}

export const AccountSelector: React.FC<IAccountSelectorProps> = (props) => {
  const [isHover, setHoverStatus] = useState<boolean>(false);

  const onMouseEnterHandle = () => {
    setHoverStatus(true);
  };

  const onMouseLeaveHandle = () => {
    setHoverStatus(false);
  };

  const onClickHandle = () => {
    props.onChange(props.item);
  };

  return (
    <AccountSelectorContainer
      isHover={isHover}
      isSelected={props.isSelected}
      onMouseEnter={onMouseEnterHandle}
      onMouseLeave={onMouseLeaveHandle}
      onClick={onClickHandle}
      disabled={props.disabled}
    >
      <img src={props.item.imageSrc} alt={props.item.imageAlt} />
    </AccountSelectorContainer>
  );
};
