/* eslint-disable import/no-cycle */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { HeaderItem, RightHeaderContainer, SignUpContainer } from './styled';

import AuthenticatedHeader from './AuthenticatedHeader';
import Button from '../Button';

export interface IRightHeaderProps {
  isAuthenticated: boolean;
  isSticky: boolean;
  isLoading: boolean;
  isProfileMenuOpen: boolean;

  onUserSignUp: () => void;
  onUserLogin: () => void;
  onUserLogout: () => void;
  openProfileMenu: (isOpen: boolean) => void;
  changeMobileMenuVisibility: (value: boolean) => void;
}

const RightHeader: React.FC<IRightHeaderProps> = (props) => {
  return (
    <RightHeaderContainer isSticky={props.isSticky}>
      {props.isAuthenticated ? (
        <AuthenticatedHeader
          isProfileMenuOpen={props.isProfileMenuOpen}
          onUserLogout={props.onUserLogout}
          changeMobileMenuVisibility={props.changeMobileMenuVisibility}
          openProfileMenu={props.openProfileMenu}
        />
      ) : (
        <>
          {props.isLoading ? (
            <Spinner animation="border" role="status" />
          ) : (
            <>
              <HeaderItem onClick={props.onUserLogin} style={{ marginLeft: 0 }}>
                Login
              </HeaderItem>
              <SignUpContainer>
                <Button
                  onClick={props.onUserSignUp}
                  style={{ padding: '10px 20px' }}
                  isTransparent={true}
                >
                  Sign Up
                </Button>
              </SignUpContainer>
            </>
          )}
        </>
      )}
    </RightHeaderContainer>
  );
};

export default RightHeader;
