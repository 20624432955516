import React, { useState } from 'react';
import { IStackTokens, Stack } from '@fluentui/react';
import MainFilters from './MainFilters';
import { DataResultsContainer } from './DataResultsContainer';
import { containerStyle } from './style';
import { ItemType } from '../../../gen/src/ahauOpenAPI';

const stackTokens: Partial<IStackTokens> = { childrenGap: 8 };

// Your main component
export const DataContainer: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [filter, setFilter] = useState<ItemType>(ItemType.NotSet);

  return (
    <Stack horizontalAlign="center" verticalAlign="start" tokens={stackTokens}>
      <div
        style={containerStyle(isHovered)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <MainFilters filter={filter} setFilter={setFilter} />
        <DataResultsContainer filter={filter} />
      </div>
    </Stack>
  );
};
