import React, { useState } from 'react';
import {
  centerDivStyle,
  resultPersonaStyle,
  resultRowContentContainerStyle,
  resultRowContentStyle,
  resultRowIconButtonStyle,
  resultRowStyle,
} from '../style';
import { IconButton, Persona } from '@fluentui/react';

interface EmailResultProps {
  name: string | undefined;
  email: string | undefined;
  url: string | undefined;
}

const EmailResult: React.FC<EmailResultProps> = ({ url, name, email }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(url, '_blank');
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...resultRowStyle,
        ...{
          borderLeft: isHovered ? '2px solid orange' : '2px solid white',
        },
      }}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <div style={resultRowContentContainerStyle}>
        <div style={centerDivStyle}>
          <Persona text={name} coinSize={20} styles={resultPersonaStyle} />
        </div>
        <div style={resultRowContentStyle}>{`${name} (${email})`}</div>
        <div style={{ display: 'flex' }}>
          <IconButton
            iconProps={{
              iconName: 'MailSolid',
              style: { color: 'orange' },
            }}
            title="Open Email"
            styles={resultRowIconButtonStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailResult;
