import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background: #e9f2ff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 2fr;
  grid-gap: 20px;
  padding: 30px;

  @media (max-width: 1440px) {
    /* margin: 0 50px; */
    padding: 20px;
  }

  @media (max-width: 1280px) {
    margin: 0 30px;
  }

  @media (max-width: 1024px) {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 968px) {
    padding: 30px;
  }

  @media (max-width: 280px) {
    padding: 20px;
  }
`;

export const Column = styled.div`
  a {
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    margin-right: 30px;
  }
  @media (max-width: 850px) {
    margin-right: 75px;
  }
`;

export const Logo = styled.img`
  width: 210px;
  margin-bottom: 40px;
`;

export const Img = styled.img`
  opacity: 0.5;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const ImageBlock = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const styledLink = css`
  font-size: 17px;
  line-height: 36px;
  font-family: 'segoeuii';
  margin-bottom: 7px;
  display: block;
  cursor: pointer;
`;

export const Text = styled.div<{ bold?: boolean; notClickable?: boolean }>`
  ${styledLink}
  cursor: ${(props) => (props.notClickable ? 'initial' : 'pointer')};
  color: ${(props) => (props.notClickable ? '#464650' : '#909096')};

  a {
    color: ${(props) => (props.notClickable ? '#464650' : '#909096')};
    text-decoration: none;
  }

  ${(props) =>
    props.bold &&
  `
        font-weight: bold;
        opacity: 1;
    `}

  &:hover {
    text-decoration: ${(props) =>
    props.notClickable ? 'initial' : 'underline'};
  }

  @media (max-width: 1024px) {
    margin-bottom: 0px;
  }
`;

export const TextLink = styled(Link)`
  ${styledLink}
  color: #909096;

  a {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
    color: #909096;
  }

  @media (max-width: 1024px) {
    margin-bottom: 0px;
  }
`;

export const IconContainer = styled.a<{
  color: string;
  iconSize?: {
    circleSize: string;
    logoSize: string;
  };
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.iconSize?.circleSize ?? '22px'};
  height: ${(props) => props.iconSize?.circleSize ?? '22px'};
  background-color: #979ca7;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;

  svg {
    font-size: ${(props) => props.iconSize?.logoSize ?? '12px'};
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: ${(props) => props.color};

    svg {
      color: #fff;
    }
  }

  // For mobile (hover effects broke design)
  @media (pointer: none), (pointer: coarse) {
    &:hover {
      background-color: #979ca7;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
