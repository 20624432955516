import React, { memo } from 'react';
import { CheckboxIcon, InfoIcon, PreferenceDescription, PreferenceRowWrapper, PreferencesRow } from './style';

export interface PreferenceRowProps {
  syncTypeStatus: boolean;
  onCheckboxClick: (isChecked: boolean) => void;

  description: string;
  infoDescription: string;
}

const PreferenceRow: React.FC<PreferenceRowProps> = (props) => {
  return (
    <PreferenceRowWrapper>
      <PreferencesRow>
        <CheckboxIcon
          isSelected={props.syncTypeStatus}
          iconName={
            props.syncTypeStatus ? 'CheckboxCompositeReversed' : 'Checkbox'
          }
          onClick={() => props.onCheckboxClick(!props.syncTypeStatus)}
        />
        <span>{props.description}</span>
      </PreferencesRow>
      <PreferenceDescription>
        <InfoIcon iconName="info" />
        {props.infoDescription}
      </PreferenceDescription>
    </PreferenceRowWrapper>
  );
};

export default memo(PreferenceRow);
