import React, { memo } from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Container';
import { FormFieldSection, SimpleForm } from '../../components/Forms';
import { ServiceLogo } from './ServiceLogo';
import { ConnectionSettingsOutputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { ConnectActionContainer, ContinueButton } from './style';
import { Loading } from '../Profile/Loading';
import WebRootInput from './WebRootInput';

export const FormContainer = styled.section`
  max-width: 450px;
  padding: 15px;
  margin: 0 auto;
`;

export interface IConnectionAddressFormProps {
  isCreationMode?: boolean;
  accountId?: number;
  serviceProvider?: ServiceProvider;
  serviceProviderName?: string;
  defaultUserName?: string;
  defaultWebRoot?: string;

  userName?: string;
  password?: string;
  passwordLength?: number;
  apiKey?: string;
  error?: unknown;

  webRootIsVerified: boolean;
  webRootIsVerifying: boolean;
  isFullscreenMode?: boolean;
  returnUri?: string;
  editData?: ConnectionSettingsOutputDto;
  isLoading?: boolean;
  isAddressChanging?: boolean;

  verifyUrl?: (url?: string) => void;
  onChange?: (arg: {}) => void;
  onContinueClick?: (webRoot?: string) => void;
}

const ConnectionAddressForm: React.FC<IConnectionAddressFormProps> = (
  props
) => {

  const [webRoot, setWebRoot] = React.useState<string | undefined>(
    props.editData?.webRoot ? props.editData?.webRoot : props.defaultWebRoot
  );
  const [isEditableWebRoot] = React.useState<boolean>(
    props.serviceProvider === ServiceProvider.EasyProjects
  );
  const [webRootIsChanged, setWebRootIsChanged] = React.useState<boolean>(
    false
  );
  const [showStatusIcon, setShowStatusIcon] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.webRootIsVerified) {
      setWebRootIsChanged(false);
      props.onContinueClick && props.onContinueClick(webRoot);
    }
  }, [props.webRootIsVerified]);

  const onWebRootChange = (val: { webRoot?: string }) => {
    setShowStatusIcon(false);
    if (val.webRoot && val.webRoot !== '') {
      setWebRoot(val.webRoot);
      setWebRootIsChanged(webRootIsChanged);
    } else {
      setWebRoot('');
    }
  };

  if (!props.serviceProvider) return <Loading />;

  return (
    <>
      <Container>
        <FormContainer className="form-setupconnection">
          <ServiceLogo
            webRoot={webRoot}
            serviceProvider={props.serviceProvider}
          />

          <SimpleForm>
            <form>
              <FormFieldSection>
                <WebRootInput
                  {...props}
                  webRoot={webRoot}
                  readOnly={!props.isCreationMode}
                  serviceProviderName={props.serviceProviderName}
                  isChanged={webRootIsChanged}
                  isVerifying={props.webRootIsVerifying}
                  isVerified={props.webRootIsVerified}
                  onChange={onWebRootChange}
                  isEditableWebRoot={isEditableWebRoot}
                  showStatusIcon={showStatusIcon}
                />
              </FormFieldSection>
              <ConnectActionContainer>
                <ContinueButton
                  onClick={(e) => {
                    setShowStatusIcon(true);
                    props.verifyUrl && props.verifyUrl(webRoot);
                    e.preventDefault();
                  }}
                >
                  Continue
                </ContinueButton>
              </ConnectActionContainer>
            </form>
          </SimpleForm>
        </FormContainer>
      </Container>
    </>
  );
};

export default memo(ConnectionAddressForm);
