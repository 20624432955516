/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { OrganizationInput, StyledButton } from './style';
import { fetchCreateOrganization } from '../../utils/reducers/organizations';
import OrganizationSection from './OrganizationSection';
import { SetupOrgSteps } from '../../consts';
import mixpanelService, { EventName } from '../../services/MixpanelService';

const CreateNewOrganization: React.FC = () => {
  const dispatch = useDispatch();
  const [newOrgName, setNewOrgName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createNewOrganization = React.useCallback(() => {
    mixpanelService.sendEvent(EventName.ORGANIZATION, 'Set Name');
    setIsLoading(true);
    dispatch(fetchCreateOrganization(newOrgName));
  }, [newOrgName]);

  return (
    <OrganizationSection
      currentStep={SetupOrgSteps.CREATE_ORGANIZATION}
      isLoading={isLoading}
      title="Set Your Organization"
      orgActions={(
        <>
          <StyledButton
            disabled={!newOrgName.replace(' ', '').length}
            onClick={createNewOrganization}
          >
            Continue
          </StyledButton>
          <Link to="/#organization">
            <StyledButton isTransparent>Cancel</StyledButton>
          </Link>
        </>
      )}
    >
      <OrganizationInput
        type="text"
        value={newOrgName}
        autoFocus={true}
        onChange={(e) => setNewOrgName(e.target.value)}
        placeholder="Enter your organization name"
        alt="Get started input"
        onKeyUp={(e) => e.key === 'Enter' && createNewOrganization()}
      />
    </OrganizationSection>
  );
};

export default CreateNewOrganization;
