import { Action, AnyAction, configureStore, getDefaultMiddleware, Middleware, ThunkAction } from '@reduxjs/toolkit';
import { ThunkMiddleware } from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import routerHistory from './history';

import rootReducer, { RootState } from './rootReducer';

const defaultMiddleware = getDefaultMiddleware<RootState,
{
  serializableCheck: boolean;
}>({
  serializableCheck: false
});

export type { RootState } from './rootReducer';

const RouterMiddleware = routerMiddleware(routerHistory);

const middleware: Array<Middleware<{}, RootState> | ThunkMiddleware<RootState, AnyAction>> = [
  ...defaultMiddleware, RouterMiddleware
];

const persistedReducer = rootReducer;

const store = configureStore({
  reducer: persistedReducer,
  middleware
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
