import React, { PureComponent } from 'react';
import { Link } from '@fluentui/react';
import styled from 'styled-components';
import { FormFieldSection } from '../../components/Forms';
import { ServiceLogo } from './ServiceLogo';
import { ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { ConnectionHeaderChangeButton, ConnectionHeaderTitle, ConnectionHeaderUrlContainer } from './style';

const HeaderContainer = styled.section`
  margin: ${(props: { customMargin?: string }) =>
    props.customMargin ? props.customMargin : '0.5rem 0 2rem'};
`;

export class ConnectionHeaderSection extends PureComponent<{
  serviceProvider: ServiceProvider;

  isCreationMode?: boolean;
  webRoot?: string;
  userName?: string;
  headerContainerMargin?: string;

  onChangeAddress?: () => void;
},
{}> {
  render() {
    return (
      <>
        <HeaderContainer customMargin={this.props.headerContainerMargin}>
          <ServiceLogo
            webRoot={this.props.webRoot}
            serviceProvider={this.props.serviceProvider}
          />
          <FormFieldSection>
            <br />
            <br />
            <ConnectionHeaderTitle>Connection to Account</ConnectionHeaderTitle>

            <ConnectionHeaderUrlContainer>
              <Link
                href={`${this.props.webRoot}?username=${this.props.userName}`}
                title="Open the account in the new window"
                target="_blank"
              >
                {this.props.webRoot}
              </Link>
              {this.props.isCreationMode && (
                <ConnectionHeaderChangeButton
                  onClick={this.props.onChangeAddress}
                >
                  Change
                </ConnectionHeaderChangeButton>
              )}
            </ConnectionHeaderUrlContainer>
          </FormFieldSection>
        </HeaderContainer>
      </>
    );
  }
}
