class SearchHistory {
  private static instance: SearchHistory | null = null;

  private searchHistory: string[] = [];

  private constructor() {
    // Load search history from storage on instantiation
    const storedHistory = localStorage.getItem('searchHistory');
    this.searchHistory = storedHistory ? JSON.parse(storedHistory) : [];
  }

  public static getInstance(): SearchHistory {
    if (!SearchHistory.instance) {
      SearchHistory.instance = new SearchHistory();
    }
    return SearchHistory.instance;
  }

  public addSearchTerm(term: string): void {
    const lowerCaseTerm = term.toLowerCase();

    const termIndex = this.searchHistory.indexOf(lowerCaseTerm);

    if (termIndex !== -1) {
      this.searchHistory.splice(termIndex, 1);
    }

    this.searchHistory.unshift(lowerCaseTerm);
    this.searchHistory = this.searchHistory.slice(0, 100);

    localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
  }

  public getSearchHistory(): string[] {
    return this.searchHistory;
  }

  // For Testing
  public resetSearchHistory(): void {
    this.searchHistory = [];
    localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
  }
}

export default SearchHistory;
