/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useHistory, useLocation } from 'react-router';
import { ConnectionCard, ConnectionCardHeader, ConnectionCardInfo, ConnectionsRow, ConnectionsSection } from '../style';
import { Loading } from '../Loading';
import { AccountInfoDto } from '../../../gen/src/ahauOpenAPI';
import { WidthContext } from '../../../App';
import { checkOverflow } from '../../../utils/utilsFunctions';
import CreateConnectionCard from './CreateConnectionCard';
import { connectionImages, CRM_TITLE, DATA_SOURCES_TITLE } from '../data';
import { deleteAccountAsync } from '../../../utils/reducers/accounts';
import EmptyList from './EmptyList';
import { CardActions } from '../../../components/CardActions';
import mixpanelService, { EventName } from '../../../services/MixpanelService';

interface IConnectionsListProps {
  connectionsData?: AccountInfoDto[];
  isLoading?: boolean;
  title: string;
}

interface IConnectionsOverflowState {
  linkTooltipOverflow: boolean;
  emailTooltipOverflow: boolean;
}

interface IConnectionsTooltipsState {
  linkTooltip: boolean;
  emailTooltip: boolean;
  id: number | undefined;
}

const ConnectionsList: React.FC<IConnectionsListProps> = (props) => {
  const width = useContext(WidthContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const titleRef = useRef<HTMLHeadingElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const emailRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef && location.hash.includes(props.title.toLocaleLowerCase())) {
      titleRef.current?.scrollIntoView();
    }
  }, [location.hash]);

  const [
    isOverflowing,
    setIsOverflowing
  ] = React.useState<IConnectionsOverflowState>({
    linkTooltipOverflow: false,
    emailTooltipOverflow: false
  });

  const [showTooltips, setShowTooltips] = useState<IConnectionsTooltipsState>({
    linkTooltip: false,
    emailTooltip: false,
    id: undefined
  });

  React.useLayoutEffect(() => {
    setIsOverflowing({
      linkTooltipOverflow: checkOverflow(linkRef.current),
      emailTooltipOverflow: checkOverflow(emailRef.current)
    });
  }, [width]);

  const onConnectAccount = (
    e: React.MouseEvent<HTMLDivElement>,
    id: number
  ) => {
    e.stopPropagation();
    history.push(`/connection/${id}`);
  };

  const onRemoveConnection = (id: number) => {
    dispatch(deleteAccountAsync(id));
  };

  return (
    <>
      <ConnectionsSection>
        <h1 ref={titleRef}>{props.title}</h1>
        {props.isLoading && <Loading />}
        {!props.connectionsData?.length && !props.isLoading && (
          <EmptyList  />
        )}
        {props.connectionsData?.length && !props.isLoading ? (
          <ConnectionsRow>
            {props.connectionsData?.map((connection) => {
              const { id, serviceProvider, applicationRoot } = connection;
              const email = get(
                connection,
                'extensionData.connectionSettings.userName',
                ''
              );
              const imageSrc = get(
                connectionImages,
                serviceProvider as string,
                ''
              );
              const title =
                serviceProvider === 'EasyProjects'
                  ? serviceProvider.split(/(?=[A-Z])/).join(' ')
                  : serviceProvider;

              return (
                <ConnectionCard
                  key={id}
                  onClick={(e) => onConnectAccount(e, id)}
                >
                  <CardActions
                    title="Do you want to remove this connection?"
                    subTitle={`Your connection with ${connection.serviceProvider} will be removed from our server. You can create a new connection with this provider at any time.`}
                    onRemoveConnection={async () => {
                      onRemoveConnection(connection.id);
                      mixpanelService.sendEvent(EventName.ACCOUNT, 'Remove', [connection.serviceProvider]);
                    }}
                  />
                  <ConnectionCardHeader>
                    <img
                      src={imageSrc}
                      alt={serviceProvider}
                      title={serviceProvider}
                    />
                  </ConnectionCardHeader>
                  <ConnectionCardInfo>
                    <h4>{title}</h4>
                    <OverlayTrigger
                      key={id}
                      placement="top"
                      overlay={<Tooltip id="link">{applicationRoot}</Tooltip>}
                      onToggle={(showTooltip) => {
                        if (isOverflowing.linkTooltipOverflow) {
                          setShowTooltips({
                            ...showTooltips,
                            linkTooltip: showTooltip,
                            id
                          });
                        }
                      }}
                      show={
                        (showTooltips.linkTooltip && showTooltips.id === id) ??
                        false
                      }
                    >
                      <a
                        href={applicationRoot}
                        target="_blank"
                        rel="noreferrer"
                        ref={linkRef}
                      >
                        {applicationRoot}
                      </a>
                    </OverlayTrigger>

                    {email && (
                      <OverlayTrigger
                        key="email"
                        placement="top"
                        overlay={<Tooltip id="email">{email}</Tooltip>}
                        onToggle={(showTooltip) => {
                          if (isOverflowing.emailTooltipOverflow) {
                            setShowTooltips({
                              ...showTooltips,
                              emailTooltip: showTooltip,
                              id
                            });
                          }
                        }}
                        show={
                          (showTooltips.emailTooltip &&
                            showTooltips.id === id) ??
                          false
                        }
                      >
                        <div ref={emailRef}>
                          <span>Email:</span> <span>{email}</span>
                        </div>
                      </OverlayTrigger>
                    )}
                  </ConnectionCardInfo>
                </ConnectionCard>
              );
            })}
            <CreateConnectionCard  />
          </ConnectionsRow>
        ) : null}
      </ConnectionsSection>
    </>
  );
};

export default memo(ConnectionsList);
