/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-regex-spaces */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */

import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../Profile/Loading';
import { AuthScreenHint } from '../../consts';
import { fetchApproveInvite } from '../../utils/reducers/organizations';
import { selectAppState } from '../../utils/appReducer';

const AcceptInviteToOrganization: React.FC = () => {
  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();
  const dispatch = useDispatch();
  const { token } = useParams() as { token?: string };
  const { pathname, search } = window.location;

  const { initialized } = useSelector(selectAppState);

  useEffect(() => {
    if (!token) {
      throw new Error('No token');
    }
    if (isLoading) {
      return;
    }
    if (!isAuthenticated || !user?.email) {
      loginWithRedirect({
        appState: { returnTo: pathname + search },
        screen_hint: AuthScreenHint.SIGN_UP
      });
    } else if (initialized) {
      dispatch(fetchApproveInvite(token));
    }
  }, [user, isLoading, isAuthenticated, initialized, token]);

  return (
    <>
      <Loading />;
    </>
  );
};

export default AcceptInviteToOrganization;
