import * as React from 'react';
import { filterButtonStyle } from './style';

interface DataGridButtonProps {
  label: string;
  selected?: boolean;
  onSelect: () => void;
  icon: React.ReactNode;
}

export const DataGridButton: React.FunctionComponent<DataGridButtonProps> = ({
  label,
  selected = false,
  onSelect,
  icon,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onClick={onSelect}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={filterButtonStyle(selected, isHovered)}
    >
        <div style={{ marginRight: '4px', fontSize: '14px' }}>{icon}</div>
        {label}
    </div>
  );
};
