/* eslint-disable no-restricted-globals */
import React from 'react';
import { ReactComponent as SuccessArrow } from '../../images/SuccessArrow.svg';
import Button from '../../components/Button';
import { ConnectionHeaderChangeButton, Link, MessageBar } from './style';

interface IConnectionStatusSectionProps {
  webRoot?: string;
  userName?: string;
  isVerifiedAccount?: boolean;
  isCreationMode?: boolean;
  isLoading?: boolean;
  isVerifyingWebroot?: boolean;
  verifyAccountErrorMessage?: unknown;
  backToSection: string;

  onCompletedClick?: () => void;
  disableFinishButton?: boolean;
  onChangeConnectionClick?: () => void;
}

export const ConnectionStatusSection: React.FC<IConnectionStatusSectionProps> = (
  props
) => {
  return (
    <>
      {props.isVerifiedAccount && (
        <MessageBar isSuccessfull>
          <div>
            <SuccessArrow />
          </div>
          <p>The user {props.userName} successfully authorized.</p>
        </MessageBar>
      )}

      {props.verifyAccountErrorMessage && !props.isVerifiedAccount && (
        <MessageBar>
          <div>!</div>
          <p>The user {props.userName} unsuccessfully authorized.</p>
        </MessageBar>
      )}
      <br />
      <br />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <ConnectionHeaderChangeButton onClick={props.onChangeConnectionClick}>
          Change connection
        </ConnectionHeaderChangeButton>

        <Link to={`/#${props.backToSection}`}>
          <Button
            disabled={props.disableFinishButton}
            style={{ marginRight: '4px', width: '100px' }}
          >
            Ok
          </Button>
        </Link>
      </div>
    </>
  );
};
