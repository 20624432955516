import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheck, faPeopleRoof, faPlay, faSquare } from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faCheck,
  faSquare,
  faPeopleRoof,
  faPlay
  // more icons go here
);
