import styled from 'styled-components';
import { Callout } from '@fluentui/react';
import { GREY_COLOR, LIGHT_BLUE_COLOR, WHITE_COLOR } from '../../../consts/theme';

export const EmptyListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 400px;
  border: 2px solid #d8e8fc;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  margin-top: 20px;

  background: rgba(255, 255, 255, 0.2);
`;

export const EmptyListDescription = styled.p`
  color: ${GREY_COLOR};
  font-family: 'segoeuii';

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  max-width: 315px;
  margin-top: 12px;
  margin-bottom: 32px;
  text-align: center;
`;

export const ConnectAccountContainer = styled.div`
  background: ${WHITE_COLOR}33;
  border: 2px solid #d8e8fc;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 350px;
  cursor: pointer;

  border-radius: 10px;
  p {
    font-family: 'segoeuii';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${GREY_COLOR};
    margin-top: 20px;
  }
`;

export const ActionsContainer = styled(Callout)`
  width: 244px;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 14px 24px rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  background: ${WHITE_COLOR};
  font-family: 'segoeuii';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  .ms-Callout-beak {
    display: none;
  }

  p {
    margin: 0;
    cursor: pointer;
    padding: 16px;
  }
  p:hover {
    background: ${LIGHT_BLUE_COLOR};
  }
`;
