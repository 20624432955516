/* eslint-disable import/no-cycle */
import React, { KeyboardEvent, useCallback, useRef, useState } from 'react';
import {
  Cross,
  DisplayedEmail,
  EmailHiddenSpan,
  EmailInput,
  EmailsInputArea,
  EmailWarningMessage,
  MultipleEmailActions
} from './style';
import CrossBlack from '../../images/CrossBlack.svg';
import { isValidEmail } from '../../helpers/validators';

export interface TeamMatesEmailsInputProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
}

const MultipleEmailsInput: React.FC<TeamMatesEmailsInputProps> = (props) => {
  const { emails, setEmails } = props;
  const [emailInputValue, setInputEmailValue] = useState<string>('');
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);

  const focusRef = useRef(null);
  const [width, setWidth] = useState<number>(30);
  const spanRef = useRef(null);
  const containerRef = useRef(null);

  React.useEffect(() => {
    if (!emailInputValue.length && !emails.length) {
      setWidth(365);
    } else {
      const span: HTMLSpanElement | null = spanRef.current;
      span && setWidth((span as HTMLSpanElement).offsetWidth + 42);
    }
  }, [emailInputValue, emails]);

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    if (!['Backspace', 'Space', 'Enter', 'Tab'].includes(e.code)) {
      return;
    }
    if (e.code === 'Backspace' && value === '' && emails.length) {
      removeElement(emails[emails.length - 1]);
      return;
    }
    if (!value.trim().length) return;
    if (
      e.code === 'Space' ||
      e.code === 'Enter' ||
      e.code === 'Tab' ||
      value[value.length - 1] === ' '
    ) {
      const email = value.trim();
      if (emails.some((item) => item === email)) {
        setInputEmailValue('');
        return;
      }
      setEmails(emails.concat(email));

      setInputEmailValue('');
      !isValidEmail(email) && setDisplayWarning(true);
    }
  }, [emails]);

  const removeElement = (teamEmailItem: string) => {
    const newEmails = emails.filter(
      (item) => item !== teamEmailItem
    );
    setDisplayWarning(newEmails.some((item) => !isValidEmail(item)));
    setEmails(newEmails);
  };

  const onInputLostFocus = useCallback(() => {
    if (!emailInputValue.trim().length) return;
    if (!emails.some(item => item === emailInputValue)) {
      setEmails(emails.concat(emailInputValue));
      !isValidEmail(emailInputValue) && setDisplayWarning(true);
    }
    setInputEmailValue('');
  }, [emails, emailInputValue]);

  return (
    <EmailsInputArea
      displayWarning={displayWarning}
      ref={containerRef}
      onClick={() => {
        const input = focusRef?.current;
        input && (input as HTMLInputElement).focus();
      }}
    >
      <ul>
        {
          emails.map((email) => {
            return (
              <DisplayedEmail
                key={`${email}`}
                isValid={isValidEmail(email)}
                title={email}
              >
                <span>{email}</span>
                <Cross
                  src={CrossBlack}
                  onClick={() => removeElement(email)}
                />
              </DisplayedEmail>
            );
          })
        }
        <div
          style={!emails.length ? {
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          } : {}}
        >
          <EmailHiddenSpan
            // @ts-ignore
            maxWidth={containerRef?.current ? (containerRef.current as HTMLDivElement).offsetWidth - 60 : width}
            ref={spanRef}
          >
            {emailInputValue}
          </EmailHiddenSpan>
          <EmailInput
            style={{ width }}
            autoFocus
            ref={focusRef}
            placeholder={
              !emailInputValue.length && !emails.length ? 'name@domain.com, name@domain.com, ...' : ''
            }
            value={emailInputValue}
            onKeyDown={onKeyDown}
            onChange={(e) => setInputEmailValue(e.target.value)}
            type="text"
            onBlur={onInputLostFocus}
          />
        </div>
      </ul>
      <MultipleEmailActions>
        {displayWarning && (
          <EmailWarningMessage title={
            `You have entered ${emails.filter((item) => !isValidEmail(item)).length} invalid email address.`
          }
          >
            {`You have entered ${emails.filter((item) => !isValidEmail(item)).length} invalid email address.`}
          </EmailWarningMessage>
        )}
      </MultipleEmailActions>
    </EmailsInputArea>
  );
};

export default MultipleEmailsInput;
