import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, get } from 'lodash';
import { IChoiceGroupOption } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ConnectorEditDataDto, CreateAccountInputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import {
  fetchAccountCreateData,
  fetchAccountsList,
  selectAccountsList,
  selectProvider,
  selectServiceProvied
} from '../../utils/reducers/accounts';
import {
  clearConnectionEditData,
  onWebRootContinueCreateClick,
  selectConnectorCreateData
} from '../../utils/reducers/connection';
import { choiceGroupOptionsStyles, connectionImages } from '../Profile/data';
import {
  AccountsSelectorContainer,
  ActionsContainer,
  ConnectionSubtitle,
  ConnectionTitle,
  Link,
  ProvidersContainer
} from './style';
import { ConnectionSteps } from '../../consts';
import { Loading } from '../Profile/Loading';
import { AccountSelector } from './AccountSelector';
import {
  getConnectionType,
  isCrmConnectionType,
  isDataSourceConnectionType,
  isProviderWithOauth
} from '../../helpers/connectionHelper';
import Button from '../../components/StyledButton';
import mixpanelService, { EventName } from '../../services/MixpanelService';

export interface IAccountsChoiceGroupOption extends IChoiceGroupOption {
  name?: string;
  webRoot?: string;
  isDefault?: boolean;
  serviceProvider: ServiceProvider;
}

interface IProvidersProps {
  isChanged: boolean;

  setChangedStatus: (value: boolean) => void;
  setStep: (value: ConnectionSteps) => void;
}

export const mapConnectors = (
  connectors: Array<ConnectorEditDataDto>
): Array<IAccountsChoiceGroupOption> => {
  return connectors.map((connector) => {
      const imageSrc = get(
        connectionImages,
        connector.serviceProvider as string,
        ''
      );
      return {
        key: connector.serviceProvider,
        text: connector.serviceProviderName
          ? connector.serviceProviderName
          : '',
        webRoot: connector.defaultWebRoot,
        name:
          connector.defaultSettings && connector.defaultSettings.userName
            ? connector.defaultSettings.userName
            : '',
        // isDefault: true,
        serviceProvider: connector.serviceProvider,
        // Todo: Check this - when dark mode is activated from dashboard pop-up, EasyProjects logo is not changed right away
        // * EasyProjects is only connector with existing dark mode version (white logo)
        imageSrc,
        selectedImageSrc: imageSrc,
        imageAlt: imageSrc,
        styles: choiceGroupOptionsStyles
      } as IAccountsChoiceGroupOption;
    });
};

const Providers: React.FC<IProvidersProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation<Location>();

  const { isChanged, setChangedStatus, setStep } = props;

  const [isOauthLoading, setIsLoading] = useState<boolean>(false);
  const [
    preSelectedProvied,
    setPreSelectedProvied
  ] = useState<ServiceProvider | null>(null);

  const selectedProvider = useSelector(selectServiceProvied);
  const accountsListState = useSelector(selectAccountsList);
  const connectorCreateData = useSelector(selectConnectorCreateData);

  const { connectors, isLoading } = accountsListState;

  useEffect(() => {
    dispatch(fetchAccountCreateData());
    dispatch(fetchAccountsList());

    const searchUrl = new URLSearchParams(location.search);
    const connectionHint = searchUrl.get('selectedProvider');

    if (connectionHint) {
      setPreSelectedProvied(connectionHint as ServiceProvider);
    }
  }, []);

  const mappedChoiceGroupItems = useMemo(
    () =>
      mapConnectors(
        connectors
      ),
    [connectors, location.search]
  );

  useEffect(() => {
    if (preSelectedProvied && mappedChoiceGroupItems.length) {
      const choiceGroupItem = find(mappedChoiceGroupItems, {
        serviceProvider: preSelectedProvied
      });

      if (choiceGroupItem) {
        onOptionChanged(choiceGroupItem);
      }
    }
  }, [preSelectedProvied, mappedChoiceGroupItems]);

  const onOptionChanged = (value: IChoiceGroupOption) => {
    const accountCreationData: CreateAccountInputDto = new CreateAccountInputDto(
      {
        // @ts-ignore
        serviceProvider: value.serviceProvider,
        // serviceProviderName: chosenOption.text,
        // @ts-ignore
        webRoot: value.webRoot,
        // @ts-ignore
        isDefault: value.isDefault,
        name: value.name
      }
    );

    dispatch(selectProvider(accountCreationData));
    setChangedStatus(true);
  };

  const onCreateAccount = () => {
    // * This dispatched action is the solution for webRoot cleaning when creating new account
    // * Try to find other solution, this one is invoking an action from a slice in another feature
    dispatch(clearConnectionEditData());
    setStep(ConnectionSteps.ADDRESS_SELECTION);
  };

  const onCreateAccountWithOauth = useCallback(() => {
    setIsLoading(true);
    dispatch(
      onWebRootContinueCreateClick({
        webRoot: selectedProvider.webRoot,
        withOauth: true
      })
    );
  }, [dispatch, selectedProvider.webRoot]);

  useEffect(() => {
    if (preSelectedProvied === connectorCreateData.serviceProvider) {
      onCreateAccountWithOauth();
    }
  }, [onCreateAccountWithOauth, preSelectedProvied, connectorCreateData]);

  if (isLoading || isOauthLoading) {
    return <Loading />;
  }

  return (
    <ProvidersContainer>
      <ConnectionTitle>Connect Account</ConnectionTitle>
      <ConnectionSubtitle>
        Please choose a system you want to integrate
      </ConnectionSubtitle>
      <AccountsSelectorContainer>
        {mappedChoiceGroupItems.map((item) => (
          <AccountSelector
            key={item.key}
            item={item}
            onChange={onOptionChanged}
            isSelected={
              isChanged &&
              selectedProvider?.serviceProvider === item.serviceProvider
            }
          />
        ))}
      </AccountsSelectorContainer>
      <br />
      <ActionsContainer>
        <Button
          onClick={() => {
            isProviderWithOauth(selectedProvider.serviceProvider) ? onCreateAccountWithOauth() : onCreateAccount();
            mixpanelService.sendEvent(EventName.ACCOUNT, 'Create', [selectedProvider.serviceProvider]);
          }}
          disabled={!isChanged}
          title="Create"
        />
        <Link to={`/#${getConnectionType(location.search)}`}>
          <Button isTransparent title="Cancel" />
        </Link>
      </ActionsContainer>
    </ProvidersContainer>
  );
};

export default Providers;
