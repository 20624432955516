import { useEffect, useRef, useState } from 'react';

export default function useComponentVisible(initialIsVisible: boolean) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const visibilityRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (visibilityRef.current && !visibilityRef.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    setIsComponentVisible(initialIsVisible);
  }, [initialIsVisible]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { visibilityRef, isComponentVisible, initialIsVisible };
}
