import styled from 'styled-components';
import { ReactComponent as MenuIcon } from '../images/MenuIcon.svg';
import { ReactComponent as ActiveMenuIcon } from '../images/ActiveMenuIcon.svg';

export const StyledMenuIcon = styled(MenuIcon)`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 22px;
`;

export const StyledActiveMenuIcon = styled(ActiveMenuIcon)`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 22px;
`;
