import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import {
  ContactLookupItem,
  ItemType,
  ITypeaheadInput,
  LookupItem,
  MailLookupItem,
  TypeaheadInput,
  TypeaheadResponse,
} from '../../gen/src/ahauOpenAPI';
import { getApiClient } from '../api';

export interface SearchDataState {
  searchData: TypeaheadResponse[]; // Search stack per account
  isLoading: boolean;
}

const initialState: SearchDataState = {
  searchData: [],
  isLoading: false,
};

export const searchDataSlice = createSlice({
  name: 'searchData',
  initialState,
  reducers: {
    resetSearchData: (state) => {
      state.searchData = [];
    },
    concatSearchData: (
      state,
      action: PayloadAction<TypeaheadResponse | undefined>
    ) => {
      if (action.payload)
        state.searchData = state.searchData.concat(action?.payload);
    },
    setIsLoading: (state, action: PayloadAction<boolean | undefined>) => {
      state.isLoading = action?.payload || false;
    },
  },
});

export const { setIsLoading, resetSearchData, concatSearchData } =
  searchDataSlice.actions;

// thunks

export const fetchSearchData =
  (query: string, accountIds: number[], filter: ItemType): AppThunk =>
  async (dispatch, getState) => {
    if (accountIds.length === 0) return;
    dispatch(searchDataSlice.actions.setIsLoading(true));
    let ongoingAPICalls: number = 0;
    const { accessToken } = getState().appReducer;
    const api = getApiClient(accessToken);

    try {
      const apiCalls = accountIds.map(async (accountId) => {
        ongoingAPICalls += 1;
        try {
          const data = await api.lookupTypeaheadByAccount(
            accountId,
            new TypeaheadInput({
              skipCount: 0,
              maxResultCount: 5,
              filterBy: query,
              types:
                filter === ItemType.NotSet
                  ? [
                      ItemType.Task,
                      ItemType.Mail,
                      ItemType.Bookmark,
                      ItemType.Channel,
                      ItemType.Contact,
                      ItemType.Document,
                    ]
                  : [filter],
            } as ITypeaheadInput)
          );
          dispatch(searchDataSlice.actions.concatSearchData(data));
        } catch (error) {
          console.error(`Error fetching data for account ${accountId}:`, error);
        } finally {
          ongoingAPICalls -= 1;
          if (ongoingAPICalls === 0) {
            dispatch(searchDataSlice.actions.setIsLoading(false));
          }
        }
      });
      await Promise.all(apiCalls);
    } catch (error) {
      console.error('Error:', error);
    }
  };
// selectors

export const selectSearchData = (state: RootState) => state.searchData;
export default searchDataSlice.reducer;
