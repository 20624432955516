/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as AddButton } from '../../../images/AddButton.svg';
import { ConnectAccountContainer } from './style';

interface ICreateConnectionCardProps {
}

const CreateConnectionCard: React.FC<ICreateConnectionCardProps> = (props) => {

  const history = useHistory();

  const connectionHandler = () => {
    history.push('/connection');
  };

  return (
    <>
      <ConnectAccountContainer onClick={connectionHandler}>
        <AddButton />
        <p>Connect Account</p>
      </ConnectAccountContainer>
    </>
  );
};

export default memo(CreateConnectionCard);
