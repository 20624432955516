/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import ErrorPage from '../Error/ErrorPage';
import Profile from '../pages/Profile';
import SetupOrganization from '../pages/Organization';
import UserAction from '../pages/UserAction';
import GoToOrder from '../components/Pricing/GoToOrder';
import AcceptInviteToOrganization from '../pages/Organization/AcceptInviteToOrganization';
import CreateNewOrganization from '../pages/Organization/CreateNewOrganization';
import OrganizationOfTeamMates from '../pages/Organization/OrganizationOfTeamMates';
import InviteTeamMates from '../pages/Organization/InviteTeamMates';
import CreateTeams from '../pages/Organization/CreateTeams';
import Connection from '../pages/Connection';
import Workspaces from '../workspaces';
import Search from '../pages/Search';

interface IRoutes {
  [key: string]: {
    path?: string;
    component?: React.FC;
    render?: (props: any) => JSX.Element;
    getPath?: (props: any) => string;
  };
}

const defaultRender = (): JSX.Element => {
  return <ErrorPage isLocationError={true} />;
};

export const Routes: IRoutes = {
  workspaces: {
    path: '/workspaces',
    component: Workspaces
  },
  profile: {
    path: '/',
    component: Profile
  },
  profileConnection: {
    path: '/connection',
    component: Connection
  },
  organization: {
    path: '/organization',
    component: SetupOrganization
  },
  newOrganization: {
    path: '/organization/new',
    component: CreateNewOrganization
  },
  teamsOrganization: {
    path: '/organization/:id/teams',
    getPath: (id) => `/organization/${id}/teams`,
    component: CreateNewOrganization,
    render: (props: any) => {
      return <CreateTeams {...props.match.params as any} />;
    }
  },
  inviteOrganization: {
    path: '/organization/:id/invite',
    getPath: (id) => `/organization/${id}/invite`,
    component: CreateNewOrganization,
    render: (props: any) => {
      return <InviteTeamMates {...props.match.params as any} />;
    }
  },
  membersOrganization: {
    path: '/organization/:id/members',
    getPath: (id) => `/organization/${id}/members`,
    component: CreateNewOrganization,
    render: (props: any) => {
      return <OrganizationOfTeamMates {...props.match.params as any} />;
    }
  },
  membersManagement: {
    path: '/organization/:id/manage',
    getPath: (id) => `/organization/${id}/manage`,
    component: CreateNewOrganization,
    render: (props: any) => {
      return <OrganizationOfTeamMates {...props.match.params as any} />;
    }
  },
  contactUs: {
    path: '/contactUs',
    component: UserAction
  },
  order: {
    path: '/order',
    component: GoToOrder
  },
  invite: {
    path: '/invite/:token',
    component: AcceptInviteToOrganization
  },
  search: {
    path: '/search',
    component: Search
  },
  error: {
    path: '/error',
    component: ErrorPage
  },
  default: {
    render: defaultRender
  }
};
