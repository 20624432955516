/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Column, Container, ImageBlock, Img, Logo, Text, TextLink } from './style';
import LogoImage from '../../images/logo-white.svg';
import LocationImage from '../../images/location.svg';
import EmailImage from '../../images/email.svg';
import ContactImage from '../../images/contact.svg';
import { redirectMobileAction, setModal } from '../../utils/appReducer';
import { WidthContext } from '../../App';
import { CONTACT_US, DestineLocations } from '../Modals/constants';
import {
  COMPANY_EMAIL,
  COMPANY_KB,
  COMPANY_LICENSE,
  COMPANY_LOCATION,
  COMPANY_TEL,
  COMPANY_TERMS,
  GET_SUPPORT,
  INTEGRATION_ADOBE,
  INTEGRATION_CHROME,
  INTEGRATION_MOBILE,
  INTEGRATION_OUTLOOK,
  INTEGRATION_TEAMS,
  REQUEST_DEMO
} from '../../utils/constants';
import SocialNetworks from './SocialNetworks';

const Footer = () => {
  const dispatch = useDispatch();

  const width = useContext(WidthContext);
  const isMobile = width <= 768;

  const onModal = (modalType: string, actionName?: string) => {
    dispatch(
      setModal({
        type: modalType,
        action: actionName ?? ''
      })
    );

    if (isMobile) {
      dispatch(
        redirectMobileAction(
          Object(DestineLocations)[modalType],
          false,
          window.pageYOffset
        )
      );
    }
  };

  return (
    <Container>
      <Column>
        <Logo src={LogoImage} title="AHAU260" alt="AHAU260 Logo" />
        <ImageBlock>
          <Img
            src={LocationImage}
            alt="Company Location"
            title="Company Location"
          />
          <TextLink to={{ pathname: COMPANY_LOCATION }} target="_blank">
            Azores, Portugal
          </TextLink>
        </ImageBlock>
        <ImageBlock>
          <Img src={EmailImage} alt="Company Email" title="Company Email" />
          <Text>
            <a href={`mailto:${COMPANY_EMAIL}`}>{COMPANY_EMAIL}</a>
          </Text>
        </ImageBlock>
        <ImageBlock>
          <Img
            src={ContactImage}
            alt="Company Contact"
            title="Company Contact"
          />
          <Text>
            <a href={`tel:${COMPANY_TEL}`}>296 912 332</a>
          </Text>
        </ImageBlock>
      </Column>
      <Column>
        <Text notClickable bold>
          Support
        </Text>
        <TextLink to={{ pathname: GET_SUPPORT }} target="_blank">
          Get Support
        </TextLink>
        <Text onClick={() => onModal(CONTACT_US, 'Contact Us')}>Contacts</Text>
        <TextLink to={{ pathname: REQUEST_DEMO }} target="_blank">
          Request a Demo
        </TextLink>
        <TextLink to={{ pathname: COMPANY_KB }} target="_blank">
          Knowledge Base
        </TextLink>
      </Column>
      <Column>
        <Text notClickable bold>
          Integrations
        </Text>
        <TextLink to={{ pathname: INTEGRATION_OUTLOOK }} target="_blank">
          Microsoft Outlook
        </TextLink>
        <TextLink to={{ pathname: INTEGRATION_ADOBE }} target="_blank">
          Adobe Creative Cloud
        </TextLink>
        <TextLink to={{ pathname: INTEGRATION_CHROME }} target="_blank">
          Chromium Browsers
        </TextLink>
        <TextLink to={{ pathname: INTEGRATION_TEAMS }} target="_blank">
          Microsoft Teams
        </TextLink>
        <TextLink to={{ pathname: INTEGRATION_MOBILE }} target="_blank">
          Mobile Platform
        </TextLink>
      </Column>
      <Column>
        <Text notClickable bold>
          Follow Us
        </Text>
        <SocialNetworks />
        <Text notClickable bold>
          Legal
        </Text>
        <TextLink to={{ pathname: COMPANY_LICENSE }} target="_blank">
          Privacy and Policy
        </TextLink>
        <TextLink to={{ pathname: COMPANY_TERMS }} target="_blank">
          Terms of Use
        </TextLink>
        <Text
          onClick={() => {
            // @ts-ignore
            const hsp = (window._hsp = window._hsp || []);
            hsp.push(['showBanner']);
          }}
        >
          Cookie Settings
        </Text>
      </Column>
    </Container>
  );
};

export default Footer;
