import * as React from 'react';
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
} from '@fluentui/react/lib/Dropdown';
import { searchFilterStyle } from './style';
import { ItemType } from '../../../../gen/src/ahauOpenAPI';
import DefaultTooltip from '../../DefaultTooltip';

interface SearchItemFiltersProps {
  setFilter: React.Dispatch<React.SetStateAction<ItemType>>;
}

export const SearchItemFilters: React.FunctionComponent<
  SearchItemFiltersProps
> = ({ setFilter }) => {
  const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();

  const DropdownControlledMultiExampleOptions = [
    { key: ItemType.NotSet, text: 'All items' },
    { key: -1, text: '-', itemType: DropdownMenuItemType.Divider },
    { key: ItemType.Document, text: 'Documents' },
    { key: ItemType.Contact, text: 'Contacts' },
    { key: ItemType.Task, text: 'Tasks' },
  ];

  const onChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<any>,
    index?: number | undefined
  ): void => {
    setSelectedItem(option);
    setFilter(option?.key as ItemType);
  };

  return (
    <DefaultTooltip text="Search Filter">
      <Dropdown
        selectedKey={selectedItem ? selectedItem.key : ItemType.NotSet}
        onChange={onChange}
        options={DropdownControlledMultiExampleOptions}
        styles={searchFilterStyle}
      />
    </DefaultTooltip>
  );
};
