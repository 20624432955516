/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable no-console */
import _mixpanel, { Dict } from 'mixpanel-browser';
import { getConfig } from '../config';
import { AccountInfoDto } from '../gen/src/ahauOpenAPI';

interface ProfileProperties {
  ServiceProviders?: ServiceProvider[];
}

interface ServiceProvider {
  ServiceName: string;
  Status: string;
}

interface IEventProperties {
  ServiceProvider?: string;
  Action?: string;
  Description?: string;
  Result?: string;
}

export enum EventName {
  NAVIGATION,
  TEAM,
  TASK,
  ACCOUNT,
  ORGANIZATION,
  MEMBER,
}

interface IEvent {
  eventName: EventName;
  properties: (action?: string, args?: Array<any>) => Dict;
}

const events: IEvent[] = [
  {
    eventName: EventName.NAVIGATION,
    properties: (action?: string) => {
      return {
        Description: 'Page Navigation Interactions',
        Action: action,
      } as IEventProperties;
    },
  },
  {
    eventName: EventName.TEAM,
    properties: (action?: string) => {
      return {
        Description: 'Team interactions',
        Action: action,
      } as IEventProperties;
    },
  },
  {
    eventName: EventName.TASK,
    properties: (action?: string) => {
      return {
        Description: 'Task interactions',
        Action: action,
      } as IEventProperties;
    },
  },
  {
    eventName: EventName.ACCOUNT,
    properties: (action?: string, args?: Array<any>) => {
      return {
        Description: 'Service Provider / Account interactions',
        Action: action,
        ServiceProvider: args && args.length > 0 ? args[0] : undefined,
        Result: args && args.length > 1 ? args[1] : undefined,
      } as IEventProperties;
    },
  },
  {
    eventName: EventName.ORGANIZATION,
    properties: (action?: string) => {
      return {
        Description: 'Organization Interactions',
        Action: action,
      } as IEventProperties;
    },
  },
  {
    eventName: EventName.MEMBER,
    properties: (action?: string, args?: Array<any>) => {
      return {
        ...({
          Description: 'Organization Members Management',
          Action: action,
        } as IEventProperties),
        ...{
          Invite: args && args.length > 0 ? args[0] : undefined,
        },
      };
    },
  },
];

class MixpanelService {
  // Apparently, this is called on every page reload, so we don't want that,
  constructor() {
    if (!getConfig().mixpanelToken) {
      console.error('Mixpanel Token is null or undefined');
      return;
    }
    // Check other config options if necessary (there are a lot of options)
    _mixpanel.init(getConfig().mixpanelToken, { debug: true });
  }

  init(email: string) {
    _mixpanel.identify(email);
  }

  // Track Events

  sendEvent(eventName: EventName, action?: string, args?: Array<any>) {
    const event = events.find((e) => e.eventName === eventName);
    if (!event) {
      console.warn(
        `Attempted no send undefined Event: ${EventName[eventName]}`
      );
      return;
    }

    this.sendEventWithId(
      EventName[event.eventName],
      event.properties ? event.properties(action, args) : undefined
    );
  }

  sendEventWithId(eventName: string, properties?: Dict) {
    _mixpanel.track(eventName, { ...properties, ...{ ID: 'start ahau' } });
  }

  // User Profile

  setUserAccounts(accounts: AccountInfoDto[] | undefined) {
    const serviceProviders: ServiceProvider[] = [];
    accounts?.forEach((element) => {
      if (element.serviceProvider && element.status)
        serviceProviders.push({
          ServiceName: element.serviceProvider,
          Status: element.status,
        });
    });
    this.setUserProfile({ ServiceProviders: serviceProviders });
  }

  setUserProfile(profileProperties?: ProfileProperties, once: boolean = false) {
    if (once) _mixpanel.people.set_once(profileProperties as Dict);
    else _mixpanel.people.set(profileProperties as Dict);
  }

  incrementProfileProperties(profileProperties: ProfileProperties) {
    _mixpanel.people.increment(profileProperties as Dict);
  }

  // Custom Events
  sendCustomEvent(eventName: string, eventProperties?: Dict) {
    this.sendEventWithId(eventName, eventProperties);
  }

  sendUniqueEvent(eventName: string, description?: string) {
    this.sendEventWithId(eventName, {
      Description: description,
    } as IEventProperties);
  }
}

const mixpanelService = new MixpanelService();
export default mixpanelService;
