import styled from 'styled-components';
import { BLUE_COLOR } from '../consts/theme';

export const Headline = styled.div<{
  isAboutUsLayout?: boolean;
  color?: string;
}>`
  font-size: clamp(38px, 4vw, 45px);
  line-height: 64px;
  font-family: 'TTHoves-DemiBold';
  color: ${(props) => props.color || '#d3d3d3'};
  margin-bottom: ${(props) => (props.isAboutUsLayout ? '10px' : '80px')};

  @media (max-width: 980px) {
    line-height: 120%;
  }

  @media (max-width: 768px) {
    line-height: 120%;
  }

  @media (max-width: 550px) {
    margin-bottom: 35px;
  }
`;

export const ProfileHeadline = styled.h1`
  color: #464650;
  font-size: 32px;
  font-weight: 700;

  @media (max-width: 537px) {
    margin-bottom: 36px;
  }
`;

export const AboutUsHeadline = styled(Headline)`
  margin-bottom: 10px;
`;

export const FeaturesHeadline = styled.span`
  font-family: 'TTHoves-DemiBold';
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: ${BLUE_COLOR}40;
  margin-bottom: 65px;
`;
