import * as React from 'react';
import { SearchBoxInput } from './SearchBox';
import ResultsList from './Results/ResultsList';
import HistoryList from './History/HistoryList';
import {
  absoluteContainerStyle,
  searchAreaStyle,
  searchContainerStyle,
} from './style';
import { ItemType } from '../../../gen/src/ahauOpenAPI';

export const SearchBoxContainer: React.FC = () => {
  const [isHovered, setHovered] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [displayResults, setDisplayResults] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>('');
  const [isSearching, setIsSearching] = React.useState<boolean>(true);
  const [filter, setFilter] = React.useState<ItemType>(ItemType.NotSet);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        isFocused &&
        !containerRef.current.contains(event.target as Node)
      ) {
        handleSearchBoxBlur();
        setIsSearching(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFocused, displayResults]);

  const handleSearchBoxFocus = () => {
    setIsFocused(true);
  };

  const handleSearchBoxBlur = () => {
    setIsFocused(false);
    setDisplayResults(false);
  };

  const handleSearchQueryChange = (query: string | undefined) => {
    setSearchQuery(query);
    if (displayResults) setDisplayResults(false);
  };

  const handleHistoryRowClick = (query: string) => {
    setSearchQuery(query);
    setDisplayResults(true);
    setIsSearching(false);
  };

  return (
    <div style={searchAreaStyle} ref={containerRef}>
      <div style={absoluteContainerStyle}>
        <div
          style={searchContainerStyle(isHovered, isFocused)}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <SearchBoxInput
            isFocused={isFocused}
            onFocus={handleSearchBoxFocus}
            onBlur={handleSearchBoxBlur}
            onSearchQueryChange={handleSearchQueryChange}
            setDisplayResults={setDisplayResults}
            defaultQuery={searchQuery}
            setDefaultQuery={setSearchQuery}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            setFilter={setFilter}
          />
          {isFocused && displayResults ? (
            <ResultsList searchQuery={searchQuery} filter={filter} />
          ) : (
            isFocused && (
              <HistoryList
                onHistoryRowClick={handleHistoryRowClick}
                searchQuery={searchQuery}
              />
            )
          )}
        </div>
        <div
          style={{
            marginBottom: '8px',
          }}
        ></div>
      </div>
    </div>
  );
};
