import React, { useContext, useEffect, useState } from 'react';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getReturnUri, selectError } from '../utils/appReducer';
import { ErrorBoxContainer, ErrorBoxInner, ErrorWrapperContainer } from './styles';
import { WidthContext } from '../App';
import { Loading } from '../pages/Profile/Loading';
import { checkIsModalRouting } from '../utils/utilsFunctions';
import { CONTACT_US } from '../components/Modals/constants';
import mixpanelService from '../services/MixpanelService';

const ErrorPage: React.FC<{ isLocationError?: boolean; returnUri?: string }> = (
  props
) => {
  const { isLoading } = useAuth0();

  const location = useLocation<Location>();
  const width = useContext(WidthContext);

  const returnUri = props.returnUri
    ? props.returnUri
    : getReturnUri(location.search);

  const requestError = useSelector(selectError);

  const [errorState, setErrorState] = useState<{
    modalType: string;
    scrollPosition: number;
  }>({
    modalType: '',
    scrollPosition: 0
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.state) {
      const { modalType, scrollPosition } = location.state as {
        modalType?: string;
        scrollPosition?: number;
      };

      setErrorState({
        modalType: modalType ?? CONTACT_US,
        scrollPosition: scrollPosition ?? 0
      });
    }
  }, [location.state]);

  const errorType = requestError.type
    ? requestError.type
    : props.isLocationError
      ? 'LocationError'
      : 'DefaultError';

  const Error: React.FC<{ errorType: string }> = (errorProps): JSX.Element => {
    mixpanelService.sendCustomEvent('ERROR', { errorType });
    if (errorProps.errorType === 'AccessDenied') {
      return (
        <>
          <h3>There was an error!</h3>
          <p>
            {requestError.text}. Please try&nbsp;
            <Link
              to={{
                pathname: '/',
                state: {
                  retryLogin: true
                }
              }}
            >
              {' '}
              signing in
            </Link>
            &nbsp;again.
          </p>
        </>
      );
    }

    if (errorProps.errorType === 'ConsentRequired') {
      return (
        <>
          <h3>There was an error!</h3>
          <p>
            {requestError.text}. Please try&nbsp;
            <Link
              to={{
                pathname: '/',
                state: {
                  retryLogin: true
                }
              }}
            >
              {' '}
              signing in
            </Link>
            &nbsp;again.
          </p>
        </>
      );
    }

    if (errorProps.errorType === 'FetchFailed') {
      return (
        <>
          <h3>There was an error!</h3>
          <p>
            {requestError.text}. Please try&nbsp;
            <Link
              to={{
                pathname: '/',
                state: {
                  retryLogin: true
                }
              }}
            >
              {' '}
              signing in
            </Link>
            &nbsp;again.
          </p>
        </>
      );
    }

    if (errorProps.errorType === 'LocationError') {
      return (
        <>
          <h3>Page not found!</h3>
          <p>
            You may have followed a broken link or entered a URL that doesn't
            exist.&nbsp;
            <Link
              onClick={() => {
                if (width > 768) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 0);
                }
              }}
              to={{
                pathname: '/',
                state: {
                  modalType: errorState.modalType,
                  scrollPosition: errorState.scrollPosition
                }
              }}
            >
              Return to homepage
            </Link>{' '}
            and try again.
          </p>
        </>
      );
    }

    if (errorProps.errorType === 'FormSubmissionFailed') {
      return (
        <>
          <h3>Form submission failed!</h3>
          <p>
            {requestError.text}. Please&nbsp;
            <Link
              onClick={() => {
                if (width > 768) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 0);
                }
              }}
              to={{
                pathname: returnUri ?? '/',
                state: {
                  modalType: errorState.modalType,
                  scrollPosition: errorState.scrollPosition
                }
              }}
            >
              refresh the page
            </Link>{' '}
            and try again.
          </p>
        </>
      );
    }

    return (
      <>
        <h3>There was an error!</h3>
        <p>
          There was some problem with your request. Please&nbsp;
          <Link
            onClick={() => {
              if (width > 768) {
                setTimeout(() => {
                  window.location.reload();
                }, 0);
              }
            }}
            to={{
              pathname: returnUri ?? '/',
              state: {
                modalType: errorState.modalType,
                scrollPosition: errorState.scrollPosition
              }
            }}
          >
            refresh the page
          </Link>{' '}
          and try again.
        </p>
      </>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  if (checkIsModalRouting(location.pathname)) {
    return <></>;
  }

  return (
    <ErrorWrapperContainer>
      <ErrorBoxContainer>
        <ErrorBoxInner>
          <Icon iconName="StatusErrorFull" style={{}} />
          <Error errorType={errorType} />
        </ErrorBoxInner>
      </ErrorBoxContainer>
    </ErrorWrapperContainer>
  );
};

export default ErrorPage;
