import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ActivityItemIdentity,
  ContactItemIdentity,
  DocumentItemIdentity,
  PaneData,
  PaneItemIdentity,
  PaneType,
  ServiceProvider,
  WorkspaceDto,
} from '../../gen/src/ahauOpenAPI';
import { getApiClient } from '../api';
import store, { AppThunk, RootState } from '../store';

export interface WorkspacesListState {
  workspaces: Array<WorkspaceDto>;
  currentWorkspaceData: PaneData | undefined;
}

const initialState: WorkspacesListState = {
  workspaces: [],
  currentWorkspaceData: undefined,
};

export const workspacesListSlice = createSlice({
  name: 'workspacesList',
  initialState,
  reducers: {
    setWorkspacesList: (
      state,
      action: PayloadAction<WorkspaceDto[] | undefined>
    ) => {
      state.workspaces = action.payload ?? [];
    },
    setCurrentWorkspaceData: (
      state,
      action: PayloadAction<PaneData | undefined>
    ) => {
      state.currentWorkspaceData = action.payload ?? undefined;
    },
  },
});

export const { setWorkspacesList, setCurrentWorkspaceData } =
  workspacesListSlice.actions;

// thunks

export const fetchWorkspacesList =
  (): AppThunk => async (dispatch, getState) => {
    const { accessToken } = getState().appReducer;
    const api = getApiClient(accessToken);

    try {
      let workspacesList = await api.workspacesGetMyWorkspaces();
      const organizations = await api.membershipsGetOrganizations();

      for (const organization of organizations) {
        let workspaces = await api.workspacesGetWorkspaces(organization.id);
        workspacesList = workspacesList.concat(workspaces);
      }

      dispatch(workspacesListSlice.actions.setWorkspacesList(workspacesList));
    } catch (error) {
      console.error('Error:', error);
    }
  };

export const fetchWorkspacePaneData =
  (workspaceId: string): AppThunk =>
  async (dispatch, getState) => {
    const { accessToken } = await getState().appReducer;
    const api = getApiClient(accessToken);
    if (!accessToken) {
      const unsubscribe = store.subscribe(() => {
        const { accessToken: newAccessToken } = getState().appReducer;
        if (newAccessToken) {
          unsubscribe();
          dispatch(fetchWorkspacePaneData(workspaceId));
        }
      });
      return;
    }

    try {
      const paneData = await api.workspacesGetPaneForWorkspace(workspaceId);
      dispatch(workspacesListSlice.actions.setCurrentWorkspaceData(paneData));
    } catch (error) {
      console.error('Error:', error);
    }
  };

// --- Link Items to Workspace ---

// Task
export const linkUnlinkTaskToWorkspace =
  (fullId: string, unlink: boolean = false): AppThunk =>
  async (dispatch, getState) => {
    const currentWorkspaceId =
      getState().workspaces.currentWorkspaceData?.workspace?.id;
    if (!currentWorkspaceId) return;

    const { accessToken } = getState().appReducer;
    const api = getApiClient(accessToken);

    try {
      var ref2 = await api.linksIdentifyItem(
        new PaneItemIdentity({
          paneType: PaneType.Activity,
          activity: new ActivityItemIdentity({
            activityId: fullId,
          }),
        })
      );
      if(unlink) await api.workspacesRemoveItemRefToWorkspace(currentWorkspaceId, ref2);
      else await api.workspacesAddItemRefToWorkspace(currentWorkspaceId, ref2);

      dispatch(fetchWorkspacePaneData(currentWorkspaceId));
    } catch (error) {
      console.error('Error:', error);
    }
  };

// Document
export const linkUnlinkDocumentToWorkspace =
  (
    url: string | undefined,
    clientId: string | undefined,
    name: string,
    fileSize: number | undefined,
    service: ServiceProvider | undefined,
    unlink: boolean = false
  ): AppThunk =>
  async (dispatch, getState) => {
    const currentWorkspaceId =
      getState().workspaces.currentWorkspaceData?.workspace?.id;
    if (!currentWorkspaceId) return;

    const { accessToken } = getState().appReducer;
    const api = getApiClient(accessToken);

    try {
      var ref2 = await api.linksIdentifyItem(
        new PaneItemIdentity({
          paneType: PaneType.Document,
          originalUrl: url,
          document: new DocumentItemIdentity({
            documentId: clientId,
            documentPath: url,
            title: name,
            isLocalFile: false,
            fileSize: fileSize,
            serviceProvider: service,
          }),
        })
      );
      if(unlink) await api.workspacesRemoveItemRefToWorkspace(currentWorkspaceId, ref2);
      else await api.workspacesAddItemRefToWorkspace(currentWorkspaceId, ref2);

      dispatch(fetchWorkspacePaneData(currentWorkspaceId));
    } catch (error) {
      console.error('Error:', error);
    }
  };

// Contact
export const linkUnlinkContactToWorkspace =
  (
    email: string,
    name: string,
    url: string | undefined,
    service: ServiceProvider | undefined,
    unlink: boolean = false
  ): AppThunk =>
  async (dispatch, getState) => {
    const currentWorkspaceId =
      getState().workspaces.currentWorkspaceData?.workspace?.id;
    if (!currentWorkspaceId) return;

    const { accessToken } = getState().appReducer;
    const api = getApiClient(accessToken);

    try {
      var ref2 = await api.linksIdentifyItem(
        new PaneItemIdentity({
          paneType: PaneType.Contact,
          originalUrl: url,
          contact: new ContactItemIdentity({
            contactEmail: email,
            contactName: name,
            serviceProvider: service,
          }),
        })
      );
      if(unlink) await api.workspacesRemoveItemRefToWorkspace(currentWorkspaceId, ref2);
      else await api.workspacesAddItemRefToWorkspace(currentWorkspaceId, ref2);

      dispatch(fetchWorkspacePaneData(currentWorkspaceId));
    } catch (error) {
      console.error('Error:', error);
    }
  };

// selectors

export const selectWorkspacesList = (state: RootState) => state.workspaces;
export default workspacesListSlice.reducer;
