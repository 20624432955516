import { ServiceProvider } from '../gen/src/ahauOpenAPI';

export const ERROR_MESSAGE =
  "Something went wrong with your request. We couldn't retrieve what you're looking for";

export enum ConnectionSteps {
  SELECT = 'select',
  AUTORIZE = 'authorize',
  EDIT = 'edit',
  ADDRESS_SELECTION = 'addressSelection'
}

export const ConnectionStepNumbers = {
  [ConnectionSteps.SELECT]: 1,
  [ConnectionSteps.ADDRESS_SELECTION]: 2,
  [ConnectionSteps.AUTORIZE]: 3,
  [ConnectionSteps.EDIT]: 4
};

export enum SetupOrgSteps {
  CREATE_ORGANIZATION = 1,
  ADD_TEAMS = 2,
  ADD_TEAM_MATES = 3,
  ORGANIZE_USERS = 4
}

export enum AuthScreenHint {
  SIGN_UP = 'signup'
}

export enum BillingTerms {
  MONTHLY = 'Monthly',
  ANNUAL = 'Yearly',
  TRIAL = 'Trial'
}

export enum LicenseStatusInfo {
  LICENSED = 'Licensed',
  EXPIRED = 'Expired'
}

export const CRM_PROVIDERS = [ServiceProvider.HubSpot];
export const DATA_SOURCE_PROVIDERS = [
  ServiceProvider.Office365,
  ServiceProvider.Google
];
