/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { memo, useContext } from 'react';
import styled from 'styled-components';
import Question, { IQuestion } from './Question';
import { Headline, ProfileHeadline } from './Headline';
import Gallery from './Gallery';
import { WidthContext } from '../App';
import { BLUE_COLOR } from '../consts/theme';

interface QuestionsListProps {
  items: IQuestion[];
  isProfilePage?: boolean;
}

const Container = styled.div`
  margin-bottom: 60px;

  @media (max-width: 360px) {
    margin-bottom: 60px;
  }
`;

const QuestionsList: React.FC<QuestionsListProps> = (props) => {
  const width = useContext(WidthContext);

  const { items, isProfilePage } = props;

  const isTablet = items.length > 2 ? width <= 854 && width > 590 : undefined;
  const isMobile = width <= 590 || (items.length === 2 && width <= 815);
  const size = isMobile ? 1 : isTablet ? 2 : 3;

  return (
    <Container>
      {isProfilePage ? (
        <ProfileHeadline>Maintenance</ProfileHeadline>
      ) : (
        <Headline color={`${BLUE_COLOR}40`}>Got questions?</Headline>
      )}
      <Gallery
        items={items}
        size={size}
        component={Question}
        isProfilePage={isProfilePage}
      />
    </Container>
  );
};

export default memo(QuestionsList);
