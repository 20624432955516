/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-regex-spaces */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Icon } from '@fluentui/react';
import { WidthContext } from '../../App';
import Button from '../Button';
import { onSendUserActionForm, selectEmail, selectTypeOfModal } from '../../utils/appReducer';
import './style.css';
import {
  Error,
  Footer,
  Input,
  InputContainer,
  InputPlaceholder,
  SuccessModalBody,
  SuccessModalHeader,
  TextArea,
  Title
} from './components';
import { UserActions } from './constants';
import { Loading } from '../../pages/Profile/Loading';
import { IGetStartedForm } from './UserActionModal';
import FooterText from './FooterText';
import { INPUT_PATTERNS } from '../../utils/constants';

interface IUserActionForm {
  actionName: string;
  showUsersField: boolean;
  showCommentsField: boolean;
  showDetailsField: boolean;
  isLoading: boolean;
  showSuccessMessage: boolean;
  successMessageText: string;
}

const UserActionForm: React.FC<IUserActionForm> = (props) => {
  const dispatch = useDispatch();

  const width = useContext(WidthContext);
  const isMobile = width <= 768;

  const email = useSelector(selectEmail);
  const modalType = useSelector(selectTypeOfModal);

  const usersFieldRef = useRef<HTMLInputElement | null>(null);

  const defaultFormValues = {
    name: '',
    email: '',
    phone: '',
    numberOfUsers: '',
    details: '',
    comments: ''
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    errors,
    getValues
  } = useForm({
    defaultValues: defaultFormValues
  });

  useEffect(() => {
    reset({ email });
  }, [email, reset]);

  const getInputPattern = (inputType: 'email' | 'phone') => {
    return Object(INPUT_PATTERNS)[inputType];
  };

  const onSubmit = (formData: IGetStartedForm) => {
    const userAction = Object(UserActions)[modalType];
    return dispatch(
      onSendUserActionForm(userAction, formData, isMobile, window.pageYOffset)
    );
  };

  // Used for fields placeholders functionality
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  if (props.isLoading) {
    return (
      <>
        <Title removeMarginBottom={props.showSuccessMessage}>
          {props.actionName}
        </Title>
        <Loading
          isModalLoading
          extraStyles={{
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
        <Footer>
          <FooterText />
        </Footer>
      </>
    );
  }

  if (props.showSuccessMessage) {
    return (
      <>
        <SuccessModalHeader>
          <Icon iconName="SkypeCircleCheck" />
        </SuccessModalHeader>
        <SuccessModalBody>
          <h2>Success!</h2>
          <h5>{props.successMessageText}</h5>
        </SuccessModalBody>
      </>
    );
  }

  const formTitle =
    modalType === 'CONTACT_US' ? 'Get in touch' : props.actionName;
  const buttonText = modalType === 'CONTACT_US' ? 'Submit' : props.actionName;

  return (
    <>
      <Title>{formTitle}</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <InputContainer>
            <Input
              name="name"
              isRequired
              ref={register({ required: true })}
              onChange={(e) => {
                if (e.target.value !== '') {
                  forceUpdate();
                }
              }}
              onBlur={() => forceUpdate()}
            />

            <InputPlaceholder fieldValue={getValues().name}>
              Full Name
            </InputPlaceholder>

            {errors.name && <Error>Full Name field is required.</Error>}
          </InputContainer>

          <InputContainer>
            <Input
              name="email"
              isRequired
              ref={register({
                required: true,
                pattern: getInputPattern('email')
              })}
              onChange={(e) => {
                setValue('email', e.target.value.trim());

                if (e.target.value !== '') {
                  forceUpdate();
                }
              }}
              onBlur={() => forceUpdate()}
            />

            <InputPlaceholder fieldValue={getValues().email}>
              Business Email
            </InputPlaceholder>

            {errors.email?.type === 'required' && (
              <Error>Business Email field is required.</Error>
            )}
            {errors.email?.type === 'pattern' && (
              <Error>Invalid Email entered.</Error>
            )}
          </InputContainer>

          {props.showUsersField && (
            <InputContainer>
              <Input
                name="numberOfUsers"
                type="number"
                isRequired
                ref={(e) => {
                  register(e, {
                    required: true,
                    setValueAs: (value) => Number(value)
                  });
                  usersFieldRef.current = e;
                }}
                onChange={(e) => {
                  if (e.target.value !== '') {
                    forceUpdate();
                  }
                }}
                onBlur={() => forceUpdate()}
                onKeyDown={(e) => {
                  if (
                    e.key === '-' ||
                    e.key === '+' ||
                    e.key === '.' ||
                    e.key === ',' ||
                    e.key === '0'
                  ) {
                    e.preventDefault();
                  }
                }}
              />

              <InputPlaceholder
                fieldName="numberOfUsers"
                fieldValue={getValues().numberOfUsers}
              >
                Potential Users Number
              </InputPlaceholder>

              {errors.numberOfUsers?.type === 'required' && (
                <Error>Potential users number field is required.</Error>
              )}
            </InputContainer>
          )}

          <InputContainer>
            <Input
              name="phone"
              ref={register({ pattern: getInputPattern('phone') })}
              onChange={(e) => {
                if (e.target.value !== '') {
                  forceUpdate();
                }
              }}
              onBlur={() => forceUpdate()}
            />
            {errors.phone && <Error>Must be a number.</Error>}

            <InputPlaceholder fieldValue={getValues().phone}>
              Business Phone
            </InputPlaceholder>
          </InputContainer>

          {props.showDetailsField && (
            <InputContainer>
              <TextArea
                name="details"
                ref={register()}
                isRequired
                onChange={(e) => {
                  if (e.target.value !== '') {
                    forceUpdate();
                  }
                }}
                onBlur={() => forceUpdate()}
              />

              <InputPlaceholder fieldValue={getValues().details}>
                Details
              </InputPlaceholder>

              {errors.details?.type === 'required' && (
                <Error>Details field is required.</Error>
              )}
            </InputContainer>
          )}

          <InputContainer>
            {props.showCommentsField && (
              <InputContainer>
                <TextArea
                  name="comments"
                  ref={register()}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      forceUpdate();
                    }
                  }}
                  onBlur={() => forceUpdate()}
                />

                <InputPlaceholder fieldValue={getValues().comments}>
                  Comments
                </InputPlaceholder>
              </InputContainer>
            )}
          </InputContainer>
        </Modal.Body>
        <Button type="submit">{buttonText}</Button>
      </form>

      <Footer>
        <FooterText />
      </Footer>
    </>
  );
};

export default UserActionForm;
