/* eslint-disable import/no-cycle */
import React, { useContext, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Modal } from 'react-bootstrap';
import cross from '../../images/Cross.svg';
import { Cross } from '../../components/Modals/components';
import { AhauOpenAPI } from '../../gen/src/ahauOpenAPI';
import { WidthContext } from '../../App';
import mixpanelService, { EventName } from '../../services/MixpanelService';

interface DeleteAccountModalProps {
  isDeleteModal: boolean;
  showDeleteModal: (value: boolean) => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = (props) => {
  const { showDeleteModal, isDeleteModal } = props;
  const width = useContext(WidthContext);
  const { logout } = useAuth0();
  const webApi = useRef<AhauOpenAPI | undefined>(undefined);

  // todo - account deletion
  const onDeleteAccount = async () => {
    mixpanelService.sendUniqueEvent('DELETE ACCOUNT', 'User deleted their Account');
    try {
      await webApi.current?.spaAuthDeleteMe().then(() => {
        logout({ returnTo: window.location.origin });
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        show={isDeleteModal}
        onHide={() => showDeleteModal(false)}
        onEscapeKeyDown={() => showDeleteModal(false)}
        dialogClassName="deleteAccountDialog"
        backdropClassName="deleteAccountBackdrop"
        contentClassName="deleteAccountContent"
        className="deleteAccountModal"
      >
        <Modal.Header closeButton={width <= 470 ?? false}>
          <Modal.Title
            style={{ width: '100%' }}
            id="contained-modal-title-vcenter"
          >
            Delete Account
          </Modal.Title>
        </Modal.Header>

        <Cross alwaysShow onClick={() => showDeleteModal(false)}>
          <img src={cross} title="Close" alt="Close button" />
        </Cross>
        <Modal.Body>
          <p>
            Are you sure you want to delete your account? All your association
            data will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer style={{ width: '100%' }}>
          <Button className="btn btn-danger" onClick={onDeleteAccount}>
            Delete
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => showDeleteModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAccountModal;
