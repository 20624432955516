/* eslint-disable import/no-cycle */
import React, { memo, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ProfilePageContainer } from './style';
import ConnectionsList from './ConnectionsList/index';
import IntegrationsList from './IntegrationsList';
import { Loading } from './Loading';
import { profileQuestions } from '../Home/data';
import QuestionsList from '../../components/QuestionsList';
import { selectAccountsList } from '../../utils/reducers/accounts';
import { ACCOUNTS_TITLE, CRM_TITLE, DATA_SOURCES_TITLE } from './data';
import PreferencesList from './PreferencesList';
import OrganizationsList from '../Organization/OrganizationList';
import AccountSettings from './AccountSettings';

const Profile: React.FC = () => {
  const location = useLocation<Location>();
  const [isAccountsLoadingInitial, setIsAccountsLoading] = useState(true);

  const {
    isLoading,
    isAuthenticated
  } = useAuth0();

  const {
    connectedAccounts,
    isLoading: isAccountsLoading
  } = useSelector(selectAccountsList);

  useEffect(() => {
    if (!isAccountsLoading) {
      setIsAccountsLoading(false);
    }
  }, [isAccountsLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (isLoading || isAccountsLoadingInitial) {
    return <Loading />;
  }

  return (
    <>
      {
        isAuthenticated && (
          <ProfilePageContainer id="profile">
            <section id="integration">
              <IntegrationsList />
            </section>
            <br />
            {/* <section id="organization">
              <OrganizationsList />
            </section>
            <br /> */}

            <section id="account">
              <ConnectionsList
                connectionsData={connectedAccounts}
                isLoading={isAccountsLoading}
                title={ACCOUNTS_TITLE}
              />
            </section>
            <br />
            <section id="preference">
              <PreferencesList />
            </section>
            <section id="accountSettings">
              <AccountSettings />
            </section>
           
            <section id="question">
              <QuestionsList isProfilePage items={profileQuestions} />
            </section>
          </ProfilePageContainer>
        )
      }
    </>
  );
};

export default memo(Profile);
