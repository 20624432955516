/* eslint-disable import/no-cycle */
import React, { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import {
  SelectedTeamItem,
  SelectTeamBtn,
  SelectTeamContainer,
  SelectTeamInput,
  SelectTeamItem,
  SelectTeamPopupContainer,
  WidthCalculationBase
} from './style';
import { Cross } from '../style';
import CrossBlack from '../../../images/CrossBlack.svg';
import useComponentVisible from './useComponentVisible';
import { TeamDto, TeamInputDto } from '../../../gen/src/ahauOpenAPI';
import { selectOrganizations } from '../../../utils/reducers/organizations';
import { NEW_ORG_TEAM_COLORS } from '../../../consts/theme';
import mixpanelService, { EventName } from '../../../services/MixpanelService';

export interface TeamsSelectProps {
  invitedMemberTeams?: TeamDto[];
  chooseTeams: (teams: TeamDto[]) => void;
  addNewTeam: (newTeamInput: TeamInputDto, assignedTeams: TeamDto[]) => void;
}

const SelectTeamsSection: React.FC<TeamsSelectProps> = (props) => {

  const { invitedMemberTeams, chooseTeams } = props;
  const { organization: { teams } } = useSelector(selectOrganizations);

  const [typedTeamName, setTypedTeamName] = useState<string>('');

  const [assignedTeams, setAssignedTeams] = useState<TeamDto[]>([]);
  const [teamsForSelect, setTeamsForSelect] = useState<TeamDto[]>(teams ?? []);
  const [isAssignedTeamsChanged, setIsAssignedTeamsChanged] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(30);
  const [displayTeamsList, setDisplayTeamList] = useState<TeamDto[]>([]);

  const { visibilityRef, isComponentVisible } = useComponentVisible(isEditMode);

  const inputFocusRef = useRef(null);
  const spanRef = useRef(null);

  React.useEffect(() => {
    invitedMemberTeams && setAssignedTeams(invitedMemberTeams);
  }, [invitedMemberTeams]);

  const closePopup = useCallback((isClosed: boolean) => {
    isEditMode && !isClosed && isAssignedTeamsChanged && chooseTeams(assignedTeams);
    setIsEditMode(isClosed);
    setTypedTeamName('');
  }, [isEditMode, assignedTeams, isAssignedTeamsChanged]);

  React.useEffect(
    () => closePopup(isComponentVisible),
    [isComponentVisible]
  );

  React.useEffect(() => {
    const selectedTeamsIds = assignedTeams.map((team) => team.id);
    setTeamsForSelect(
      (teams ?? []).filter(
        (team) => !selectedTeamsIds.includes(team.id)
      )
    );
  }, [assignedTeams, teams]);

  React.useEffect(() => {
    let selectTeamList = teamsForSelect;
    if (typedTeamName.length) {
      selectTeamList = teamsForSelect.filter((team) =>
        team?.name?.toLowerCase().startsWith(typedTeamName.toLowerCase())
      );
    }
    setDisplayTeamList(selectTeamList);
  }, [typedTeamName, teamsForSelect]);

  React.useEffect(() => {
    if (!typedTeamName.length && !assignedTeams.length) {
      setWidth(200);
    } else {
      const span: HTMLSpanElement | null = spanRef.current;
      span && setWidth((span as HTMLSpanElement).offsetWidth + 42);
    }
  }, [typedTeamName, assignedTeams]);

  const addNewTeam = useCallback(() => {
    if (
      !typedTeamName.trim().length ||
      teams?.some((team) => team.name === typedTeamName)
    ) return;
    mixpanelService.sendEvent(EventName.TEAM, 'New');
    setIsEditMode(false);
    props.addNewTeam(
      new TeamInputDto({
        name: typedTeamName,
        color: NEW_ORG_TEAM_COLORS[(teams?.length || 1) % 10]
      }),
      assignedTeams
    );
    setTypedTeamName('');
  }, [typedTeamName, teams, assignedTeams]);

  const changeAssignedTeams = (newTeamsList: TeamDto[]) => {
    setIsAssignedTeamsChanged(true);
    setAssignedTeams(newTeamsList);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    if ((e.key === 'Space' || e.key === 'Enter') && value.trim().length) {
      addNewTeam();
    }
    if (e.key === 'Backspace' && value === '' && assignedTeams.length) {
      changeAssignedTeams(assignedTeams.slice(0, -1));
    }
  };

  return (
    <SelectTeamContainer
      ref={visibilityRef}
      isEditMode={isEditMode}
      onClick={() => {
        setIsEditMode(true);
        const input = inputFocusRef?.current;
        input && (input as HTMLInputElement).focus();
      }}
    >
      {
        !isEditMode ? (
          <SelectTeamBtn title="Add teams">
            <Icon iconName="Add" />
          </SelectTeamBtn>
        ) : (<></>)
      }
      {
        assignedTeams.map((team, index) => (
          <SelectedTeamItem
            key={team.id}
            title={team.name}
            color={NEW_ORG_TEAM_COLORS[index % 10]}
          >
            <div />
            <span title={team.name}>{team.name}</span>
            <Cross
              src={CrossBlack}
              onClick={() => {
                changeAssignedTeams(assignedTeams.filter(
                  (teamItem) => teamItem.id !== team.id
                ));
              }}
            />
          </SelectedTeamItem>
        ))
      }
      {
        isEditMode && (
          <>
            <WidthCalculationBase ref={spanRef}>
              {typedTeamName}
            </WidthCalculationBase>
            <SelectTeamInput
              style={{ width }}
              autoFocus
              placeholder={(() => {
                if (typedTeamName.length || assignedTeams.length) return '';
                if (teamsForSelect.length) return 'Select teams';
                return 'Enter a new team name';
              })()}
              ref={inputFocusRef}
              value={typedTeamName}
              onKeyDown={onKeyDown}
              onChange={(e) => setTypedTeamName(e.target.value)}
              type="text"
              onKeyUp={(e) => e.key === 'Enter' && closePopup(false)}
            />
            <SelectTeamPopupContainer>
              {
                !!typedTeamName.length &&
                !displayTeamsList.some((team) => team.name === typedTeamName) && (
                  <SelectTeamItem onClick={addNewTeam}>
                    <span>+ Add Team</span>
                  </SelectTeamItem>
                )
              }
              {
                displayTeamsList.map((displayTeam, index) => (
                  <SelectTeamItem
                    title={displayTeam.name}
                    key={displayTeam.id}
                    backgroundColor={displayTeam.userOptions?.color ?? NEW_ORG_TEAM_COLORS[index % 10]}
                    onClick={() => changeAssignedTeams(
                      assignedTeams.concat(displayTeam)
                    )}
                  >
                    <div />
                    <span>{displayTeam.name}</span>
                  </SelectTeamItem>
                ))
              }
            </SelectTeamPopupContainer>
          </>
        )
      }
    </SelectTeamContainer>
  );
};

export default SelectTeamsSection;
