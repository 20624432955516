import styled from 'styled-components';
import { GREY_COLOR, LIGHT_GREY_COLOR, WHITE_COLOR } from '../../../consts/theme';

export const AddTeamsBtn = styled.li`
  left: 0;
  font-family: 'TTHoves-DemiBold', serif;
  font-size: 17px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;
  background: transparent;
  color: #0000009c;
`;

export const SelectTeamContainer = styled.ul<{ isEditMode: boolean }>`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //max-width: 400px;
  background-color: ${WHITE_COLOR};

  ${(props) => props.isEditMode
          && `
        border: 1px solid ${LIGHT_GREY_COLOR};
        box-shadow: 0px 8px 20px 4px rgb(79 79 82 / 10%);
        padding: 5px;
        width: 100%;
      `}
  gap: 5px;

  & > li {
    cursor: ${props => !props.isEditMode ? 'pointer' : 'default'};
  }

  & > div:nth-child(1) {
    position: relative;
  }
`;


// export const SelectTeamContainer = styled.div<{ isEditMode: boolean }>`
//   display: flex;
//   align-items: center;
//   position: relative;
//   width: 100%;
//   min-width: 100px;
//   padding: 5px;
//   background-color: ${WHITE_COLOR};
//   ${(props) => !props.isEditMode && 'height: 40px;'}
//   cursor: ${props => !props.isEditMode ? 'pointer' : 'default'};
//
//   & > span {
//     font-family: 'TTHoves-DemiBold', serif;
//     width: 100%;
//     color: ${GREY_COLOR};
//     text-align: left;
//   }
//
//   ul {
//     margin: 0;
//     padding: 0;
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;
//     max-width: 400px;
//     background-color: ${WHITE_COLOR};
//
//     ${(props) => props.isEditMode
//     && `
//         border: 1px solid ${LIGHT_GREY_COLOR};
//         box-shadow: 0px 8px 20px 4px rgb(79 79 82 / 10%);
//         padding: 5px;
//         width: 100%;
//       `}
//     gap: 5px;
//   }
//
//   ul > li {
//     cursor: ${props => !props.isEditMode ? 'pointer' : 'default'};
//   }
//
//   ul > div:nth-child(1) {
//     position: relative;
//   }
// `;

export const WidthCalculationBase = styled.span`
  display: inline-block;
  vertical-align: top;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
`;

export const SelectedTeamItem = styled.li<{ color: string }>`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
  list-style: none;
  padding: 5px 10px 5px 20px;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;

  & > div {
    left: 6px;
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: ${(props) => props.color};
  }

  & > span {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const SelectTeamInput = styled.input`
  font-family: 'TTHoves-DemiBold', serif;
  padding: 0.4rem;
  outline: none;
  border: none;
  max-width: 375px;
  background: transparent;
  font-size: 17px;
  color: black;
  margin: 0;
`;

export const SelectTeamPopupContainer = styled.div`
  position: absolute;
  box-shadow: 0 8px 20px 4px rgb(79 79 82 / 10%);
  display: flex;
  flex-direction: column;
  background-color: ${WHITE_COLOR};
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const SelectTeamItem = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  border-top: 1px solid ${LIGHT_GREY_COLOR};

  & > div {
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor || GREY_COLOR};
  }

  &:hover {
    cursor: pointer;
    background-color: ${LIGHT_GREY_COLOR};
  }

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`;

export const SelectTeamBtn = styled.div`
  position: relative;
  cursor: pointer;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px dashed black;
`;

export const InviteNewTeamContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  justify-content: start;
`;

export const InviteNewTeamMates = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;
