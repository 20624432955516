/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import { SimpleForm } from '../../components/Forms';
import { Container } from '../../components/Container';
import { ConnectionSettingsOutputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { ConnectionStatusSection } from './ConnectionStatusSection';
import { ConnectionHeaderSection } from './ConnectionHeaderSection';
import { Loading } from '../Profile/Loading';
import { AsanaWorkspaceSection } from './AsanaWorkspaceSection';

const FormContainer = styled.section`
  max-width: 450px;
  padding: 15px;
  margin: 0 auto;
`;

export interface IConnectionStatusProps {
  accountId?: number;
  isCreationMode?: boolean;
  editData?: ConnectionSettingsOutputDto;

  serviceProvider: ServiceProvider;
  serviceProviderName?: string;

  onChangeConnectionClick?: () => void;
  onCompletedClick?: () => void;

  verifyErrorMessage?: unknown;
  isVerifiedAccount?: boolean;
  isLoading?: boolean;
  isVerifyingWebroot?: boolean;
  isFullscreenMode?: boolean;
  backToSection: string;
}

export const ConnectionStatus: React.FC<IConnectionStatusProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFinishBtnDisabled, setIsFinishBtnDisabled] = useState(false);

  useEffect(() => {
    if (!props.isLoading) {
      setIsLoading(false);
      setIsFinishBtnDisabled(props.serviceProvider === ServiceProvider.Asana);
    }
  }, [props.isLoading]);

  if (
    isLoading ||
    props.isLoading ||
    props.isVerifyingWebroot ||
    isNil(props.isVerifiedAccount)
  )
    return <Loading />;

  return (
    <Container>
      <FormContainer className="form-setupconnection">
        <SimpleForm>
          <ConnectionHeaderSection
            userName={props.editData?.userName}
            webRoot={props.editData?.webRoot}
            serviceProvider={props.serviceProvider}
          />
          {props.serviceProvider === ServiceProvider.Asana && (
            <>
              <AsanaWorkspaceSection
                accountId={props.accountId}
                onWorkspaceSelected={setIsFinishBtnDisabled}
              />
              <br />
              <br />
            </>
          )}
          <ConnectionStatusSection
            {...props}
            disableFinishButton={isFinishBtnDisabled}
            userName={props.editData?.userName}
          />
        </SimpleForm>
      </FormContainer>
    </Container>
  );
};
