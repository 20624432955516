import { IButtonStyles, IPersonaStyles } from '@fluentui/react';
import { CSSProperties } from 'react';
import { COLORS } from './../style';

export const workspaceContainerStyle: CSSProperties = {
  width: '96px',
  height: '86px',
};

export const circularShadowStyle = (isHovered: boolean): CSSProperties => ({
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  boxShadow: isHovered
    ? '-3px 3px 10px rgba(0, 86, 179,0.6)'
    : '-3px 3px 12px rgba(144,144,144,0.8)',
  transition: 'box-shadow 0.1s ease-in-out',
});

export const createWorkspaceButtonStyle: IButtonStyles = {
  root: {
    backgroundColor: 'white',
    color: COLORS.averageGray,
    height: '48px',
    width: '48px',
    minWidth: '10px',
    fontSize: '36px',
    borderRadius: '50%',
    border: `1.5px solid ${COLORS.averageGray}`,

    textAlign: 'center',
    paddingBottom: '7px',
  },
  rootHovered: {
    backgroundColor: 'white',
    color: COLORS.averageGray,
  },
  rootPressed: {
    backgroundColor: 'white',
    color: COLORS.averageGray,
  },
};

export const workspaceButtonStyle: Partial<IPersonaStyles> = {
  root: {
    width: '48px',
    cursor: 'pointer',
    '& span': {
      fontSize: '20px', // Adjust the font size as needed
    },
  },
  primaryText: {
    display: 'none',
  },
};

export const workspaceLabelStyle: CSSProperties = {
  width: '96px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  fontSize: '12px',
  marginBottom: '12px',
};

export const workspacesContainerStyle: CSSProperties = {
  paddingTop: '100px',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap', // Add this line
};
