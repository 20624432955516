import React from 'react';
import { ConnectionHeaderSection } from './ConnectionHeaderSection';
import { ConnectionSettingsOutputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { AuthorizeOAuthSection } from './AuthorizeOAuthSection';
import { SimpleForm } from '../../components/Forms';

interface IAsanaAuthorizeFormProps {
  serviceProvider: ServiceProvider;
  errorPasswordCredentials?: boolean;
  editData?: ConnectionSettingsOutputDto;

  errorOAuth?: unknown;
  onChange?: (arg: {}) => void;
  connectOAuth?: () => void;
}

export const AsanaAuthorizeForm: React.FC<IAsanaAuthorizeFormProps> = (
  props
) => {
  return (
    <SimpleForm>
      <ConnectionHeaderSection
        {...props}
        userName={props.editData?.userName}
        webRoot={props.editData?.webRoot}
      />
      <>
        <AuthorizeOAuthSection {...props} connect={props.connectOAuth} />
        <br />
      </>
    </SimpleForm>
  );
};
