import * as React from 'react';
import { Stack, IStackTokens, Persona } from '@fluentui/react';
import {
  circularShadowStyle,
  workspaceButtonStyle,
  workspaceLabelStyle,
} from './style';
import DefaultTooltip from '../DefaultTooltip';

const stackTokens: Partial<IStackTokens> = { childrenGap: 8 };

interface WorkspaceButtonProps {
  onButtonClick: () => void;
  label: string;
}

export const WorkspaceButton: React.FC<WorkspaceButtonProps> = ({
  onButtonClick,
  label,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Stack horizontalAlign="center" verticalAlign="center" tokens={stackTokens}>
      <div
        style={circularShadowStyle(isHovered)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <DefaultTooltip text={label}>
          <Persona
            text={label}
            onClick={onButtonClick}
            styles={workspaceButtonStyle}
          />
        </DefaultTooltip>
      </div>
      <span style={workspaceLabelStyle}>{label}</span>
    </Stack>
  );
};
