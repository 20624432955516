export const COLORS = {
  globalGrayColor: '#888',
  lightGray: '#f0f0f0',
  lightestGray: '#f9f9f9',
  averageGray: '#B5B5B5',
};

export const SIZES = {
  searchBarHeight: '32px',
  defaultFontSize: '14px'
}