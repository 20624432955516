import * as React from 'react';
import { Stack, IStackTokens, DefaultButton } from '@fluentui/react';
import {
  circularShadowStyle,
  createWorkspaceButtonStyle,
  workspaceContainerStyle,
} from './style';
import DefaultTooltip from '../DefaultTooltip';

const stackTokens: Partial<IStackTokens> = { childrenGap: 8 };

interface CreateWorkspaceButtonProps {
  onButtonClick: () => void;
}

export const CreateWorkspaceButton: React.FC<CreateWorkspaceButtonProps> = ({
  onButtonClick,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Stack horizontalAlign="center" verticalAlign="start" tokens={stackTokens}>
      <div style={workspaceContainerStyle}>
        <Stack horizontalAlign="center" tokens={stackTokens}>
          <DefaultTooltip text="New Workspace">
            <div
              style={circularShadowStyle(isHovered)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <DefaultButton
                styles={createWorkspaceButtonStyle}
                text="+"
                onClick={onButtonClick}
              />
            </div>
          </DefaultTooltip>
        </Stack>
      </div>
    </Stack>
  );
};
