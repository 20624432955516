import React, { useEffect, useMemo } from 'react';
import { ShowMore } from './ShowMore';
import ResultRow from './ResultRow';
import { noResultsStyle } from './style';
import { ProcessSearchData } from './SearchResultsManager';
import {
  fetchSearchData,
  resetSearchData,
  selectSearchData,
} from '../../../../utils/reducers/searchData';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountsList } from '../../../../utils/reducers/accounts';
import { ItemType } from '../../../../gen/src/ahauOpenAPI';

interface ResultsListProps {
  searchQuery: string | undefined;
  filter: ItemType;
}

const ResultsList: React.FC<ResultsListProps> = ({ searchQuery, filter }) => {
  const [showMore, setShowMore] = React.useState(false);
  const dispatch = useDispatch();

  const { connectedAccounts } = useSelector(selectAccountsList);
  const { isLoading } = useSelector(selectSearchData);
  useEffect(() => {
    dispatch(
      fetchSearchData(
        searchQuery || '',
        connectedAccounts.map((account) => account.id),
        filter
      )
    );
    return () => {
      setShowMore(false);
      dispatch(resetSearchData());
    };
  }, []);

  const { filteredData, leftOverData } = ProcessSearchData(
    searchQuery?.toLowerCase(),
    filter
  );

  return (
    <>
      {filteredData.map((result, index) => (
        <ResultRow key={index}>{result}</ResultRow>
      ))}
      {showMore &&
        leftOverData.map((result, index) => (
          <ResultRow key={index}>{result}</ResultRow>
        ))}

      {isLoading ? (
        <div style={noResultsStyle}>Loading...</div>
      ) : filteredData.length === 0 ? (
        <div style={noResultsStyle}>No Results were found...</div>
      ) : (
        !showMore &&
        leftOverData.length > 0 && (
          <ShowMore
            onClick={() => {
              setShowMore(true);
            }}
          />
        )
      )}
    </>
  );
};

export default ResultsList;
