import React, { memo, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Panel } from './panel';


const Workspaces = () => {

    return (<Panel>
       <>as</>
    </Panel>);
};

export default memo(Workspaces);