/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { connectionHelper, getAuthorizeUrl, getConnectionType } from '../../helpers/connectionHelper';
import {
  AccountInfoDto,
  AuthMethods,
  ConnectionSettingsInputDto,
  ConnectionSettingsOutputDto,
  ConnectorEditDataDto,
  CreateAccountInputDto,
  ServiceProvider,
  VerifyAccountConnectionOutputDto,
  VerifyUrlInputDto,
  VerifyUrlOutputDto,
  VerifyUrlResultType
} from '../../gen/src/ahauOpenAPI';
import { AppThunk, RootState } from '../store';
import { setError } from '../appReducer';
import { ERROR_MESSAGE } from '../../consts';
import { getApiClient } from '../api';
import mixpanelService, { EventName } from '../../services/MixpanelService';

export interface IConnectionCredentials {
  userName?: string;
  password?: string;
  apiKey?: string;
}

export interface IFetchConnectionParams {
  accountId?: number;
  serviceProvider?: ServiceProvider;
  defaultWebRoot?: string;
}

export interface ICreateAccountParams {
  webRoot?: string;

  withOauth?: boolean;
}

export interface ConnectionState {
  // New connection data
  connectorCreateData?: ConnectorEditDataDto;

  // Existing connection
  connectionEditData?: ConnectionSettingsOutputDto;
  accountInfo?: AccountInfoDto;

  connectionSettings?: ConnectionSettingsInputDto;

  isVerifyingAccount: boolean;
  isVerifiedAccount?: boolean;

  isVerifyingWebroot: boolean;
  isVerifiedWebroot: boolean;

  verifyUrlData?: VerifyUrlOutputDto;
  // ILastErrorItem
  verifyAccountErrorMessage?: unknown;
  verifyUrlErrorMessage?: unknown;

  isJustCreatedAccount?: boolean;
  isServiceAuthDialogOpened?: boolean;
  isLoading?: boolean;
}

const initialState: ConnectionState = {
  connectorCreateData: ConnectorEditDataDto.fromJS({
    serviceProvider: '',
    serviceProviderName: '',
    isPasswordSupported: false,
    isTokenSupported: false,
    defaultWebRoot: '',
    defaultSettings: undefined
  }),

  isVerifyingAccount: false,
  isVerifiedAccount: undefined,

  isVerifyingWebroot: false,
  isVerifiedWebroot: false,

  isJustCreatedAccount: false,
  isServiceAuthDialogOpened: false,
  isLoading: false
};

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setInitialConnection: (state) => {
      // eslint-disable-next-line no-param-reassign
      state = Object.assign(state, initialState);
    },
    setConnector: (state, action: PayloadAction<ConnectorEditDataDto>) => {
      state.connectorCreateData = action.payload;
      state.connectionEditData = undefined;
      state.accountInfo = undefined;
      state.connectionSettings = undefined;

      state.verifyUrlData = undefined;
      state.verifyAccountErrorMessage = undefined;
      state.verifyUrlErrorMessage = undefined;

      state.isVerifiedAccount = false;
      state.isVerifiedWebroot = false;
    },
    setConnectionEditData: (
      state,
      action: PayloadAction<ConnectionSettingsOutputDto>
    ) => {
      state.connectionEditData = action.payload;
    },
    modifyConnectionEditData: (
      state,
      action: PayloadAction<Partial<ConnectionSettingsOutputDto>>
    ) => {
      const destinationObj = {};
      const connectionEditDataCopy = Object.assign(
        destinationObj,
        state.connectionEditData
      );
      state.connectionEditData = Object.assign(
        connectionEditDataCopy,
        action.payload
      );
    },
    clearConnectionEditData: (state) => {
      state.connectionEditData = undefined;
      state.isJustCreatedAccount = false;
    },
    setAccountInfo: (state, action: PayloadAction<AccountInfoDto>) => {
      state.accountInfo = action.payload;
    },
    modifyAccountInfo: (
      state,
      action: PayloadAction<Partial<AccountInfoDto>>
    ) => {
      Object.assign(state.accountInfo!, action.payload!);
    },
    setConnectionSettings: (
      state,
      action: PayloadAction<ConnectionSettingsInputDto>
    ) => {
      state.connectionSettings = action.payload;
    },
    setIsJustCreatedAccount: (state, action: PayloadAction<boolean>) => {
      state.isJustCreatedAccount = action.payload;
    },
    toggleServiceAuthDialog: (state) => {
      state.isServiceAuthDialogOpened = !state.isServiceAuthDialogOpened;
    },
    setVerifyResult: (
      state,
      action: PayloadAction<VerifyAccountConnectionOutputDto>
    ) => {
      const verifyResponse = action.payload;
      // wrapError
      state.verifyAccountErrorMessage = verifyResponse.isSuccessful
        ? undefined
        : undefined;
    },
    setIsVerifyingAccount: (state, action: PayloadAction<boolean>) => {
      state.isVerifyingAccount = action.payload;
    },
    setIsVerifiedAccount: (state, action: PayloadAction<boolean>) => {
      state.isVerifiedAccount = action.payload;
    },
    setVerifyUrlResult: (state, action: PayloadAction<VerifyUrlOutputDto>) => {
      state.verifyUrlData = action.payload;
    },
    setVerifyAccountErrorMessage: (
      state,
      action: PayloadAction<| { accountId: number; isSuccessful: boolean }
      | Error
      | undefined
      | unknown>
    ) => {
      if (action.payload !== undefined) {
        // wrapError
        state.verifyAccountErrorMessage = action.payload;
      } else {
        state.verifyAccountErrorMessage = undefined;
      }
    },
    setWebRootIsVerifying: (state, action: PayloadAction<boolean>) => {
      state.isVerifyingWebroot = action.payload;
    },
    setWebRootIsVerified: (state, action: PayloadAction<boolean>) => {
      state.isVerifiedWebroot = action.payload;
    },
    setVerifyUrlErrorMessage: (
      state,
      action: PayloadAction<VerifyAccountConnectionOutputDto | undefined>
    ) => {
      if (action.payload !== undefined) {
        // wrapError
        state.verifyUrlErrorMessage = undefined;
      } else {
        state.verifyUrlErrorMessage = undefined;
      }
    }
  }
});

export const {
  toggleServiceAuthDialog,
  clearConnectionEditData,
  modifyConnectionEditData,
  setIsVerifiedAccount
} = connectionSlice.actions;

const { startLoading, stopLoading } = connectionSlice.actions;

/**
 * Loads new or existing connection depending of @param accountId existence
 * @param accountId
 * @param serviceProvider
 */
export const fetchConnection = ({
  accountId,
  serviceProvider,
  defaultWebRoot = ''
}: IFetchConnectionParams): AppThunk => (dispatch) => {
  if (!accountId && serviceProvider) {
    dispatch(loadNewConnection(serviceProvider, defaultWebRoot));
  } else if (accountId) {
    dispatch(loadExistingConnection(accountId));
  }
};

/**
 * Helper for @function fetchConnection
 * @param serviceProvider
 */
const loadNewConnection = (
  serviceProvider: ServiceProvider,
  defaultWebRoot?: string
): AppThunk => async (dispatch, getState) => {
  const { isJustCreatedAccount } = getState().connection;
  const { connectors } = getState().accounts.list;

  const {
    setConnector,
    setConnectionSettings,
    setVerifyAccountErrorMessage
  } = connectionSlice.actions;

  try {
    // Get connector for usage based on service provider argument
    let connector = connectors.find(
      (x) => x.serviceProvider === serviceProvider
    );

    // If there's no connector, default connector should be used
    connector = connector || connectors[0];

    if (serviceProvider !== ServiceProvider.Unknown) {
      if (!isJustCreatedAccount) {
        dispatch(
          setConnector({
            ...connector,
            ...(defaultWebRoot ? { defaultWebRoot } : {})
          } as ConnectorEditDataDto)
        );
      }
      dispatch(
        setConnectionSettings(
          ConnectionSettingsInputDto.fromJS({
            authMethod: connector.defaultSettings?.authMethod
          })
        )
      );

      dispatch(setVerifyAccountErrorMessage(undefined));
    }
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

/**
 * Helper for @function fetchConnection
 * @param accountId
 */
const loadExistingConnection = (accountId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  const { isJustCreatedAccount } = getState().connection;

  const { userEmail } = getState().appReducer;
  const {
    setInitialConnection,
    setConnectionEditData,
    setAccountInfo,
    setConnectionSettings
  } = connectionSlice.actions;

  const id = Number(accountId);

  try {
    dispatch(setInitialConnection());
    dispatch(startLoading());

    const data = await api.accountsGetConnection(id);

    dispatch(
      setConnectionEditData(
        new ConnectionSettingsOutputDto({
          userName: data.userName ?? userEmail,
          webRoot: data.webRoot,
          authMethod: data.authMethod,
          ignoreSslErrors: data.ignoreSslErrors,
          passwordLength: data.passwordLength,
          apiKeyLength: data.apiKeyLength
        })
      )
    );

    dispatch(
      setConnectionSettings(
        new ConnectionSettingsInputDto({
          userName: data.userName ?? userEmail,
          authMethod: data.authMethod ? data.authMethod : AuthMethods.Password,
          ignoreSslErrors: data.ignoreSslErrors
        })
      )
    );

    if (data.account) {
      dispatch(setAccountInfo(data.account));
    }

    // * Need to confirm if this should be called here
    // ? And how it should be fired (isJustCreatedAccount) ?

    if (!isJustCreatedAccount) {
      dispatch(verifyAccountConnectionAsync(accountId));
    }
    dispatch(verifyUrlAsync(accountId));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

const createNewAccount = ({
  webRoot,
  withOauth
}: ICreateAccountParams): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  const { connectorCreateData, connectionSettings } = getState().connection;

  const { setAccountInfo } = connectionSlice.actions;

  const serviceProvider = connectorCreateData?.serviceProvider
    ? connectorCreateData?.serviceProvider
    : ServiceProvider.EasyProjects;
  const userName = connectionSettings?.userName;
  const authMethod = connectionSettings?.authMethod;
  try {
    dispatch(startLoading());

    const account = new CreateAccountInputDto({
      webRoot: webRoot || '',
      name: userName,
      serviceProvider,
      isDefault: false
    });

    const createdAccount = await api.accountsCreate(account);

    dispatch(modifyConnectionEditData({ authMethod }));
    dispatch(setAccountInfo(createdAccount));

    dispatch(fetchConnection({ accountId: createdAccount.id }));

    if (withOauth) {
      window.location.assign(
        getAuthorizeUrl(
          createdAccount.id || 0,
          getConnectionType(window.location.search)
        )
      );
    }
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const saveAccountAsync = (args: {
  accountId: number;
  connectionSettings: ConnectionSettingsInputDto;
}): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  const { setIsJustCreatedAccount } = connectionSlice.actions;
  try {
    await api.accountsUpdateConnection(args.accountId, args.connectionSettings);

    dispatch(setIsJustCreatedAccount(false));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const connectCredentials = (
  accountId: number,
  credentials: IConnectionCredentials,
  callback?: (accountId: number) => void
): AppThunk => async (dispatch, getState) => {
  const {
    setVerifyAccountErrorMessage,
    setIsVerifyingAccount,
    setConnectionSettings
  } = connectionSlice.actions;

  const connectionSettings = new ConnectionSettingsInputDto({
    authMethod: credentials.apiKey
      ? AuthMethods.ApiKey
      : AuthMethods.Password,
    userName: credentials.userName,
    apiKey: credentials.apiKey,
    password: credentials.password,
    ignoreSslErrors: false,
  });

  dispatch(setConnectionSettings(connectionSettings));

  try {
    dispatch(setIsVerifyingAccount(true));

    if (!connectionSettings) {
      return;
    }

    // Todo: check this validation
    if (!connectionHelper.validateFields(connectionSettings)) {
      dispatch(
        setVerifyAccountErrorMessage({
          accountId,
          isSuccessful: false,
          message: 'Incorrect data. Please, fill all required fields.'
        })
      );
      return;
    }

    dispatch(verifyAccountConnectionAsync(accountId, callback));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const connectOAuth = (_accountId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const { setIsJustCreatedAccount } = connectionSlice.actions;
  let { connectionEditData: connectionSettings } = getState().connection;

  try {
    dispatch(modifyConnectionEditData({ authMethod: AuthMethods.OAuthToken }));
    connectionSettings = getState().connection.connectionEditData;

    if (!connectionSettings) {
      return;
    }
    // const input = connectionSettings as ConnectionSettingsInputDto;

    // dispatch(
    //   saveAccountAsync({
    //     accountId,
    //     connectionSettings: input,
    //   })
    // );
    dispatch(setIsJustCreatedAccount(false));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  } finally {
    dispatch(toggleServiceAuthDialog());
  }
};

export const verifyUrlAsync = (
  accountId?: number,
  inputWebRoot?: string
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  const { connection } = getState();

  const {
    connectorCreateData: {
      serviceProvider: creationServiceProvider = ServiceProvider.EasyProjects
    } = {},
    connectionEditData: { webRoot: loadedWebRoot } = {},
    accountInfo: {
      serviceProvider: loadedServiceProvider = ServiceProvider.EasyProjects
    } = {},
    isJustCreatedAccount
  } = connection;

  const serviceProvider = accountId
    ? loadedServiceProvider
    : creationServiceProvider;
  const webRoot = inputWebRoot || loadedWebRoot;

  const {
    setVerifyUrlErrorMessage,
    setWebRootIsVerifying,
    setVerifyUrlResult,
    setWebRootIsVerified
  } = connectionSlice.actions;

  if (!webRoot) {
    return;
  }

  dispatch(setWebRootIsVerified(false));

  if (!isJustCreatedAccount) {
    dispatch(setWebRootIsVerifying(true));
  }

  try {
    dispatch(setVerifyUrlErrorMessage(undefined));

    const data = await api.accountsVerifyUrl(
      new VerifyUrlInputDto({ serviceProvider, webRoot })
    );

    if (
      data.resultType &&
      (data.resultType.toString() === 'Verified' ||
        data.resultType === VerifyUrlResultType.Valid)
    ) {
      dispatch(setWebRootIsVerified(true));
      dispatch(setVerifyUrlResult(data));

      dispatch(setWebRootIsVerifying(false));
      dispatch(setWebRootIsVerified(data.isSuccessful));
    } else {
      dispatch(
        setVerifyUrlErrorMessage(
          VerifyAccountConnectionOutputDto.fromJS({
            accountId: accountId!,
            isSuccessful: false,
            message: 'Incorrect account URL'
          })
        )
      );
      dispatch(setWebRootIsVerified(false));
    }
  } catch (error) {
    dispatch(setWebRootIsVerified(false));
    // Todo: define display for catched error
    if (accountId) {
      dispatch(
        setVerifyUrlErrorMessage(
          VerifyAccountConnectionOutputDto.fromJS({
            accountId,
            message: (error as unknown) || '',
            isSuccessful: false
          })
        )
      );
    }
  } finally {
    dispatch(setWebRootIsVerifying(false));
  }
};

/**
 * try connection validate connection settings
 * @param accountId
 * @param callback
 */
export const verifyAccountConnectionAsync = (
  accountId: number,
  callback?: (accountId: number) => void
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  const {
    connectionSettings,
    connectionSettings: { authMethod = AuthMethods.Password } = {}
  } = getState().connection;
  const {
    setIsVerifyingAccount,
    setVerifyResult,
    setVerifyAccountErrorMessage
  } = connectionSlice.actions;

  if (!connectionSettings) {
    return;
  }

  const id = Number(accountId);

  let verifyAccountErrorMessage = 'Invalid token';

  if (authMethod === AuthMethods.Password) {
    verifyAccountErrorMessage = 'Invalid user name or password';
  }
  if (authMethod === AuthMethods.ApiKey) {
    verifyAccountErrorMessage = 'Invalid API Key';
  }

  try {
    dispatch(setVerifyAccountErrorMessage(undefined));
    const result = await api.accountsVerifyConnection(
      id,
      new ConnectionSettingsInputDto(connectionSettings)
    );

    mixpanelService.sendEvent(EventName.ACCOUNT, 'Connect / Sync', [result.result?.serviceProvider, result.result?.resultType]);

    dispatch(setVerifyResult(result));
    const isVerifiedAccount = result.isSuccessful;

    if (!isVerifiedAccount) {
      dispatch(
        setVerifyAccountErrorMessage({
          accountId: id,
          isSuccessful: result.isSuccessful,
          result: result.result,
          message: verifyAccountErrorMessage
        })
      );
    }

    if (isVerifiedAccount && connectionSettings) {
      dispatch(
        modifyConnectionEditData({
          userName: connectionSettings.userName,
          authMethod: connectionSettings.authMethod
        })
      );
      await dispatch(saveAccountAsync({ accountId: id, connectionSettings }));
    }

    dispatch(setIsVerifiedAccount(isVerifiedAccount));
    dispatch(setIsVerifyingAccount(false));

    callback && callback(accountId);
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const startSync = (accountId: number): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(startLoading());
    const { accessToken } = getState().appReducer;
    const api = getApiClient(accessToken);
    await api.syncDoSync(accountId);
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const onWebRootContinueCreateClick = ({
  webRoot,
  withOauth
}: ICreateAccountParams): AppThunk => async (dispatch) => {
  const { setIsJustCreatedAccount } = connectionSlice.actions;

  dispatch(createNewAccount({ webRoot, withOauth }));
  dispatch(setIsJustCreatedAccount(true));
};

export const selectConnection = (state: RootState) => state.connection;
export const selectConnectorCreateData = (state: RootState) =>
  state.connection.connectorCreateData!;

export default connectionSlice.reducer;
