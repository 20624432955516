/* eslint-disable max-len */
/* eslint-disable no-debugger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { SwipeableHandlers, useSwipeable } from 'react-swipeable';
import { ConfigurationOptions, SwipeableCallbacks } from 'react-swipeable/dist/types';
import { Arrow, Container, ItemsContainer, PaginationContainer, Point, PointsList } from './style';
import { WidthContext } from '../../App';

import arrowLeft from '../../images/arrowLeftGray.svg';
import arrowLeftBlue from '../../images/arrowLeftBlue.svg';
import arrowRight from '../../images/arrowRight.svg';
import arrowRightBlue from '../../images/arrowRightBlue.svg';

interface GalleryProps {
  items: any[];
  component: any;
  size: number;
  isProfilePage?: boolean;
}

const Gallery: React.FC<GalleryProps> = (props) => {
  const { size, items, isProfilePage } = props;
  const width = useContext(WidthContext);
  const isMobile = width <= 536;
  const Item = props.component;

  const [startedIndex, setStartedIndex] = useState(0);
  const [subArray, setSubArray] = useState<any[] | undefined>(undefined);

  const isFirstItemShowed = subArray?.some((item) => item.id === 1);
  const isLastItemShowed = subArray?.some((item, index, array) =>
    items.length > 2 ? item.id === 3 : item.id === 2
  );

  const handlersConfig: Partial<SwipeableCallbacks & ConfigurationOptions> = {
    delta: 10, // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false, // call e.preventDefault
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    onSwipedRight: () => (size < 3 ? prev() : undefined),
    onSwipedLeft: () => (size < 3 ? next() : undefined)
  };

  const handlers: SwipeableHandlers = useSwipeable({
    ...handlersConfig
  });

  useEffect(() => {
    let newArray = [];
    const step = size;

    if (startedIndex + step - 1 > items.length - 1) {
      const firstElement = items.length > 2 ? items[startedIndex] : items[0];
      const secondElement = items.length > 2 ? items[0] : items[1];
      newArray = [firstElement, secondElement];
    } else {
      newArray = items.slice(startedIndex, startedIndex + step);
    }

    setSubArray(newArray);
  }, [startedIndex, size, items]);

  const prev = () => {
    if (startedIndex === 0) {
      if (size === 2 && !isLastItemShowed) {
        setStartedIndex(items.length - 2);
        return;
      }
      setStartedIndex(items.length - 1);
    } else {
      setStartedIndex(startedIndex - 1);
    }
  };

  const next = () => {
    if (startedIndex === items.length - 1 || (size === 2 && isLastItemShowed)) {
      setStartedIndex(0);
    } else {
      setStartedIndex(startedIndex + 1);
    }
  };

  // ? refactor work / other way ?
  const feedbackArrowLeft =
    !isFirstItemShowed || isLastItemShowed ? arrowLeftBlue : arrowLeft;
  const feedbackArrowRight = !isLastItemShowed ? arrowRightBlue : arrowRight;

  return (
    <>
      {!isMobile && (
        <Container
          {...handlers}
          size={size}
          marginTop={items.length > 2 ? '20px' : '30px'}
        >
          <Arrow size={size} onClick={() => prev()}>
            <img
              src={feedbackArrowLeft}
              title="Return to previous testimonial"
              alt="Return to previous testimonial arrow"
            />
          </Arrow>
          <div>
            <ItemsContainer size={size} isProfilePage={isProfilePage}>
              {subArray &&
              subArray.map((item, index) => (
                <Item
                  key={item.id}
                  item={item}
                  index={index}
                  siblingsNumber={items.length}
                />
              ))}
            </ItemsContainer>
            <PointsList size={size}>
              {items.map((user, index) => (
                <Point
                  onClick={() => {
                    if (startedIndex !== index) {
                      setStartedIndex(index);
                    }
                  }}
                  isActive={startedIndex === index}
                  key={user.id}
                />
              ))}
            </PointsList>
          </div>
          <Arrow size={size} onClick={() => next()}>
            <img
              src={feedbackArrowRight}
              title="Go to next testimonial"
              alt="Go to next testimonial arrow"
            />
          </Arrow>
        </Container>
      )}

      {isMobile && (
        <Container {...handlers} size={size}>
          <PaginationContainer>
            <Arrow
              size={size}
              onClick={() => prev()}
              style={{ justifyContent: 'flex-start' }}
            >
              <img
                src={feedbackArrowLeft}
                title="Return to previous testimonial"
                alt="Return to previous testimonial arrow"
              />
            </Arrow>
            <PointsList size={size}>
              {items.map((user, index) => (
                <Point
                  onClick={() => {
                    if (startedIndex !== index) {
                      setStartedIndex(index);
                    }
                  }}
                  isActive={startedIndex === index}
                  key={user.id}
                />
              ))}
            </PointsList>
            <Arrow
              size={size}
              onClick={() => next()}
              style={{ justifyContent: 'flex-end' }}
            >
              <img
                src={feedbackArrowRight}
                title="Go to next testimonial"
                alt="Go to next testimonial arrow"
              />
            </Arrow>
          </PaginationContainer>
          <ItemsContainer size={size} isProfilePage={isProfilePage}>
            {subArray &&
            subArray.map((item, index) => (
              <Item
                key={item.id}
                item={item}
                index={index}
                siblingsNumber={items.length}
              />
            ))}
          </ItemsContainer>
        </Container>
      )}
    </>
  );
};

export default Gallery;
