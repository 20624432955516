import styled from 'styled-components';

const Input = styled.input<{
  type: string;
  isAboutUsLayout?: boolean;
  isHeaderInput?: boolean;
}>`
  ${(props) =>
    props.type === 'text'
      ? ` 
    height: 56px;
    width: 440px;
    box-shadow: rgba(70,70,80,0.1);
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
    border-radius: 10px;
    border: none;
    padding-right: 156px;
    padding: 0 20px;
    color: #464650;
    padding-right: 164px;
    ::placeholder {
      color: #9b9ba1;
    }
    :focus {
        outline: none;
    }
    @media (max-width: 550px) {
      width: 100%;
      padding-right: 60px;
    }
    @media only screen 
        and (-webkit-device-pixel-ratio: 3) { 
          -webkit-appearance: none;
          box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
    }
    @media only screen
        and (-webkit-device-pixel-ratio: 2) {
          -webkit-appearance: none;
          box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
    }
`
      : `
    background-color: #3A86FF;
    z-index: 2;
    width: 156px;
    height: 48px;
    font-size: 17px;
    margin-left: -156px;

    color: #FFFFFF;
    font-family: 'TTHoves-DemiBold';
    cursor: pointer;
    border-radius: 10px;
    border: none;
    :focus {
        outline: none;
    }
    @media (max-width: 550px) {
      ${
  !props.isAboutUsLayout &&
      `
        width: 48px;
        margin-left: -48px;
        position: absolute;
        right: 5px;
      `
}

      @media not all and (min-resolution:.001dpcm) { @media {
        bottom: 4px;
      }}
    }
`}

  @media (max-width: 550px) {
    margin-bottom: 30px;
  }

  @media (max-width: 280px) {
    padding: 0 6px;
  }
`;

export const AboutUsInput = styled(Input)`
  margin-left: unset;
  margin-top: 25px;

  @media (max-width: 890px) {
    margin-top: ${(props) => (!props.isHeaderInput ? '7px' : '')};
  }
`;

export default Input;
