import * as React from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { IconButton } from '@fluentui/react';
import { searchButtonStyle } from './style';

const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };
interface SearchButtonProps {
  onButtonClick: () => void; // New prop for the button click callback
}

export const SearchButton: React.FC<SearchButtonProps> = ({ onButtonClick }) => {
  return (
    <Stack tokens={stackTokens}>
      <IconButton
        iconProps={{ iconName: 'Search' }}
        title="Search"
        onClick={onButtonClick} // Use the provided callback here
        styles={searchButtonStyle}
      />
    </Stack>
  );
};
