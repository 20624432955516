/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AssignTeamMateRow,
  ImageBtn,
  InfoSection,
  LoadingContainer,
  RowWrapper,
  TeamMateActions,
  TeamMateInfoContainer
} from '../style';
import SelectTeamsSection from './SelectTeamsSection';
import {
  IInviteInputDto,
  IMemberDto,
  InviteInputDto,
  MemberDto,
  MembershipsRoles,
  OrganizationInvite,
  TeamDto,
  TeamInputDto
} from '../../../gen/src/ahauOpenAPI';
import deleteImg from '../../../images/Delete.svg';
import { LoadingButton } from '../../Profile/Loading';
import {
  fetchAddOrganizationTeams,
  fetchDeleteInvite,
  fetchDeleteMember,
  fetchUpdateInvite,
  fetchUpdateMember,
  selectOrganizations
} from '../../../utils/reducers/organizations';
import TeamMateAvatar from './TeamMateAvatar';
import mixpanelService, { EventName } from '../../../services/MixpanelService';

export interface TeamMatesOrganizationRowProps {
  orgId: number;
  member: OrganizationInvite | MemberDto;
  color: string;
  memberStatus?: string;
}

const TeamMatesOrganizationRow: React.FC<TeamMatesOrganizationRowProps> = (
  props
) => {
  const dispatch = useDispatch();
  const { organization: { teams } } = useSelector(selectOrganizations);

  const { color, member, memberStatus, orgId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addNewTeam = useCallback((newTeamInput: TeamInputDto, assignedTeams: TeamDto[]) => {
    setIsLoading(true);
    dispatch(
      fetchAddOrganizationTeams(member.organizationId, newTeamInput, (team: TeamDto) => {
        updateTeamMate(member, [
          ...member.teams ?? [],
          ...assignedTeams,
          team
        ]);
      })
    );
  }, [member, teams]);

  const updateTeamMate = (
    invitedMember: OrganizationInvite | MemberDto,
    updTeams?: TeamDto[],
    role?: MembershipsRoles
  ) => {
    setIsLoading(true);
    const invitedMemberData = {
      ...invitedMember,
      organizationId: orgId,
      role: role ?? invitedMember.role,
      teams: updTeams ?? invitedMember.teams
    };

    if (invitedMember instanceof OrganizationInvite) {
      dispatch(
        fetchUpdateInvite(
          orgId,
          invitedMember.id,
          new InviteInputDto(invitedMemberData as IInviteInputDto)
        )
      );
    } else {
      dispatch(
        fetchUpdateMember(
          orgId,
          new MemberDto(invitedMemberData as IMemberDto)
        )
      );
    }
  };

  const deleteTeamMate = useCallback(() => {
    mixpanelService.sendEvent(EventName.MEMBER, 'Delete', [member instanceof OrganizationInvite]);
    setIsLoading(true);
    if (member instanceof OrganizationInvite) {
      dispatch(fetchDeleteInvite(member));
    } else {
      dispatch(fetchDeleteMember(member));
    }
  }, [member]);

  useEffect(() => {
    isLoading && setIsLoading(false);
  }, [member]);

  return (
    <RowWrapper>
      <AssignTeamMateRow isBlocked={isLoading}>
        <InfoSection>
          <TeamMateAvatar member={member} color={color} />
          <TeamMateInfoContainer>
            <span title={member.email}>{member.email}</span>
            <span>{memberStatus}</span>
          </TeamMateInfoContainer>
        </InfoSection>
        <SelectTeamsSection
          addNewTeam={addNewTeam}
          chooseTeams={(chooseTeams: TeamDto[]) => {
            mixpanelService.sendEvent(EventName.MEMBER, 'Update Teams', [member instanceof OrganizationInvite]);
            setIsLoading(true);
            updateTeamMate(member, chooseTeams);
          }}
          invitedMemberTeams={member.teams}
        />
        <TeamMateActions>
          <Dropdown
            placeholder="Role"
            defaultSelectedKey={member.role}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(event, item) => {
              if (!item) return;
              mixpanelService.sendEvent(EventName.MEMBER, 'Update Role', [member instanceof OrganizationInvite]);
              setIsLoading(true);
              updateTeamMate(member, undefined, item.key as MembershipsRoles);
            }}
            options={[
              { key: MembershipsRoles.Manager, text: 'Manager' },
              { key: MembershipsRoles.Member, text: 'Member' },
            ]}
            styles={{ title: { border: 'none' }, dropdown: { width: 100 } }}
          />
          <ImageBtn
            src={deleteImg}
            onClick={() => {
              setIsLoading(true);
              deleteTeamMate();
            }}
            title="Delete team mate"
          />
        </TeamMateActions>
      </AssignTeamMateRow>
      {
        isLoading && (
          <LoadingContainer>
            <LoadingButton extraStyles={{
              border: 'none',
              position: 'relative',
            }}
            />
          </LoadingContainer>
        )
      }
    </RowWrapper>
  );
};

export default TeamMatesOrganizationRow;
