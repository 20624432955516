import React from 'react';
import { ReactComponent as ArrowLeft } from '../../images/arrowLeft.svg';
import {
  BackButtonContainer,
  CloseCross,
  HeaderContainer,
  Link,
  Step,
  StepperContainer
} from '../../pages/Connection/style';
import cross from '../../images/Cross.svg';

export interface IStepsHeader {
  currentStep: number;
  steps: number;
  closeLinkTo: string;
  onCLose?: () => void;
  goBack?: () => void;
  displayGoBack: boolean;
}

export const StepsHeader: React.FC<IStepsHeader> = (props) => {
  const steps = Array.from(Array(props.steps + 1).keys());
  steps.shift();

  return (
    <HeaderContainer>
      {props.goBack && props.displayGoBack && (
        <BackButtonContainer onClick={props.goBack}>
          <Link to="#">
            <ArrowLeft />
            <span>Back</span>
          </Link>
        </BackButtonContainer>
      )}
      <div />
      <StepperContainer>
        {steps.map((step) => (
          <Step
            isCurrent={props.currentStep === step}
            isPassedStep={step < props.currentStep}
            key={step}
          >
            {step}
          </Step>
        ))}
      </StepperContainer>

      {
        (props.closeLinkTo || props.onCLose) && (
          <CloseCross>
            <Link to={props.closeLinkTo} onClick={props.onCLose}>
              <img src={cross} title="Close" alt="Close button" />
            </Link>
          </CloseCross>
        )
      }
    </HeaderContainer>
  );
};
