import {
  TableColumnDefinition,
  createTableColumn,
} from '@fluentui/react-components';
import React, { ReactNode, useState } from 'react';
import { dataResultButtonStyle } from './style';
import DefaultTooltip from '../DefaultTooltip';

export type CustomButtonType = {
  icon: ReactNode;
  onClick: () => void;
  oneTime?: boolean;
  tooltip: string;
};

export interface DataGridItem {
  key: string;
  icon: ReactNode;
  type?: string;
  label: string;
  buttons?: Array<CustomButtonType>;
}

export const DataGridColumns: TableColumnDefinition<DataGridItem>[] = [
  createTableColumn<DataGridItem>({
    columnId: 'type',
    compare: (a, b) => {
      const typeA = a.type || '';
      const typeB = b.type || '';
      return typeA.localeCompare(typeB);
    },
    renderHeaderCell: () => {
      return ' ';
    },
    renderCell: (item) => {
      return item.icon;
    },
  }),
  createTableColumn<DataGridItem>({
    columnId: 'label',
    compare: (a, b) => {
      return a.label.localeCompare(b.label);
    },
    renderHeaderCell: () => {
      return 'Label';
    },
    renderCell: (item) => {
      return <DataRow item={item} fillSpace={true} />;
    },
  }),
];

interface DataRowProps {
  item: DataGridItem;
  fillSpace: boolean;
}

const DataRow: React.FC<DataRowProps> = ({ item }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div style={{ flex: '1' }}>{item?.label}</div>
      <div style={{ display: 'flex' }}>
        {item.buttons &&
          item.buttons.map((button, index) => (
            <DataResultButton
              key={index}
              icon={button.icon}
              onClick={button.onClick}
              oneTime={button.oneTime}
              tooltip={button.tooltip}
            />
          ))}
      </div>
    </div>
  );
};

interface DataResultButtonProps {
  icon: ReactNode;
  onClick: () => void;
  oneTime: boolean | undefined;
  tooltip: string;
}

const DataResultButton: React.FC<DataResultButtonProps> = ({
  icon,
  onClick,
  oneTime,
  tooltip,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <DefaultTooltip text={tooltip}>
      <div
        style={dataResultButtonStyle(isHovered, disabled)}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            onClick();
            if (oneTime) setDisabled(true);
          }
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {icon}
      </div>
    </DefaultTooltip>
  );
};
