/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
import { AhauOpenAPI } from '../gen/src/ahauOpenAPI';
import { getConfig } from '../config';
import 'isomorphic-fetch';

export const getAuthHeaderValue = (token: string) => {
  return `Bearer ${token}`;
};

const { apiUrl } = getConfig();

export const WebApi = (token: string) => {
  const openApi = new AhauOpenAPI(apiUrl, {
    fetch: (input: RequestInfo, init?: RequestInit) => {
      if (init && init.headers) {
        // @ts-ignore
        init.headers['Authorization'] = getAuthHeaderValue(token);
        // @ts-ignore
        init.headers['Access-Control-Allow-Origin'] = '*';
        // @ts-ignore
        init.headers['X-Requested-With'] = 'Access-Control-Allow-Headers';
      }
      return fetch(input, init);
    }
  });

  return openApi;
};
