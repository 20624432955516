/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { memo, useState } from 'react';
import * as Validators from '../../helpers/validators';
import { ServiceProvider } from '../../gen/src/ahauOpenAPI';
import Input from '../Profile/Input';

const renderVerifyIconProps = (
  showStatusIcon?: boolean,
  isVerifying?: boolean
) => {
  return showStatusIcon && isVerifying
    ? {
      iconName: 'ProgressRingDots'
    }
    : undefined;
};

export interface IWebRootInputProps {
  defaultWebRoot?: string;
  readOnly?: boolean;
  webRoot?: string;
  serviceProvider?: ServiceProvider;
  serviceProviderName?: string;
  onChange?: (val: { webRoot?: string }) => void;
  onVerifyUrl?: () => void; // todo: return bool for set error message
  isVerified?: boolean;
  isVerifying?: boolean;
  prefix?: string;
  suffix?: string;
  isEditableWebRoot?: boolean;
  isChanged?: boolean;
  isAddressChanging?: boolean;
  showError?: () => void;
}

const WebRootInput: React.FC<IWebRootInputProps & {
  showStatusIcon?: boolean;
}> = (props) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  React.useEffect(() => {
    if (props.webRoot && !isLoaded) {
      setIsLoaded(true);
    }
  }, [props.webRoot]);

  const cleanUrl = (url?: string): string | undefined => {
    if (!url) return;

    if (
      props.serviceProvider === ServiceProvider.EasyProjects &&
      url.endsWith('/')
    ) {
      // eslint-disable-next-line no-param-reassign
      url = url.substring(0, url.length - 1);
    }

    let editable = props.prefix ? url.replace(props.prefix, '') : url;

    if (props.suffix) {
      editable = editable.replace(props.suffix, '');
    }

    return editable;
  };

  const getUrl = (part?: string): string => {
    return `${props.prefix ? props.prefix : ''}${part}${
      containsSuffix() ? props.suffix : ''
    }`;
  };

  const containsSuffix = (): boolean => {
    if (props.webRoot && props.suffix) {
      return props.webRoot.indexOf(props.suffix) > 0;
    }
    return false;
  };

  const handleChange = (e: any, value?: string) => {
    props.onChange && props.onChange({ webRoot: getUrl(value) });
  };

  const isInvalidInput = (value: string) =>
    Validators.required('URL is required field.')(value) ||
    Validators.url('URL is not valid.')(value);

  const {
    serviceProviderName,
    isVerifying,
    webRoot,
    isVerified,
    showStatusIcon
  } = props;

  return (
    <Input
      label={`${serviceProviderName} URL`}
      iconProps={renderVerifyIconProps(showStatusIcon, isVerifying)}
      onGetErrorMessage={(value) => isInvalidInput(getUrl(value)) || undefined}
      disabled={isVerifying}
      value={cleanUrl(webRoot) || ''}
      onChange={handleChange}
      deferredValidationTime={2000}
      spellCheck={false}
      validateOnFocusOut={false}
      isAsyncValidationFailed={showStatusIcon && !isVerifying && !isVerified}
    />
  );
};

export default memo(WebRootInput);
