/* eslint-disable @typescript-eslint/indent */
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Title = styled.h1<{ removeMarginBottom?: boolean }>`
  color: #464650;
  font-size: 24px;
  line-height: 32px;
  font-family: 'TTHoves-DemiBold';
  margin-bottom: ${(props) => (props.removeMarginBottom ? 'unset' : '30px')};
  text-transform: capitalize;

  [data-content$='Request'] {
    color: red !important;
  }
`;

export const InputContainer = styled.div<{ isRequired?: boolean }>`
  position: relative;
`;

export const Input = styled.input<{ isRequired?: boolean }>`
  width: 100%;
  height: 56px;
  box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
  border: none;
  padding-left: 20px;
  color: #464650;
  font-size: 16px;
  position: relative;

  + label {
    position: absolute;
    top: 16px;
    left: 20px;
    color: #9b9ba1;
  }

  ${(props) =>
  props.isRequired &&
  `
        + label:after {
            content:'*';
            margin-left: 4px;
            color: #b30000;
        }
    `}

  &:focus {
    outline: none;
  }

  @media only screen and (-webkit-device-pixel-ratio: 2) {
    -webkit-appearance: none;
    box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  }

  @media only screen and (-webkit-device-pixel-ratio: 3) {
    -webkit-appearance: none;
    box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  }
`;

export const InputPlaceholder = styled.label<{
  fieldName?: string;
  fieldValue: string | number;
}>`
  pointer-events: none;
  display: ${(props) =>
  props.fieldValue !== undefined && props.fieldValue !== ''
    ? 'none'
    : 'initial'};

  ${(props) =>
  props.fieldName === 'numberOfUsers' &&
  `
        display: ${
    Number(props.fieldValue) !== 0 &&
    props.fieldValue !== undefined &&
    props.fieldValue !== ''
      ? 'none'
      : 'initial'
  };
    `};
`;

export const TextArea = styled.textarea<{ isRequired?: boolean }>`
  width: 100%;
  padding-top: 10px;
  box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
  border: none;
  padding-left: 20px;
  color: #464650;
  font-size: 16px;
  min-height: 5rem;

  + label {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #9b9ba1;
  }

  ${(props) =>
  props.isRequired &&
  `
        + label:after {
            content:'*';
            margin-left: 4px;
            color: #b30000;
        }
    `}

  &:focus {
    outline: none;
  }
`;

export const Footer = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #909696;
  margin-top: 20px;
  font-family: 'segoeuii';

  @media all and (max-width: 368px) {
    text-align: center;
  }
`;

export const UnderLine = styled.span`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
`;

export const Cross = styled.div<{ alwaysShow?: boolean; hide?: boolean }>`
  position: relative;
  top: ${(props) => (!props.alwaysShow ? '-55px' : '-90px')};
  right: -275px;
  width: 32px;
  height: 32px;
  background: #d3d3d3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 600px) {
    right: -236px;
  }

  @media (max-width: 510px) {
    top: -85px;
    right: -215px;
  }

  @media (max-width: 470px) {
    display: none;
    right: -190px;
  }

  @media (max-width: 410px) {
    display: none;
  }

  ${(props) =>
  props.hide &&
  `
        display: none;
    `}
`;

export const BackButton = styled.div<{ isSuccessMessage?: boolean }>`
  width: 90px;
  height: 38px;
  padding: 5px;
  border: 2px solid #3a86ff;
  color: #3a86ff;
  background: transparent;
  border-radius: 10px;
  margin-left: 15px;
  position: absolute;
  top: ${(props) => (props.isSuccessMessage ? '-50px' : '-3px')};
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0.8;

  i {
    font-size: 16px;
    margin-top: 3px;
  }

  span {
    font-size: 17px;
    font-weight: 600;
  }

  &:hover {
    background: #eef3ff;
    color: #296dd9;
    border-color: #296dd9;
    opacity: 1;
  }

  @media (min-width: 769px) {
    display: none;
  }

  @media (max-width: 520px) {
    width: 82px;
    height: 35px;
  }

  @media (max-width: 375px) {
    top: -35px;
  }

  @media (max-width: 330px) {
    top: -37px;
  }

  ${(props) =>
  props.isSuccessMessage &&
  `
        @media (max-width: 768px) {
            margin-left: unset;
            top: -46px;
        }

        @media (max-width: 520px) {
            top: -43px;
        }

        @media (max-width: 453px) {
            top: -43px;
        }

        @media (max-width: 375px) {
            top: -43px;
        }
    `};
`;

export const FooterTextContainer = styled.div`
  a {
    text-decoration: none;
    color: #909696;
  }
`;

export const SuccessModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  i {
    font-size: 90px;
    width: 100%;
    color: #62ac62;
    text-align: center;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const SuccessModalBody = styled(Modal.Body)`
  position: absolute !important;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px !important;
  height: 100px;

  h2,
  h5 {
    text-align: center;
  }

  h5 {
    font-weight: normal;
    margin-top: 10px;
  }

  @media (max-width: 468px) {
    width: 95%;
  }

  @media (max-width: 440px) {
    width: 90%;
  }

  @media (max-width: 412px) {
    width: 85%;
  }

  @media (max-width: 385px) {
    width: 85%;
  }

  @media (max-width: 340px) {
    h2 {
      font-size: 1.8rem;
    }

    h5 {
      font-size: 1.15rem;
    }
  }
`;

export const Error = styled.div`
  padding-left: 20px;
  margin-bottom: 20px;
  color: red;
  font-family: 'segoeuii';
  font-size: 14px;
`;
