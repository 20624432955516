import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { connectionImages } from '../Profile/data';

const LogoContainer = styled.section`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const ServiceLogo = (props: {
  webRoot?: string;
  serviceProvider?: ServiceProvider;
}) => {
  // * Logos need defining and improvement, this will do it for now
  const serviceProviderName = props.serviceProvider;

  const imageSrc = get(connectionImages, serviceProviderName || '', '');

  return (
    <LogoContainer className="ms-textAlignCenter">
      <a
        href={props.webRoot}
        title={serviceProviderName}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={imageSrc}
          alt={serviceProviderName}
          width="130"
          height="130"
        />
      </a>
    </LogoContainer>
  );
};

ServiceLogo.defaultProps = {
  webRoot: '',
  serviceProvider: ''
};
