/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageBtn, TeamIcon } from '../style';
import { ReactComponent as OrgTeamIcon } from '../../../images/OrgTeamIcon.svg';
import approve from '../../../images/Approve.svg';
import cross from '../../../images/CrossBlack.svg';
import editImg from '../../../images/Edit.svg';
import deleteImg from '../../../images/Delete.svg';
import { AddTeamInput, OrgListItem, TeamRowActionContainer } from './style';
import {
  fetchDeleteOrganizationTeam,
  fetchUpdateOrganizationTeam,
  selectOrganizations
} from '../../../utils/reducers/organizations';
import OrgInputWrapper from '../OrgInputWrapper';
import { LoadingButton } from '../../Profile/Loading';
import { TeamDto, TeamInputDto } from '../../../gen/src/ahauOpenAPI';
import mixpanelService, { EventName } from '../../../services/MixpanelService';

export interface TeamItemProps {
  teamItem: TeamDto;
  color: string;
}

const TeamItemRow: React.FC<TeamItemProps> = (props) => {

  const dispatch = useDispatch();

  const { color, teamItem } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [newTeamName, setNewTeamName] = useState<string>(teamItem.name || '');
  const [warningMessage, setWarningMessage] = useState<string>('');

  const { organization: { teams } } = useSelector(selectOrganizations);

  useEffect(() => {
    isLoading && setIsLoading(false);
  }, [teamItem.name]);

  const updateName = React.useCallback(() => {
    const name = newTeamName.trim();
    if (!name) {
      setWarningMessage('Name can\'t be empty');
      return;
    }
    if (teams?.find((team) => team.name === name && team.id !== teamItem.id)) {
      setWarningMessage('Team with such name already exists!');
      return;
    }
    if (teamItem.name === name) {
      setIsEditMode(false);
      return;
    }
    setIsLoading(true);
    setIsEditMode(false);

    dispatch(
      fetchUpdateOrganizationTeam(
        teamItem.organizationId,
        teamItem.id,
        new TeamInputDto({ name, color })
      )
    );
  }, [teamItem, newTeamName]);

  const deleteTeam = useCallback(() => {
    mixpanelService.sendEvent(EventName.TEAM, 'Delete');
    setIsLoading(true);
    dispatch(fetchDeleteOrganizationTeam(teamItem));
  }, [teamItem]);

  if (!isEditMode) {
    return (
      <OrgListItem isEditMode={isEditMode} isLoading={isLoading}>
        <TeamIcon {...props}>
          <OrgTeamIcon />
        </TeamIcon>
        <span title={teamItem.name}>{teamItem.name}</span>
        {isLoading ? (
          <LoadingButton extraStyles={{ width: '100%', justifyContent: 'end', border: '0' }} />
        ) : (
          <>
            <TeamRowActionContainer>
              <ImageBtn src={editImg} onClick={() => setIsEditMode(true)} />
              <ImageBtn
                src={deleteImg}
                onClick={() => {
                  setIsLoading(true);
                  deleteTeam();
                }}
              />
            </TeamRowActionContainer>
          </>
        )}
      </OrgListItem>
    );
  }

  return (
    <OrgListItem isEditMode={isEditMode} displayWarning={!!warningMessage}>
      <TeamIcon {...props}>
        <OrgTeamIcon />
      </TeamIcon>

      <OrgInputWrapper warningText={warningMessage}>
        <AddTeamInput
          displayWarning={false}
          autoFocus={true}
          type="text"
          value={newTeamName}
          onChange={(e) => {
            setWarningMessage('');
            setNewTeamName(e.target.value);
          }}
          placeholder="New Team Name"
          alt="Add new team to organization"
          onKeyUp={(event) => event.key === 'Enter' && updateName()}
        />
      </OrgInputWrapper>

      <TeamRowActionContainer>
        <ImageBtn src={approve} onClick={updateName} />
        <ImageBtn
          src={cross}
          onClick={() => {
            setWarningMessage('');
            setNewTeamName(teamItem?.name ?? "");
            setIsEditMode(false);
          }}
        />
      </TeamRowActionContainer>
    </OrgListItem>
  );
};

export default TeamItemRow;

