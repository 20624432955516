/* eslint-disable import/no-cycle */
/* eslint-disable-next-line no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { AppThunk, RootState } from '../store';
import {
  AccountCreateDataDto,
  AccountInfoDto,
  AccountInfoStatus,
  ConnectorEditDataDto,
  CreateAccountInputDto,
  ServiceProvider
} from '../../gen/src/ahauOpenAPI';
import { setError } from '../appReducer';
import { ERROR_MESSAGE } from '../../consts';
import { getApiClient } from '../api';
import { isCRMProvider, isDataSourceProvider } from '../../helpers/connectionHelper';
import mixpanelService from '../../services/MixpanelService';

export interface IServiceProviderData {
  name?: string;
  webRoot: string;
  isDefault: boolean;
  serviceProvider: ServiceProvider;
  serviceProviderName?: string;
}

export interface AccountsListState {
  isCreateDialogOpen: boolean;
  connectedAccounts: Array<AccountInfoDto>;
  connectors: ConnectorEditDataDto[];
  // todo:  multiaccounts?
  serviceProviderData: IServiceProviderData;
  isLoading: boolean;
  createData?: AccountCreateDataDto;
  isChoiceOptionsLoading: boolean;
}

const initialState: AccountsListState = {
  isCreateDialogOpen: false,
  connectedAccounts: [],
  connectors: [],
  serviceProviderData: {
    serviceProvider: ServiceProvider.Unknown,
    serviceProviderName: '',
    name: '',
    webRoot: '',
    isDefault: false
  },
  isLoading: true,
  isChoiceOptionsLoading: false
};

export const accountsListSlice = createSlice({
  name: 'accountsList',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setCreateData: (state, action: PayloadAction<AccountCreateDataDto>) => {
      state.createData = action.payload;
      state.connectors = action.payload.connectors;
      state.isChoiceOptionsLoading = false;
    },
    setAccountsList: (
      state,
      action: PayloadAction<AccountInfoDto[] | undefined>
    ) => {
      state.connectedAccounts = action.payload ?? [];
    },
    selectProvider: (state, action: PayloadAction<CreateAccountInputDto>) => {
      state.serviceProviderData = action.payload;
    }
  }
});

export const { selectProvider, setAccountsList } = accountsListSlice.actions;

// thunks

export const fetchAccountsList = (): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  dispatch(accountsListSlice.actions.startLoading());

  try {
    const accountsList = await api.accountsGetAll();
    mixpanelService.setUserAccounts(accountsList.items);

    dispatch(
      accountsListSlice.actions.setAccountsList(
        accountsList.items?.filter(
          (account) =>
            account.status !== AccountInfoStatus.Deleting
        )
      )
    );
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  } finally {
    dispatch(accountsListSlice.actions.stopLoading());
  }
};

export const fetchAccountCreateData = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { accessToken } = getState().appReducer;
  const api = await getApiClient(accessToken);

  try {
    const createData = await api.accountsGetAccountCreateData();

    if (!createData.connectors) {
      throw new Error('connectors is empty while get account create data');
    }

    dispatch(accountsListSlice.actions.setCreateData(createData));
  } catch (e) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const deleteAccountAsync = (accountId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    await api.accountsDelete(accountId);
    dispatch(fetchAccountsList());
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

// selectors

export const selectAccountsList = (state: RootState) => state.accounts.list;
export const selectServiceProvied = (state: RootState) =>
  state.accounts.list.serviceProviderData;

export default accountsListSlice.reducer;
