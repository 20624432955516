import React from 'react';
import HistoryRow from './HistoryRow';
import SearchHistory from './SearchHistory';

interface HistoryListProps {
  onHistoryRowClick: (query: string) => void;
  maxItems?: number;
  searchQuery: string | undefined;
}

const HistoryList: React.FC<HistoryListProps> = ({
  onHistoryRowClick,
  maxItems = 5,
  searchQuery,
}) => {
  // Filter histories based on the searchQuery
  const histories: string[] = SearchHistory.getInstance().getSearchHistory();
  const filteredHistories = searchQuery
    ? histories.filter((history) =>
        history.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : histories.slice(0, maxItems);

  return (
    <>
      {filteredHistories.slice(0, maxItems).map((history, index) => (
        <HistoryRow
          key={history}
          query={history}
          onClick={onHistoryRowClick}
          searchQuery={searchQuery}
        />
      ))}
    </>
  );
};

export default HistoryList;
