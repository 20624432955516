import React, { useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import styled from 'styled-components';
import { Callout } from '@fluentui/react';
import { RemoveButton } from './RemoveButton';
import { StyledActiveMenuIcon, StyledMenuIcon } from './StyledMenuIcon';
import { LIGHT_BLUE_COLOR, WHITE_COLOR } from '../consts/theme';

export interface ICardActionsActionsProps {
  onRemoveConnection?: () => void;
  title: string;
  subTitle: string;
}

const ActionsContainer = styled(Callout)`
  width: 244px;
  box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    0px 14px 24px rgba(51, 51, 51, 0.12);
  border-radius: 4px;
  background: ${WHITE_COLOR};
  font-family: 'segoeuii';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  .ms-Callout-beak {
    display: none;
  }

  p {
    margin: 0;
    cursor: pointer;
    padding: 16px;
  }
  p:hover {
    background: ${LIGHT_BLUE_COLOR};
  }
`;

export const CardActions: React.FC<ICardActionsActionsProps> = (props) => {
  const [isActionsVisible, setIsVisible] = useState<boolean>(false);
  const buttonId = useId('callout-button');

  const handleOpenActions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsVisible(true);
  };

  const handleCloseActions = () => {
    setIsVisible(false);
  };

  return (
    <>
      {!isActionsVisible && (
        <StyledMenuIcon id={buttonId} onClick={handleOpenActions} />
      )}
      {isActionsVisible && (
        <StyledActiveMenuIcon id={buttonId} onClick={handleOpenActions} />
      )}
      {isActionsVisible && (
        <ActionsContainer
          role="dialog"
          gapSpace={0}
          onDismiss={handleCloseActions}
          setInitialFocus
          target={`#${buttonId}`}
        >
          <RemoveButton {...props} />
        </ActionsContainer>
      )}
    </>
  );
};
