import styled from 'styled-components';

const tweakpoints = {
  mediumScreen: '868px',

  smallScreenA: '710px',
  smallScreenB: '600px',
  smallScreenC: '500px',
  smallScreenD: '430px',
  smallScreenE: '400px'
};

export const ErrorWrapperContainer = styled.div`
  height: 600px;
  position: relative;
  color: #464650;
`;

export const ErrorBoxContainer = styled.div`
  width: 650px;
  height: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f7f7f7;
  border-radius: 20px;

  @media all and (max-width: ${tweakpoints.mediumScreen}) {
    width: 590px;
  }

  @media all and (max-width: ${tweakpoints.smallScreenA}) {
    width: 500px;
  }

  @media all and (max-width: ${tweakpoints.smallScreenB}) {
    width: 100%;
    height: fit-content;
  }
`;

export const ErrorBoxInner = styled.div`
  padding: 30px;
  height: 100%;

  i {
    font-size: 90px;
    width: 100%;
    text-align: center;
    color: #c82333;
  }

  h3 {
    text-align: center;
    font-size: 30px;

    @media all and (max-width: ${tweakpoints.smallScreenD}) {
      font-size: 25px;
    }
  }

  p {
    margin-top: 25px;
    text-align: center;
    font-size: 17px;

    @media all and (max-width: ${tweakpoints.smallScreenD}) {
      font-size: 16px;
    }
  }

  a {
    display: inline-block;
  }

  @media all and (max-width: ${tweakpoints.smallScreenB}) {
    i {
      font-size: 80px;
    }

    p {
      margin-top: 15px;
      font-size: 17px;
    }
  }
`;
