import { CSSProperties } from "react";
import { COLORS, SIZES } from "../../style";

export const historyRowStyles = {
    historyRow: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0 8px 0 8px',
      borderTop: `1px solid ${COLORS.lightGray}`,
      background: '#fff',
      height: '30px',
    },
    hovered: {
      background: COLORS.lightGray,
    },
    searchIcon: {
      marginRight: '8px',
      color: COLORS.globalGrayColor,
      fontWeight: '900',
      fontSize: SIZES.defaultFontSize,
    },
    labelText: {
      fontSize: SIZES.defaultFontSize,
      marginLeft: '6px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    } as CSSProperties,
  };