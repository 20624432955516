import * as React from 'react';
import { Spinner, Stack } from '@fluentui/react';
import { useHistory } from 'react-router';
import { FormFieldSection } from '../../components/Forms';
import { ConnectionSettingsOutputDto } from '../../gen/src/ahauOpenAPI';
import { getAuthorizeUrl, getConnectionType } from '../../helpers/connectionHelper';
import Button from '../../components/StyledButton';

interface IAuthorizeOAuthSectionProps {
  customOAuthButtonText?: string;
  isAuthorizing?: boolean;
  editData?: ConnectionSettingsOutputDto;
  accountId?: number;

  connect?: () => void;
}

export const AuthorizeOAuthSection: React.FC<IAuthorizeOAuthSectionProps> = (
  props
) => {
  const history = useHistory();

  const handleConnect = () => {
    props.connect && props.connect();
    if (props.accountId) {
      history.push(
        `/connection/${props.accountId}?accountType=${getConnectionType(
          window.location.search
        )}`
      );
    }
    window.location.assign(
      getAuthorizeUrl(
        props.accountId || 0,
        getConnectionType(window.location.search)
      )
    );
  };

  return (
    <>
      <FormFieldSection>
        <Stack verticalAlign="start" tokens={{ childrenGap: 15 }}>
          <Stack
            horizontal
            horizontalAlign="start"
            tokens={{ childrenGap: 20 }}
          >
            <Button
              title="Authorize"
              onClick={handleConnect}
              disabled={!props.accountId}
            />
            {props.isAuthorizing && (
              <Spinner label="Authorizing" labelPosition="right" />
            )}
          </Stack>
        </Stack>
      </FormFieldSection>
    </>
  );
};
