/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
export const CONTACT_US = 'CONTACT_US';

export enum UserActions {
  CONTACT_US = 'Contact Us'
}

export enum DestineLocations {
  CONTACT_US = '/contactUs'
}
