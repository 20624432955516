/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { IIconProps } from '@fluentui/react';
import React, { memo, useState } from 'react';
import { InputContainer, InputLabel, StyledInput } from './style';

interface IInputProps {
  label?: string;
  iconProps?: IIconProps;
  disabled?: boolean;
  value?: string;
  spellCheck?: boolean;
  validateOnFocusOut?: boolean;
  validateOnLoad?: boolean;
  type?: string;
  deferredValidationTime?: number;
  isAsyncValidationFailed?: boolean;

  onGetErrorMessage?:
  | ((
    value: string
  ) => string | JSX.Element | PromiseLike<string | JSX.Element> | undefined)
  | undefined;
  onKeyDown?:
  | React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  | undefined;
  onChange?:
  | ((
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void)
  | undefined;
}

const Input: React.FC<IInputProps> = ({ label, ...props }) => {
  const [isValid, setIsValid] = useState<boolean>(true);

  const validate = (value: string) => {
    if (props.onGetErrorMessage) {
      const result = props.onGetErrorMessage(value);
      setIsValid(!result);
      return result;
    }
    return '';
  };

  return (
    <InputContainer>
      {props.value && (
        <InputLabel disabled={props.disabled}>{label}</InputLabel>
      )}
      <StyledInput
        {...props}
        onGetErrorMessage={validate}
        placeholder={label}
        isEmpty={!props.value}
        isValid={isValid}
        autoComplete="off"
        isAsyncValidationFailed={props.isAsyncValidationFailed}
      />
    </InputContainer>
  );
};

export default memo(Input);
