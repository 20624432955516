import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ISearchBox, SearchBox } from '@fluentui/react/lib/SearchBox';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { IconButton } from '@fluentui/react';
import SearchHistory from './History/SearchHistory';
import Panel from './SearchFiltersPanel/Panel';
import { backButtonStyle, iconButtonStyle, searchBoxStyle } from './style';
import { ItemType } from '../../../gen/src/ahauOpenAPI';

const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };

interface SearchBoxContainerProps {
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onSearchQueryChange: (query: string | undefined) => void;
  setDisplayResults: (value: boolean) => void;
  defaultQuery?: string | undefined;
  setDefaultQuery: (query: string) => void;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
  setFilter: React.Dispatch<React.SetStateAction<ItemType>>;

}

export const SearchBoxInput: React.FC<SearchBoxContainerProps> = ({
  isFocused,
  onFocus,
  onBlur,
  onSearchQueryChange,
  setDisplayResults,
  defaultQuery,
  setDefaultQuery,
  isSearching,
  setIsSearching,
  setFilter,
}) => {
  const searchBoxRef = useRef<ISearchBox | null>(null);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>('');

  useEffect(() => {
    if (searchBoxRef.current && isFocused) {
      searchBoxRef.current.focus();
    }
  }, [isFocused]);

  const handleSearch = () => {
    if (!searchQuery) return;
    const trimmedQuery = searchQuery.trim();
    if (!trimmedQuery) return;
    setIsSearching(false);
    SearchHistory.getInstance().addSearchTerm(trimmedQuery);
    setDisplayResults(true);
    if (searchBoxRef.current && isFocused) {
      searchBoxRef.current.blur();
    }
  };
  const handleSearchQueryChange = (e: any, newValue: string | undefined) => {
    if (!isSearching) setIsSearching(true);
    setSearchQuery(newValue);
    onSearchQueryChange(newValue);
  };

  const searchBox = (
    <SearchBox
      placeholder="Search"
      onSearch={handleSearch}
      onChange={handleSearchQueryChange}
      onFocus={onFocus}
      styles={searchBoxStyle(isFocused)}
      showIcon={true}
      disableAnimation={true}
      componentRef={searchBoxRef}
      value={defaultQuery}
    />
  );

  return (
    <Stack tokens={stackTokens}>
      <div style={{ width: '100%' }}>
        {searchBox}
        {isFocused ? (
          <IconButton
            iconProps={{ iconName: 'ChromeBack' }}
            title="Go Back"
            onClick={() => {
              if (isFocused) {
                onBlur();
                setIsSearching(true);
                setDefaultQuery('');
              } else onFocus();
            }}
            styles={backButtonStyle}
          />
        ) : (
          <IconButton
            iconProps={{ iconName: 'Search' }}
            title="Search"
            onClick={() => {
              if (isFocused) onBlur();
              else onFocus();
            }}
            styles={iconButtonStyle}
          />
        )}
        <Panel
          isFocused={isFocused}
          isSearching={isSearching}
          onButtonClick={handleSearch}
          setFilter={setFilter}
        />
      </div>
    </Stack>
  );
};
