import React, { HTMLAttributes, PureComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { SEPARATOR_COLOR } from '../consts/theme';

export const SectionBlock = styled.div<{ alignCenter?: boolean }>`
  margin-top: 10px;
  margin-bottom: 10px;
  ${({ alignCenter }) =>
    alignCenter &&
  `
    text-align:center;
  `}
`;

/**
 * Separates a section of any elements from each other.
 */
export const Section = (props: {
  children: ReactNode;
  alignCenter?: boolean;
}) => (
  <SectionBlock alignCenter={props.alignCenter}>{props.children}</SectionBlock>
);

Section.defaultProps = {
  alignCenter: false
};

const HeroDiv = styled(SectionBlock)``;
/**
 * Large page title section
 */
export const HeroSection = (props: { children: ReactNode }) => (
  <HeroDiv className="ms-font-xxl">{props.children}</HeroDiv>
);

const TitleDiv = styled(SectionBlock)`
  margin-bottom: 1rem;
`;
/**
 * Page header section
 */
export const TitleSection = (props: { children: ReactNode }) => (
  <TitleDiv className="ms-font-xl">{props.children}</TitleDiv>
);

const SubTitleDiv = styled(SectionBlock)`
  margin-bottom: 1rem;
`;
export const SubTitleSection = (props: { children: ReactNode }) => (
  <SubTitleDiv className="ms-font-l">{props.children}</SubTitleDiv>
);

const ActionSectionBlock = styled(SectionBlock)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  > button {
    margin-top: 0.5rem;
    margin-right: 0.6rem;
    vertical-align: middle;
  }
  > a {
    margin-top: 0.5rem;
    margin-right: 0.6rem;
    vertical-align: middle;
  }
  > div {
    margin-top: 1rem;
    margin-right: 0.6rem;
    vertical-align: middle;
    display: inline-block;
  }
  & > span > button {
    margin-top: 0.5rem;
    margin-right: 0.6rem;
    vertical-align: middle;
    display: inline;
  }
`;

/**
 * The section with action buttons
 */
export const ActionSection = (props: HTMLAttributes<HTMLDivElement>) => (
  <ActionSectionBlock {...props}>{props.children}</ActionSectionBlock>
);

export const ActionFlexSection = styled(ActionSection)`
  display: flex;
  justify-content: space-between;
`;

const WarnDiv = styled(SectionBlock)`
  color: #ea4300;
`;
/**
 * A section with some kind of warning
 */
export const WarnSection = (props: { children: ReactNode }) => (
  <WarnDiv className="ms-font-m-plus warn-section">{props.children}</WarnDiv>
);

const SuccessDiv = styled(SectionBlock)`
  color: green;
`;

/**
 * A section with "ok" message
 */
export const SuccessSection = (props: { children: ReactNode }) => (
  <SuccessDiv className="ms-font-m-plus success-section">
    {props.children}
  </SuccessDiv>
);

const PageHeroBlock = styled.div`
  text-align: center;
`;

/**
 * Adds a header, caption and everything to the page.
 */
export class PageHeroSection extends PureComponent<{
  hero?: ReactNode;
  title?: ReactNode;
  subTitle?: ReactNode;
}> {
  render() {
    return (
      <PageHeroBlock>
        {this.props.hero && <HeroSection>{this.props.hero}</HeroSection>}
        {this.props.title && <TitleSection>{this.props.title}</TitleSection>}
        {this.props.subTitle && (
          <SubTitleSection>{this.props.subTitle}</SubTitleSection>
        )}
      </PageHeroBlock>
    );
  }
}

const Separator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  color: ${SEPARATOR_COLOR};

  .separator-line {
    border-top: 1px solid;
    width: 100%;
  }

  .separator-text {
    width: fit-content;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const SeparatorSection = (props: { children: ReactNode }) => (
  <Separator>
    <div className="separator-line" />
    <span className="separator-text"> {props.children}</span>
    <div className="separator-line" />
  </Separator>
);
