/* eslint-disable import/no-cycle */
import React from 'react';
import { FormFieldSection } from '../../components/Forms';
import * as Validators from '../../helpers/validators';
import { IConnectionCredentials } from './ConnectionAuthorizeForm';
import Input from '../Profile/Input';

interface IUserCredentialsFormProps {
  secondfieldLabel?: string;
  label?: string;
  userName?: string;
  password?: string;
  passwordLength?: number;

  changedAuthMethod?: () => void;
  onChange?: (value?: IConnectionCredentials | undefined) => void;
  connectCredentials?: () => void;
}

export const UserCredentialsSection: React.FC<IUserCredentialsFormProps> = React.memo(
  (props) => {
    const { userName, password, secondfieldLabel } = props;

    const keyPress = (
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (e.keyCode === 13) {
        props.connectCredentials && props.connectCredentials();
        e.preventDefault();
      }
    };

    return (
      <>
        <FormFieldSection>
          <Input
            label={props.label}
            spellCheck={false}
            validateOnFocusOut
            validateOnLoad={false}
            onGetErrorMessage={(value) => {
              return Validators.required(`${props.label} is required field.`)(
                value
              );
            }}
            value={userName}
            // tslint:disable-next-line:jsx-no-lambda
            onChange={(e, value) => {
              props.onChange &&
              props.onChange({ userName: value, password: props.password });
            }}
            onKeyDown={(e) => keyPress(e)}
          />
        </FormFieldSection>
        <FormFieldSection>
          <Input
            label={secondfieldLabel}
            type="password"
            validateOnFocusOut
            validateOnLoad={false}
            onGetErrorMessage={(value) => {
              return Validators.required('Password is required field')(value);
            }}
            value={password}
            // tslint:disable-next-line:jsx-no-lambda
            onChange={(e, value) => {
              props.onChange &&
              props.onChange({ userName: props.userName, password: value });
            }}
            onKeyDown={(e) => keyPress(e)}
          />
        </FormFieldSection>
      </>
    );
  }
);
