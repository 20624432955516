/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../consts/routes';
import {
  Avatar,
  ProfileActionButton,
  ProfileActionLink,
  ProfileActionsContainer,
  ProfileContainer,
  ProfileContentContainer,
  ProfileInfoContainer,
  ProfileInfoItem,
  ProfileLogoutButton
} from './styled';
import { clearProfileData, selectLicenseData, setUserEmail } from '../../utils/appReducer';
import { GET_SUPPORT } from '../../utils/constants';
import { WidthContext } from '../../App';
import { checkOverflow } from '../../utils/utilsFunctions';
import { getConfig } from '../../config';
import { selectOrganizations } from '../../utils/reducers/organizations';
import mixpanelService, { EventName } from '../../services/MixpanelService';

export interface IAuthenticatedHeaderProps {
  isProfileMenuOpen: boolean;

  onUserLogout: () => void;
  openProfileMenu: (isOpen: boolean) => void;
  changeMobileMenuVisibility: (isOpen: boolean) => void;
}

interface IHeaderTooltipsState {
  nameTooltip: boolean;
  emailTooltip: boolean;
  type: 'name' | 'email' | '';
}

interface IHeaderOverflowState {
  nameTooltipOverflow: boolean;
  emailTooltipOverflow: boolean;
}

const { stripeClientPortalUrl } = getConfig();

const AuthenticatedHeader: React.FC<IAuthenticatedHeaderProps> = (props) => {
  const { user } = useAuth0();

  const history = useHistory();
  const dispatch = useDispatch();

  const width = useContext(WidthContext);

  const { data: licenseData } = useSelector(selectLicenseData);
  const { organizations } = useSelector(selectOrganizations);

  const profileButtonRef = useRef<HTMLImageElement>(null);
  const nameRef = useRef<HTMLDivElement>(null);
  const emailRef = useRef<HTMLDivElement>(null);

  const [target, setTarget] = useState<EventTarget | null>(null);

  const [isOverflowing, setIsOverflowing] = useState<IHeaderOverflowState>({
    nameTooltipOverflow: false,
    emailTooltipOverflow: false
  });

  const [showTooltips, setShowTooltips] = useState<IHeaderTooltipsState>({
    nameTooltip: false,
    emailTooltip: false,
    type: ''
  });

  const [userEmailParts, setUserEmailParts] = useState<string[]>([
    '',
    ''
  ]);

  useEffect(() => {
    if (user?.email) {
      dispatch(setUserEmail(user.email));
      mixpanelService.init(user.email);
      const emailParts = user.email.split('@');
      setUserEmailParts(emailParts);
    }
  }, [dispatch, user?.email]);

  const onProfileButtonClick = (button: 'page' | 'support' | 'logout') => {
    if (button === 'page') {
      history.push('/');
      props.openProfileMenu(false);
    }

    if (button === 'support') {
      const newWindow = window.open(
        GET_SUPPORT,
        '_blank',
        'noopener,noreferrer'
      );
      if (newWindow) newWindow.opener = null;
    }

    if (button === 'logout') {
      props.changeMobileMenuVisibility(false);
      props.openProfileMenu(false);
      dispatch(clearProfileData());
      props.onUserLogout();
    }
  };

  useLayoutEffect(() => {
    if (props.isProfileMenuOpen) {
      setIsOverflowing({
        nameTooltipOverflow: checkOverflow(nameRef.current),
        emailTooltipOverflow: checkOverflow(emailRef.current)
      });
    }
  }, [props.isProfileMenuOpen, width]);

  const handleManageSubscription = () => {
    mixpanelService.sendEvent(EventName.NAVIGATION, 'Authenticated Header - Manage Subscription')
    // todo: fix
    const win = window.open(
      `${stripeClientPortalUrl}?prefilled_email=${user?.email}`,
      '_blank'
    );
    win?.focus();

    // if (licenseData?.isTrial) {
    //   window.location.replace('https://www.ahau260.com/pricing');
    // } else {
    //   const win = window.open(
    //     `${stripeClientPortalUrl}?prefilled_email=${user?.email}`,
    //     '_blank'
    //   );
    //   win?.focus();
    // }
  };

  const orgMenu = organizations?.map((o) => {
    return (
      <ProfileActionLink
        key={o.id.toString()}
        onClick={(e) => {
          history.push(Routes.membersManagement.getPath!(o.id.toString()));
          mixpanelService.sendEvent(EventName.ORGANIZATION, 'Select');
        }}
      >
        {' '}
        {o.name}
      </ProfileActionLink>
    );
  });

  return (
    <>
      <ProfileContainer ref={profileButtonRef}>
        <Avatar
          src={user?.picture}
          alt={user?.name}
          onClick={(e) => {
            props.openProfileMenu(!props.isProfileMenuOpen);
            setTarget(e.target);
          }}
          customStyles={{
            cursor: 'pointer'
          }}
        />

        <Overlay
          show={props.isProfileMenuOpen}
          target={target as HTMLElement}
          placement="bottom-end"
          container={profileButtonRef.current}
          containerPadding={20}
          rootClose
          onHide={() => props.openProfileMenu(false)}
        >
          <Popover id="popover-container">
            <Popover.Content>
              <ProfileContentContainer>
                <Avatar
                  src={user?.picture}
                  alt={user?.name}
                  onClick={() => onProfileButtonClick('page')}
                  popupAvatar
                  customStyles={{
                    width: '48px',
                    height: '48px',
                    margin: '0 auto',
                    cursor: 'pointer'
                  }}
                />
                <ProfileInfoContainer>
                  <OverlayTrigger
                    key="name"
                    onToggle={(showTooltip) => {
                      if (isOverflowing.nameTooltipOverflow) {
                        setShowTooltips({
                          ...showTooltips,
                          nameTooltip: showTooltip,
                          type: 'name'
                        });
                      }
                    }}
                    show={
                      (showTooltips.nameTooltip &&
                        showTooltips.type === 'name') ??
                      false
                    }
                    overlay={
                      <Tooltip id="name" show={false}>
                        {user?.name}
                      </Tooltip>
                    }
                  >
                    <ProfileInfoItem ref={nameRef}>{user?.name}</ProfileInfoItem>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key="email"
                    placement="top"
                    onToggle={(showTooltip) => {
                      // if (isOverflowing.emailTooltipOverflow) {
                      setShowTooltips({
                        ...showTooltips,
                        emailTooltip: showTooltip,
                        type: 'email'
                      });
                      // }
                    }}
                    show={
                      (showTooltips.emailTooltip &&
                        showTooltips.type === 'email') ??
                      false
                    }
                    overlay={<Tooltip id="email">{user?.email}</Tooltip>}
                  >
                    <ProfileInfoItem className="email" ref={emailRef}>
                      <p className="email-text">{userEmailParts[0]}</p>
                      <p className="email-website">@{userEmailParts[1]}</p>
                    </ProfileInfoItem>
                  </OverlayTrigger>
                </ProfileInfoContainer>
              </ProfileContentContainer>

              <ProfileActionsContainer>
                { /*        <ProfileActionButton
                  onClick={() => onProfileButtonClick('page')}
                >
                  My Profile
                </ProfileActionButton> */}
                {orgMenu}
                <ProfileActionButton onClick={handleManageSubscription}>
                  {licenseData?.isTrial ? 'Buy' : 'Manage'} Subscription
                </ProfileActionButton>
                <ProfileActionLink 
                  href={GET_SUPPORT} 
                  target="_blank" 
                  onClick={() => mixpanelService.sendEvent(EventName.NAVIGATION, 'Authenticated Header - Support')}
                >
                  Support
                </ProfileActionLink>
                <ProfileLogoutButton
                  isTransparent
                  onClick={() => onProfileButtonClick('logout')}
                >
                  Log Out
                </ProfileLogoutButton>
              </ProfileActionsContainer>
            </Popover.Content>
          </Popover>
        </Overlay>
      </ProfileContainer>
    </>
  );
};

export default AuthenticatedHeader;
