import styled from 'styled-components';
import {
  BLACK_COLOR,
  BLUE_COLOR,
  DARK_BLUE_COLOR,
  GREY_COLOR,
  LIGHT_GREY_COLOR,
  WARNING_INPUT,
  WHITE_COLOR
} from '../../consts/theme';
import Button from '../../components/Button';
import Input from '../../components/Input';

export const OgrCreationStepContainer = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  max-width: 840px;
  padding: 0 20px;
  width: 100%;
`;

export const OrgCreationStepTitle = styled.h2`
  font-family: 'TTHoves-DemiBold', serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${BLACK_COLOR};
  margin: 0;
`;

export const OrganizationContainer = styled.section`
  position: relative;
  margin-top: 100px;
  min-height: 600px;
  background: ${WHITE_COLOR};
  box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  border-radius: 10px;
  margin-bottom: 300px;
  padding: 0 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
`;

export const OrganizationBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NavActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const LoadingBtnContainer = styled.div`
  position: relative;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  gap: 10px;
  background: transparent;
  color: grey;
  font-size: 16px;
  font-family: 'TTHoves-DemiBold', serif;
  padding: 10px 22px;
  transition: 0.2s ease-in-out;
  cursor: default;
  border-radius: 10px;

  .spinner-border {
    border: 0.25em solid #3a86ff;
    border-right-color: transparent;
  }
`;

export const StyledButton = styled(Button)<{
  isTransparent?: boolean;
  isDanger?: boolean;
}>`
  width: fit-content;
  gap: 10px;

  ${(props) => props.isTransparent &&
          `
    border: none;
    background: transparent;
    color: ${BLUE_COLOR};
    :hover {
      background: transparent;
      color: ${DARK_BLUE_COLOR};
    }
    :disabled {
      background: transparent;
      color: ${BLUE_COLOR}4A;
    }
  `}
`;

export const NewOrgInputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
`;

export const OrganizationInput = styled(Input)<{ displayWarning?: boolean }>`
  ${(props) => props.displayWarning && `
      padding: 0 20px 10px 20px;
      border: 1px solid ${WARNING_INPUT};
   `}
  padding: 0 20px;
`;

export const WarningMessage = styled.span`
  color ${WARNING_INPUT};
  position: absolute;
  z-index: 1;
  left: 20px;
  right: 0;
  bottom: 0;
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TeamIcon = styled.div<{ color: string }>`
  position: relative;
  border-radius: 12px;
  height: 48px;
  width: 48px;
  box-shadow: inset 0 -2px rgba(0, 0, 0, .05);
  align-items: center;
  background-color: ${(props) => props.color};
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: flex;
  fill: #fff;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
`;

export const ImageBtn = styled.img`
  cursor: pointer;
`;

export const EmailsInputArea = styled.div<{ displayWarning: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  background: #ffffff;
  padding: 10px;
  outline: none;
  width: 100%;
  border: ${(props) => props.displayWarning ? `1px solid ${WARNING_INPUT}` : 'none'};
  border-radius: 10px;
  transition: all 0.2s;
  cursor: text;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  box-shadow: 0 8px 20px 4px rgb(79 79 82 / 10%);

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    width: 100%;
    
    li {
      list-style: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    div > span {
      display: inline-block;
      vertical-align: top;
      position: absolute;
      visibility: hidden;
      white-space: nowrap;
    }
  }
`;

export const DisplayedEmail = styled.li<{ isValid: boolean }>`
  cursor: default;
  padding: 5px 10px;
  background: ${(props) => (props.isValid ? LIGHT_GREY_COLOR : WARNING_INPUT)};
  border-radius: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;

  & > span {
    cursor: default;
    max-width: 700px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const Cross = styled.img`
  cursor: pointer;
  width: 15px;
  height: 15px;
`;

export const TeamMatesInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const EmailInput = styled.input`
  padding: 0.5rem;
  outline: none;
  border: none;
  width: 100%;
  background: transparent;
  font-size: 17px;
  color: black;
  margin: 0;

  &:active {
    pointer-events: none;
  }
`;

export const EmailHiddenSpan = styled.span<{ maxWidth?: number }>`
  ${(props) => props.maxWidth && `
        max-width: ${props.maxWidth}px;
    `}
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TeamMatesInputLabel = styled.h4<{ optional?: boolean }>`
  font-family: 'TTHoves-DemiBold', serif;
  font-size: 23px;
  line-height: 48px;
  text-align: left;
  margin: 0;
  ${(props) => props.optional
    ? `
        font-weight: 500;
        color: ${GREY_COLOR};
    `
    : `
        font-weight: 700;
        color: ${BLACK_COLOR};
    `}
`;

export const TeamMatesComment = styled.textarea`
  padding: 15px;
  box-shadow: 0 8px 20px 4px rgb(79 79 82 / 10%);
  border: none;
  width: 100%;
  height: 200px;
  font-size: 17px;

  ::placeholder {
    color: #9b9ba1;
  }

  :focus {
    outline: none;
  }
`;

export const AssignTeamMatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 100%;
`;

export const TeamMatesList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
`;

export const RowWrapper = styled.div`
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
`;

export const AssignTeamMateRow = styled.div<{ isBlocked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  ${props => props.isBlocked && `
    pointer-events: none;
  `}

  @media (max-width: 900px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const UserAvatar = styled.span<{ color: string }>`
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  text-transform: uppercase;
  color: ${WHITE_COLOR};
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
`;

export const TeamMateInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 0;
  cursor: default;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    min-width: 0;
  }

  & > span:nth-child(1) {
    font-size: 17px;
    font-weight: 700;
    color: ${BLACK_COLOR};
  }

  & > span:nth-child(2) {
    font-size: 15px;
    color: ${GREY_COLOR};
  }
`;

export const EmailWarningMessage = styled.span`
  color ${WARNING_INPUT};
  font-size: 0.9rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MultipleEmailActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 100%;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  @media (max-width: 850px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media (min-width: 850px) {
    min-width: 350px;
  }
`;

export const TeamMateActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: default;
  background: rgb(251 251 251 / 89%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;