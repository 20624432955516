/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { WorkspaceSelect } from './style';
import { AccountSettings, IdNameDto } from '../../gen/src/ahauOpenAPI';
import { WebApi } from '../../api/WebApi';
import { selectAccessToken } from '../../utils/appReducer';

interface AsanaWorkspaceSectionProps {
  accountId?: number;
  onWorkspaceSelected: (isDisabled: boolean) => void;
}

export const AsanaWorkspaceSection: React.FC<AsanaWorkspaceSectionProps> = (
  props
) => {
  const accessToke = useSelector(selectAccessToken);
  const [workspaceList, setWorkspaceList] = React.useState<IdNameDto[]>([]);

  React.useEffect(() => {
    props.onWorkspaceSelected(!!props.accountId);
    if (props.accountId) {
      WebApi(accessToke)
        .settingsGetFormData(props.accountId)
        .then((formData) => {
          formData?.workspaces && setWorkspaceList(formData.workspaces);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          push('/error');
        });
    }
  }, [props.accountId]);

  const onChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!props.accountId) return;
    const accountSettings = new AccountSettings({
      workspaceId: event.target.value
    });
    await WebApi(accessToke)
      .settingsUpdate(props.accountId, accountSettings)
      .then(() => props.onWorkspaceSelected(false));
  };

  if (!props.accountId) {
    return null;
  }

  return (
    <WorkspaceSelect defaultValue="0" onChange={onChange}>
      <option value="0" disabled>
        Select workspace
      </option>
      {workspaceList.map((workspace) => (
        <option key={workspace.id} value={workspace.id}>
          {workspace.name}
        </option>
      ))}
    </WorkspaceSelect>
  );
};
