/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { RootState } from '../rootReducer';
import { AppThunk } from '../store';
import { getApiClient } from '../api';
import {
  InviteInputDto,
  MemberDto,
  MembershipsRoles,
  OrganizationDetailsDto,
  OrganizationDto,
  OrganizationInputDto,
  OrganizationInvite,
  TeamDto,
  TeamInputDto
} from '../../gen/src/ahauOpenAPI';
import { setError } from '../appReducer';
import { ERROR_MESSAGE } from '../../consts';
import { getConfig } from '../../config';
import { Routes } from '../../consts/routes';
import mixpanelService, { EventName } from '../../services/MixpanelService';

const { clientApiUrl } = getConfig();

export interface OrganizationsState {
  organizations: OrganizationDto[];
  organization: OrganizationDetailsDto;
}

const initialState: OrganizationsState = {
  organizations: [],
  organization: new OrganizationDetailsDto(),
};

export const organizationsSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<OrganizationDto[]>) => {
      state.organizations = action.payload ?? [];
    },
    setOrganization: (state, action: PayloadAction<OrganizationDetailsDto>) => {
      state.organization = action.payload;
    }
  }
});

const {
  setOrganizations,
  setOrganization,
} = organizationsSlice.actions;

export const fetchCreateOrganization = (name: string): AppThunk => async (
  dispatch,
  getState
) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    const org = await api.membershipsCreateOrganization(new OrganizationInputDto({ name }));
    dispatch(push(Routes.teamsOrganization?.getPath!(org.id)));
  } catch (e) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchOrganizations = (): AppThunk => async (
  dispatch,
  getState
) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    const orgs = await api.membershipsGetOrganizations();
    dispatch(setOrganizations(orgs));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchOrganizationDetails = (id: number): AppThunk => async (
  dispatch,
  getState
) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);
  try {
    const org = await api.membershipsGetOrganizationDetails(id);
    dispatch(setOrganization(org));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchDeleteOrganization = (orgId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    mixpanelService.sendEvent(EventName.ORGANIZATION, 'Remove');
    const orgs = await api.membershipsDeleteOrganization(orgId);
    dispatch(setOrganizations(orgs));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchAddOrganizationTeams = (
  orgId: number,
  newTeamData: TeamInputDto,
  addTeamToMember?: (team: TeamDto) => void
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    const newTeam = await api.membershipsCreateTeam(orgId, newTeamData);
    addTeamToMember ? (
      addTeamToMember(newTeam)
    ) : (
      dispatch(fetchOrganizationDetails(orgId))
    );
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchUpdateOrganizationTeam = (
  orgId: number,
  teamId: number,
  updateData: TeamInputDto
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    await api.membershipsUpdateTeam(orgId, teamId, updateData);
    dispatch(fetchOrganizationDetails(orgId));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchDeleteOrganizationTeam = (
  team: TeamDto
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);
  try {
    await api.membershipsDeleteTeam(team.organizationId, team.id);
    dispatch(fetchOrganizationDetails(team.organizationId));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchInviteMembers = (
  orgId: number,
  emails: string[],
  message: string
): AppThunk => async (dispatch, _getState) => {
  dispatch(fetchInvitesToOrganization(orgId, emails, message));
  dispatch(push(Routes.membersOrganization.getPath!(orgId)));
};

export const fetchInviteAdditionalMembers = (
  orgId: number,
  emails: string[],
  message: string
): AppThunk => async (dispatch, _getState) => {
  dispatch(fetchInvitesToOrganization(orgId, emails, message));
  dispatch(fetchOrganizationDetails(orgId));
};

const fetchInvitesToOrganization = (
  orgId: number,
  emails: string[],
  message: string
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    const invites = emails.map(
      e => new InviteInputDto({ email: e, role: MembershipsRoles.Member })
    );
    const response = await api.membershipsInvitesToOrganization(orgId, invites);
    const emailToTokenList = response.map(invite => ({
      email: invite.email,
      token: invite.id
    }));

    await fetch(`${clientApiUrl}api/invite`, {
      method: 'POST',
      // mode: 'no-cors', // for local development
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        organization: getState()?.organizations?.organization?.organization?.name ?? '',
        invites: emailToTokenList,
        message,
        token: accessToken
      })
    });
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchApproveInvite = (
  token: string
): AppThunk => (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);
  try {
    api.membershipsAcceptInviteToOrganization(token)
      .then(() => {
        dispatch(fetchOrganizations());
        dispatch(push('/'));
      })
      .catch(() => dispatch(push('/error')));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchUpdateInvite = (
  orgId: number,
  inviteId: string,
  invite: InviteInputDto
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    await api.membershipsUpdateInviteToOrganization(orgId, inviteId, invite);
    dispatch(fetchOrganizationDetails(orgId));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchDeleteInvite = (
  invite: OrganizationInvite
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    await api.membershipsDeleteInviteToOrganization(invite.id);
    dispatch(fetchOrganizationDetails(invite.organizationId));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchUpdateMember = (
  orgId: number,
  member: MemberDto
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    await api.membershipsUpdateOrganizationMembers(orgId, member);
    dispatch(fetchOrganizationDetails(orgId));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const fetchDeleteMember = (
  member: MemberDto
): AppThunk => async (dispatch, getState) => {
  const { accessToken } = getState().appReducer;
  const api = getApiClient(accessToken);

  try {
    const orgId = member.organizationId;
    await api.membershipsDeleteOrganizationMembers(orgId, member);
    dispatch(fetchOrganizationDetails(orgId));
  } catch (error) {
    dispatch(push('/error'));
    dispatch(
      setError({
        type: 'FetchFailed',
        text: ERROR_MESSAGE
      })
    );
  }
};

export const selectOrganizations = (state: RootState) => state.organizations;

export default organizationsSlice.reducer;
