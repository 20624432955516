/* eslint-disable no-else-return */
/* eslint-disable import/no-cycle */
import React from 'react';
import { WorkspacesFilter } from './Workspaces/WorkspacesFilter';
import { DataContainer } from './DataPanel/DataContainer';
import { SearchBoxContainer } from './SearchBox/SearchBoxContainer';

const Search: React.FC = () => {

  return (
    <div>
      <SearchBoxContainer />
      <WorkspacesFilter />
      <DataContainer />
    </div>
  );
};

export default Search;
