/* eslint-disable no-console, no-control-regex*/
export let isTempStorage = false;
export let isStorageAccessDenied = false;

let tmp_storage = {};
const p = '__unique_a260__';
const tempStorage = {
  setItem: (k: any, v: any) => {
    // @ts-ignore
    tmp_storage[p + k] = v;
  },
  getItem: (k: any) => {
    // @ts-ignore
    return tmp_storage[p + k] === undefined ? null : tmp_storage[p + k];
  },
  removeItem: (k: any) => {
    // @ts-ignore
    delete tmp_storage[p + k];
  },
  clear: () => {
    tmp_storage = {};
  }
};

let localStorage: any = tempStorage;
let sessionStorage: any = tempStorage;
// Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
// throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
// to avoid the entire page breaking, without having to do a check at each usage of Storage.

try {
  localStorage = window.localStorage;
  sessionStorage = window.sessionStorage;
} catch (e) {
  isStorageAccessDenied = true;

  //+ 'the most common cause of this is using "Private Browsing Mode". Some settings '
  //+ 'may not save or some features may not work properly for you.');
  console.error('storage access denied');
}


try {
  if (!isStorageAccessDenied) {
    if (typeof localStorage === 'object') {
      localStorage.setItem('_storage_test', 'test');
      localStorage.removeItem('_storage_test');
    }
  }
} catch (e) {

  isTempStorage = true;

  //+ 'the most common cause of this is using "Private Browsing Mode". Some settings '
  //+ 'may not save or some features may not work properly for you.');
  console.debug('temp storage is used');
  localStorage = tempStorage;
  sessionStorage = tempStorage;
}

export enum SessionStorageKeys {
  ReloadIfErrorOcurred = 'ReloadIfErrorOcurred'
}

export interface AuthSession {
  accessToken?: string
  auth0token?: string
  expiresAt?: Date
  expiresIn?: number
}

class StorageService {

  /** set local storage value. if !value it will be removed */
  copyToLocal = (obj: any) => {
    const objs = obj as Array<{ key: any, value: any }>;

    for (let i = 0; i < objs.length; i++) {
      const item = objs[i];
      if (item) {
        localStorage.setItem(item.key, item.value);
      }
    }

    console.debug('copy local storage');
  };

  /** set session storage value. if !value it will be removed */
  setSession = (key: SessionStorageKeys, value: any) => {
    if (!value) {
      sessionStorage.removeItem(key);
      return;
    }
    sessionStorage.setItem(key, value ? JSON.stringify(value) : '');
  };

  getSession = <T>(key: SessionStorageKeys): T | undefined => {
    try {
      const state = sessionStorage.getItem(key);
      return state ? JSON.parse(state) : undefined;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  setAuthSession = (authResult: AuthSession) => {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(authResult.expiresAt);
    localStorage.setItem('access_token', authResult.accessToken!);
    localStorage.setItem('auth0token', authResult.auth0token!);
    localStorage.setItem('expires_at', expiresAt);

    console.debug(`Auth session stored${isTempStorage ? ' to the temp storage' : ''}.`);
  };

  clearAuthSession = () => {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('auth0token');
    localStorage.removeItem('expires_at');

    console.debug('Auth session cleared');
  };

  getAuthSession = (): | {
    accessToken: string
    auth0token?: string
    expiresAt: string
    expired: boolean
  } | undefined => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) return undefined;
    const expiresAt = localStorage.getItem('expires_at') || '';
    let expired = true;
    // Check whether the current time is past the
    // Access Token's expiry time

    try {
      // must parse to Date
      const expiresAtDate = Date.parse(JSON.parse(expiresAt));
      expired = new Date().getTime() > expiresAtDate;

      if (expired) {
        console.debug('stored token is expired');
        localStorage.removeItem('access_token');
      }
    } catch (e) {
      console.error(e);
    }

    return {
      accessToken,
      expiresAt,
      expired,
      auth0token: localStorage.getItem('auth0token') || undefined
    };
  };
}

export const storage = () => new StorageService();
