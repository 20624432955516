import React from 'react';
import { SearchButton } from './SearchButton';
import { SearchItemFilters } from './SearchItemsFilter';
import { panelStyle } from './style';
import { ItemType } from '../../../../gen/src/ahauOpenAPI';

interface PanelProps {
  isFocused: boolean;
  isSearching: boolean;
  onButtonClick: () => void;
  setFilter: React.Dispatch<React.SetStateAction<ItemType>>;
}
const Panel: React.FC<PanelProps> = ({
  setFilter,
  onButtonClick,
  isFocused,
  isSearching,
}) => {
  return (
    <div style={panelStyle}>
      <SearchItemFilters setFilter={setFilter} />
      {isFocused && isSearching && (
        <SearchButton onButtonClick={onButtonClick} />
      )}
    </div>
  );
};

export default Panel;
