/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SetupOrgSteps } from '../../../consts';
import { StyledButton } from '../style';
import TeamItemRow from './TeamItemRow';
import {
  fetchAddOrganizationTeams,
  fetchOrganizationDetails,
  selectOrganizations
} from '../../../utils/reducers/organizations';
import OrgInputWrapper from '../OrgInputWrapper';
import { AddNewTeamSection, AddTeamInput, TeamsContainer } from './style';
import { Routes } from '../../../consts/routes';
import { NEW_ORG_TEAM_COLORS } from '../../../consts/theme';
import { selectAppState } from '../../../utils/appReducer';
import { TeamInputDto } from '../../../gen/src/ahauOpenAPI';
import OrganizationSection from '../OrganizationSection';
import mixpanelService, { EventName } from '../../../services/MixpanelService';

enum LoadingType {
  PAGE_LOADING,
  TEAM_CREATION
}

interface IContentLoading {
  isLoading: boolean;
  loadingType: LoadingType;
}

export interface AddOrganizationTeamsProps {
  id: string
}

const CreateTeams: React.FC<AddOrganizationTeamsProps> = (props) => {
  const { id } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [newTeamName, setNewTeamName] = useState<string>('');
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<IContentLoading | null>(null);

  const { organization } = useSelector(selectOrganizations);
  const { initialized } = useSelector(selectAppState);

  React.useEffect(() => {
    if (initialized && id) {
      setLoadingState({ isLoading: true, loadingType: LoadingType.PAGE_LOADING });
      dispatch(fetchOrganizationDetails(Number(id)));
    }
  }, [id, initialized]);

  useEffect(() => {
    loadingState?.isLoading && setLoadingState(null);
  }, [organization?.teams]);

  const createNewTeam = useCallback(() => {
    if (loadingState?.isLoading) return;
    const teamName = newTeamName.trim();
    if (!teamName) {
      setWarningMessage('Organization name can\'t be empty');
      return;
    }
    if (organization?.teams?.find(
      item => item.name === teamName
    )) {
      setWarningMessage('Such Team already exists.');
      return;
    }

    mixpanelService.sendEvent(EventName.TEAM, 'New');
    setLoadingState({ isLoading: true, loadingType: LoadingType.TEAM_CREATION });
    dispatch(fetchAddOrganizationTeams(
      Number(id),
      new TeamInputDto(
        {
          name: teamName,
          color: NEW_ORG_TEAM_COLORS[(organization?.teams?.length || 1) % 10]
        }
      )
    ));
    setNewTeamName('');
  }, [newTeamName, organization]);

  return (
    <OrganizationSection
      currentStep={SetupOrgSteps.ADD_TEAMS}
      isLoading={loadingState?.isLoading && loadingState.loadingType === LoadingType.PAGE_LOADING}
      title="Add Teams to your Organization"
      orgActions={(
        <>
          <StyledButton
            disabled={!organization?.teams?.length}
            onClick={() => history.push(Routes.inviteOrganization.getPath!(id))}
          >
            Continue
          </StyledButton>
          <Link to={Routes.inviteOrganization.getPath!(id)}>
            <StyledButton isTransparent>Skip</StyledButton>
          </Link>
        </>
      )}
    >
      <TeamsContainer>
        <AddNewTeamSection displayWarning={!!warningMessage}>
          <OrgInputWrapper warningText={warningMessage}>
            <AddTeamInput
              type="text"
              autoFocus={true}
              value={newTeamName}
              onChange={(e) => {
                setWarningMessage(null);
                setNewTeamName(e.target.value);
              }}
              placeholder="Enter your team name"
              alt="Add new team to organization"
              onKeyUp={(event) => event.key === 'Enter' && createNewTeam()}
            />
          </OrgInputWrapper>

          <StyledButton
            disabled={(
              loadingState?.isLoading &&
              loadingState.loadingType === LoadingType.TEAM_CREATION ||
              !newTeamName?.length
            )}
            onClick={createNewTeam}
          >
            {
              loadingState?.isLoading && loadingState.loadingType === LoadingType.TEAM_CREATION ?
                (
                  <>
                    <Spinner as="div" animation="border" role="status" size="sm" color="blue" />
                    <span>Adding...</span>
                  </>
                ) : (
                  <span>Add</span>
                )
            }
          </StyledButton>

        </AddNewTeamSection>
        {
          organization?.teams?.sort(
            (a, b) => (a.id < b.id) ? 1 : -1
          ).map(
            (teamItem, index) => (
              <TeamItemRow
                key={teamItem.id}
                color={NEW_ORG_TEAM_COLORS[index % 10]}
                teamItem={teamItem}
              />
            )
          )
        }
      </TeamsContainer>
    </OrganizationSection>
  );
};

export default CreateTeams;
