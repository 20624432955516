import { IButtonStyles, IDropdownStyles } from "@fluentui/react";
import { COLORS, SIZES } from "../../style";
import { CSSProperties } from "react";

export const dropdownFilterStyle: IButtonStyles = {
    root: {
      backgroundColor: 'transparent',
      color: COLORS.globalGrayColor,
      paddingLeft: '1px',
      height: SIZES.searchBarHeight,
      width: '90px',
      fontSize: SIZES.defaultFontSize,
      float: 'right',
    },
    rootHovered: {
      backgroundColor: COLORS.lightGray,
      color: COLORS.globalGrayColor,
    },
    rootPressed: {
      backgroundColor: COLORS.lightGray,
      color: COLORS.globalGrayColor,
      paddingRight: '0px',
    },
    menuIcon: {
      fontSize: '10px',
      fontWeight: 'bold',
      marginTop: '4px',
    },
  };

  export const panelStyle: CSSProperties = {
    position: 'absolute',
    right: '0',
    top: '0',
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  };

  export const searchButtonStyle: IButtonStyles = {
    root: {
      paddingRight: '6px',
    },
    icon: {
      fontWeight: '900',
    },
  };

  export const searchFilterStyle: Partial<IDropdownStyles> = {
    root: {
      color: COLORS.globalGrayColor,
      paddingLeft: '1px',
      height: SIZES.searchBarHeight,
      width: '110px',
      fontSize: SIZES.defaultFontSize,
      float: 'right',
    },
    title: {
      border: 'none',
      color: COLORS.globalGrayColor,
      backgroundColor: 'transparent',
      float: 'right',
      '&:hover, &:focus, &:active, &:not(:hover)': {
        color: `${COLORS.globalGrayColor} !important`,
      },
    },
    dropdown: {
      border: 'none',
      '&::after': {
        border: 'none !important',
        outline: 'none !important',
      },
    },
    dropdownItem: {
      color: COLORS.globalGrayColor,
      '&:hover, &:focus, &:active': {
        color: `${COLORS.globalGrayColor} !important`,
      },
    },
  };