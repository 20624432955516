/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { memo } from 'react';
import styled from 'styled-components';
import { BLUE_COLOR, DARK_BLUE_COLOR, RED_COLOR, WHITE_COLOR } from '../consts/theme';

const StyledButton = styled.button<{
  isTransparent?: boolean;
  isDanger?: boolean;
}>`
  background: ${BLUE_COLOR};
  color: ${WHITE_COLOR};
  font-family: 'TTHoves-DemiBold';
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border: none;
  padding: 15px 32px;

  box-shadow: 0px 50px 80px rgba(58, 134, 255, 0.1);
  border-radius: 10px;

  :disabled {
    background: ${BLUE_COLOR}4A;
    cursor: not-allowed;
  }

  :hover {
    background: ${(props) =>
    props.disabled ? `${BLUE_COLOR}4A` : DARK_BLUE_COLOR};
  }

  ${(props) =>
    props.isTransparent &&
  `
    background: transparent;
    color: ${BLUE_COLOR};
    :hover {
      background: transparent;
      color: ${DARK_BLUE_COLOR};
    }
    :disabled {
      background: transparent;
      color: ${BLUE_COLOR}4A;
    }
  `}

  ${(props) =>
    props.isDanger &&
  `
    background: ${RED_COLOR}E6;
    color: ${WHITE_COLOR};
    :hover {
      background: ${RED_COLOR};
    }
    :disabled {
      background: transparent;
    }
  `}
`;

interface IButtonProps {
  title: string;
  disabled?: boolean;
  onClick?: (event: any) => void;
  isTransparent?: boolean;
  isDanger?: boolean;
}

const defaultOnClick = () => null;

const Button: React.FC<IButtonProps> = (props) => {
  return (
    <StyledButton
      isTransparent={props.isTransparent}
      disabled={props.disabled}
      onClick={props.onClick || defaultOnClick}
      isDanger={props.isDanger}
    >
      {props.title}
    </StyledButton>
  );
};

export default memo(Button);
