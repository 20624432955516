import React, { memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { IntegrationContainer, IntegrationsRows, IntegrationsSection } from './style';
import { integrations } from './data';
import { Loading } from './Loading';
import mixpanelService, { EventName } from '../../services/MixpanelService';

const IntegrationsList: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {isAuthenticated && (
        <IntegrationsSection>
          <h1>Apps</h1>
          <IntegrationsRows>
            {integrations.map((integration) => {
              const {
                title,
                image: { src, width },
                path
              } = integration;

              return (
                <Link 
                  to={{ pathname: path }} 
                  target="_blank" 
                  key={title}
                  onClick={() => mixpanelService.sendEvent(EventName.NAVIGATION, `Apps - ${title}`)}
                >
                  <IntegrationContainer key={title}>
                    <img
                      src={src}
                      style={{ width: width }}
                      alt={title}
                      title={title}
                    />
                  </IntegrationContainer>
                </Link>
              );
            })}
          </IntegrationsRows>
        </IntegrationsSection>
      )}
    </>
  );
};

export default memo(IntegrationsList);
