import React, { ReactNode, useState } from 'react';
import {
  resultRowContainerStyle,
} from './style';

interface ResultRowProps {
  children: ReactNode;
}

const ResultRow: React.FC<ResultRowProps> = ({children}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={resultRowContainerStyle(isHovered)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default ResultRow;
