import { useSelector } from 'react-redux';
import { selectSearchData } from '../../../../utils/reducers/searchData';
import BookmarkResult from './ResultTypes/BookmarkResult';
import DocumentResult from './ResultTypes/DocumentResult';
import ChannelResult from './ResultTypes/ChannelResult';
import ContactResult from './ResultTypes/ContactResult';
import EmailResult from './ResultTypes/EmailResult';
import TaskResult from './ResultTypes/TaskResult';
import { ItemType } from '../../../../gen/src/ahauOpenAPI';
import React from 'react';

export type Result = {
  label: string;
  onClick: () => void;
  callbacks?: {
    [eventName: string]: () => void;
  };
};

export const ProcessSearchData = (
  query: string | undefined,
  filter: ItemType
) => {
  const { searchData } = useSelector(selectSearchData);
  if (!searchData || !query) return { filteredData: [], leftOverData: [] };

  let filteredData: Array<React.ReactNode> = [];
  let leftOverData: Array<React.ReactNode> = [];

  for (const accountData of searchData) {
    const bookmarks = accountData.bookmarks;
    if (bookmarks && filter === ItemType.NotSet) {
      for (let i = 0; i < bookmarks.length; i++) {
        const result = (
          <BookmarkResult
            label={bookmarks[i].name || ''}
            url={bookmarks[i].originalUrl || ''}
          />
        );
        if (i >= 5) leftOverData.push(result);
        else filteredData.push(result);
      }
    }

    const documents = accountData.documents;
    if (
      documents &&
      (filter === ItemType.NotSet || filter === ItemType.Document)
    ) {
      for (let i = 0; i < documents.length; i++) {
        const result = (
          <DocumentResult
            filename={documents[i].name}
            url={documents[i].originalUrl}
            fileSize={documents[i].fileSize}
            clientId={documents[i].clientId}
            service={documents[i].service}
          />
        );
        if (i >= 5) leftOverData.push(result);
        else filteredData.push(result);
      }
    }

    const channels = accountData.channels;
    if (channels && filter === ItemType.NotSet) {
      for (let i = 0; i < channels.length; i++) {
        const result = (
          <ChannelResult
            name={channels[i].name}
            url={channels[i].originalUrl}
            serviceProvider={channels[i].service}
          />
        );
        if (i >= 5) leftOverData.push(result);
        else filteredData.push(result);
      }
    }
    const contacts = accountData.contacts;
    if (
      contacts &&
      (filter === ItemType.NotSet || filter === ItemType.Contact)
    ) {
      for (let i = 0; i < contacts.length; i++) {
        const result = (
          <ContactResult
            name={contacts[i].name}
            email={contacts[i].email}
            url={contacts[i].originalUrl}
            service={contacts[i].service}
          />
        );
        if (i >= 5) leftOverData.push(result);
        else filteredData.push(result);
      }
    }
    const emails = accountData.mails;
    if (emails && filter === ItemType.NotSet) {
      for (let i = 0; i < emails.length; i++) {
        const result = (
          <EmailResult
            name={emails[i].senderName}
            email={emails[i].senderEmail}
            url={emails[i].originalUrl}
          />
        );
        if (i >= 5) leftOverData.push(result);
        else filteredData.push(result);
      }
    }
    const tasks = accountData.tasks;
    if (tasks && (filter === ItemType.NotSet || filter === ItemType.Task)) {
      for (let i = 0; i < tasks.length; i++) {
        const result = (
          <TaskResult
            name={tasks[i].name}
            fullId={tasks[i].fullId}
            accountIconUrl={tasks[i].accountIconUrl}
            url={tasks[i].originalUrl}
          />
        );
        if (i >= 5) leftOverData.push(result);
        else filteredData.push(result);
      }
    }
  }
  return { filteredData, leftOverData };
};
