import React, { useEffect, useState } from 'react';
import {
  buttonIconStyle,
  centerDivStyle,
  resultPersonaStyle,
  resultRowContentContainerStyle,
  resultRowContentStyle,
  resultRowIconButtonStyle,
  resultRowStyle,
} from '../style';
import { IconButton, Persona } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  linkUnlinkContactToWorkspace,
  selectWorkspacesList,
} from '../../../../../utils/reducers/workspaces';
import { ServiceProvider } from '../../../../../gen/src/ahauOpenAPI';

interface ContactResultProps {
  name: string | undefined;
  email: string | undefined;
  url: string | undefined;
  service: ServiceProvider | undefined;
}

const ContactResult: React.FC<ContactResultProps> = ({
  url,
  name,
  email,
  service,
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { currentWorkspaceData } = useSelector(selectWorkspacesList);

  useEffect(() => {
    if (currentWorkspaceData && currentWorkspaceData.contacts && email) {
      setDisabled(
        currentWorkspaceData.contacts.some(
          (contact) => contact.contact?.email === email
        )
      );
    } else if (!currentWorkspaceData) setDisabled(false);
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(url, '_blank');
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...resultRowStyle,
        ...{
          borderLeft: isHovered ? '2px solid purple' : '2px solid white',
        },
      }}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <div style={resultRowContentContainerStyle}>
        <div style={centerDivStyle}>
          <Persona text={name} coinSize={20} styles={resultPersonaStyle} />
        </div>
        <div style={resultRowContentStyle}>{`${name} (${email})`}</div>
        <div style={{ display: 'flex' }}>
          {email && name && (
            <IconButton
              onClick={(e) => {
                dispatch(
                  linkUnlinkContactToWorkspace(email, name, url, service)
                );
                setDisabled(true);
                e.stopPropagation();
              }}
              disabled={disabled}
              iconProps={{
                iconName: 'AddTo',
                style: buttonIconStyle(disabled),
              }}
              title="Add to Workspace"
              styles={resultRowIconButtonStyle}
            />
          )}
          <IconButton
            iconProps={{
              iconName: 'ContactCard',
              style: { color: 'purple' },
            }}
            title="Open Contact"
            styles={resultRowIconButtonStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactResult;
