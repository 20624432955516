/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthMethods, ConnectionSettingsOutputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { EasyProjectsAuthorizeForm } from './EasyProjectsAuthorizeForm';
import { RedmineAuthorizeForm } from './RedmineAuthorizeForm';
import { JiraAuthorizeForm } from './JiraAuthorizeForm';
import { AsanaAuthorizeForm } from './AsanaAuthorizeForm';
import { Container } from '../../components/Container';
import { SimpleForm } from '../../components/Forms';
import { Loading } from '../Profile/Loading';
import { isProviderWithOauth } from '../../helpers/connectionHelper';

export interface IConnectionCredentials {
  userName?: string;
  password?: string;
  apiKey?: string;
}

const FormContainer = styled.section`
  max-width: 450px;
  padding: 15px;
  margin: 0 auto;
`;

interface IConnectionAuthorizeFormProps {
  editData?: ConnectionSettingsOutputDto;
  isCreationMode?: boolean;
  accountId?: number;
  serviceProvider: ServiceProvider;
  serviceProviderName?: string;
  isLoading?: boolean;
  isVerifyingWebroot?: boolean;

  webRoot?: string;
  userName?: string;
  password?: string;
  passwordLength?: number;
  apiKey?: string;

  verifyErrorMessage?: unknown;
  errorOAuth?: unknown;

  showPasswordCredentialsForm?: boolean;
  showAuthorizeOAuthForm?: boolean;
  isAuthorizing?: boolean;
  customOAuthButtonText?: string;

  isFullscreenMode?: boolean;

  onChange?: (arg: {}) => void;
  onChangeAddress?: () => void;
  connectCredentials?: (connectionSettings: IConnectionCredentials) => void;
  connectOAuth?: () => void;
  changeAuthMethod?: (authMethod: AuthMethods) => void;
}

export const ConnectionAuthorizeForm: React.FC<IConnectionAuthorizeFormProps> = (
  props
) => {
  const [fields, setFields] = useState<IConnectionCredentials | undefined>({
    userName: props.editData?.userName || '',
    password: '',
    apiKey: ''
  });

  React.useEffect(() => {
    setFields({
      userName: props.editData?.userName || ''
    });
  }, [props.editData?.userName]);

  if (props.isLoading) return <Loading />;

  return (
    <>
      <Container>
        <FormContainer className="form-setupconnection">
          <SimpleForm>
            {props.serviceProvider === ServiceProvider.EasyProjects && (
              <>
                <EasyProjectsAuthorizeForm
                  {...props}
                  verifyErrorMessage={props.verifyErrorMessage}
                  userName={fields?.userName}
                  password={fields?.password}
                  passwordLength={props.editData?.passwordLength}
                  serviceProvider={props.serviceProvider}
                  onChange={(args) => setFields(args)}
                  connectCredentials={() => {
                    const connectionSettings = {
                      userName: fields?.userName,
                      password: fields?.password
                    };

                    props.connectCredentials &&
                    props.connectCredentials(connectionSettings);
                  }}
                />
              </>
            )}

            {props.serviceProvider === ServiceProvider.Redmine && (
              <RedmineAuthorizeForm
                {...props}
                verifyErrorMessage={props.verifyErrorMessage}
                userName={fields?.userName}
                password={fields?.password}
                serviceProvider={props.serviceProvider}
                authMethod={
                  props.editData?.authMethod
                    ? props.editData?.authMethod
                    : AuthMethods.Password
                }
                onChange={(args) => setFields(args)}
                connectCredentials={() => {
                  const connectionSettings = {
                    userName: fields?.userName,
                    password: fields?.password,
                    apiKey: fields?.apiKey
                  };

                  props.connectCredentials &&
                  props.connectCredentials(connectionSettings);
                }}
              />
            )}

            {isProviderWithOauth(props.serviceProvider) && (
              <>
                <AsanaAuthorizeForm
                  {...props}
                  serviceProvider={props.serviceProvider}
                  onChange={(args) => setFields(args)}
                />
              </>
            )}

            {props.serviceProvider === ServiceProvider.Jira && (
              <JiraAuthorizeForm
                {...props}
                userName={fields?.userName}
                password={fields?.password}
                serviceProvider={props.serviceProvider}
                onChange={(args) => setFields(args)}
                connectCredentials={() => {
                  const connectionSettings = {
                    userName: fields?.userName,
                    password: fields?.password,
                    apiKey: fields?.apiKey
                  };

                  props.connectCredentials &&
                  props.connectCredentials(connectionSettings);
                }}
              />
            )}
          </SimpleForm>
        </FormContainer>
      </Container>
    </>
  );
};
