import * as React from "react";
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  root: { color: 'red' },
});

export const Panel = ({ children }: React.PropsWithChildren<{}>) =>  {

  return (<div className="borderRadiusXLarge">{children}</div>);
};
