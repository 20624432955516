/* eslint-disable import/no-cycle */
import GetSupport from '../../images/GetSupport.svg';
import KnowledgeBase from '../../images/KnowledgeBase.svg';
import RequestDemo from '../../images/RequestDemo.svg';
import { IQuestion } from '../../components/Question';
import { COMPANY_KB, GET_SUPPORT, REQUEST_DEMO } from '../../utils/constants';

export const question1: IQuestion = {
  image: GetSupport,
  buttonText: 'Get Support',
  id: 1,
  redirectDestination: GET_SUPPORT
};

export const question2: IQuestion = {
  image: KnowledgeBase,
  buttonText: 'Knowledge Base',
  id: 2,
  redirectDestination: COMPANY_KB
};

export const question3: IQuestion = {
  image: RequestDemo,
  buttonText: 'Request a Demo',
  id: 3,
  redirectDestination: REQUEST_DEMO
};

export const questions: Array<IQuestion> = [question1, question2, question3];
export const profileQuestions: Array<IQuestion> = [question1, question2];
