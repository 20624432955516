import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  fetchWorkspacePaneData,
  selectWorkspacesList,
} from '../../../utils/reducers/workspaces';
import { WorkspaceButton } from './WorkspaceButton';
import { CreateWorkspaceButton } from './CreateWorkspaceButton';
import { workspacesContainerStyle } from './style';

export const WorkspacesFilter: React.FC = () => {
  const { workspaces } = useSelector(selectWorkspacesList);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const workspaceId = pathParts.length > 3 ? pathParts.pop() : null;

  // Fetch data for the selected workspace on component mount
  React.useEffect(() => {
    if (workspaceId) {
      dispatch(fetchWorkspacePaneData(workspaceId));
    }
  }, [dispatch, workspaceId]);

  return (
    <div style={workspacesContainerStyle}>
      {workspaces.map((workspace, index) => (
        <WorkspaceButton
          key={workspace.id}
          onButtonClick={() => {
            dispatch(fetchWorkspacePaneData(workspace.id));
            history.push(`/search/workspace/${workspace.id}`);
          }}
          label={workspace.name ? workspace.name : 'Undefined'}
        />
      ))}
      <CreateWorkspaceButton onButtonClick={() => {}} />
    </div>
  );
};
