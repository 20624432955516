import { CSSProperties } from 'react';
import { COLORS } from '../style';
import { IButtonStyles, ISearchBoxStyles } from '@fluentui/react';

export const searchAreaStyle: CSSProperties = {
  width: '100%',
  position: 'relative',
};
export const absoluteContainerStyle: CSSProperties = {
  position: 'absolute',
  width: '100%',
  maxWidth: '800px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  margin: '0 auto',
  marginTop: '40px',
};

export const searchContainerStyle = (
  isHovered: boolean,
  isFocused: boolean
): CSSProperties => ({
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: isHovered
    ? '-4px 6px 12px rgba(0, 86, 179, 0.4)'
    : '-4px 6px 12px rgba(144,144,144,0.4)',
  transition: 'box-shadow 0.1s ease-in-out',
  backgroundColor: isFocused ? COLORS.lightGray : 'white',
});

export const searchBoxStyle = (
  isFocused: boolean
): Partial<ISearchBoxStyles> => ({
  root: {
    width: '100%',
    border: 'none',
    '&::after': {
      border: 'none !important',
      outline: 'none !important',
    },
    paddingRight: '142px',
    backgroundColor: isFocused ? COLORS.lightGray : 'white',
  },
  icon: {
    display: 'none',
  },
  field: {
    '&::placeholder': {
      color: COLORS.averageGray,
    },
  },
  clearButton: {
    display: 'none',
  },
});

export const iconButtonStyle: IButtonStyles = {
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  icon: {
    fontWeight: 'bold',
  },
};

export const backButtonStyle: IButtonStyles = {
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  icon: {
    fontWeight: 'bold',
    color: 'gray',
  },
};
