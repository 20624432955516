import {
  Bookmark24Regular,
  Channel24Regular,
  ClipboardTask24Regular,
  ContactCard24Regular,
  LinkDismiss24Regular,
  Mail24Regular,
  Open24Regular,
} from '@fluentui/react-icons';
import { DataGridItem } from './DataGridColumns';
import { useSelector, useDispatch } from 'react-redux';
import {
  linkUnlinkContactToWorkspace,
  linkUnlinkDocumentToWorkspace,
  linkUnlinkTaskToWorkspace,
  selectWorkspacesList,
} from '../../../utils/reducers/workspaces';
import { ItemType } from '../../../gen/src/ahauOpenAPI';
import { Icon } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

export const ProcessData = (filter?: ItemType) => {
  const { currentWorkspaceData } = useSelector(selectWorkspacesList);
  const dispatch = useDispatch();
  if (!currentWorkspaceData) return [];
  const workspaceId = currentWorkspaceData.workspace?.id || 'undefined';
  let filteredData: Array<DataGridItem> = [];

  const bookmarks = currentWorkspaceData.bookmarks;
  if (bookmarks && filter === ItemType.NotSet) {
    for (const bookmark of bookmarks) {
      filteredData.push({
        key: `${bookmark.bookmark?.title}_${bookmark.bookmark?.id}_${workspaceId}`,
        label: bookmark.bookmark?.title || 'undefined',
        type: 'bookmark',
        icon: <Bookmark24Regular />,
        buttons: [          
          {
            icon: <Open24Regular />,
            onClick: () => window.open(bookmark.originalUrl, '_blank'),
            tooltip: "Open Bookmark"
          },
        ],
      });
    }
  }

  const documents = currentWorkspaceData.documents;
  if (
    documents &&
    (filter === ItemType.NotSet || filter === ItemType.Document)
  ) {
    for (const document of documents) {
      const filename = document.document?.fileName;
      const path = document.document?.path;
      const fileExtension = filename
        ? filename.split('.').pop()?.toLowerCase()
        : '';
      filteredData.push({
        key: `${document.document?.fileName}_${document.document?.id}_${workspaceId}`,
        type: fileExtension,
        icon: (
          <Icon
            {...getFileTypeIconProps({
              extension: fileExtension || '',
              size: 24,
              imageFileType: 'svg',
            })}
          />
        ),
        label: filename || 'undefined',
        buttons: [
          ...(filename
            ? [
                {
                  icon: <LinkDismiss24Regular />,
                  onClick: () =>
                    dispatch(
                      linkUnlinkDocumentToWorkspace(
                        path,
                        document.externalKey,
                        filename,
                        document.document?.fileSize,
                        document.serviceProvider,
                        true
                      )
                    ),
                  oneTime: true,
                  tooltip: "Remove Document from Workspace"
                },
              ]
            : []),
          {
            icon: <Open24Regular />,
            onClick: () => window.open(path, '_blank'),
            tooltip: "Open Document"
          },
        ],
      });
    }
  }

  const channels = currentWorkspaceData.channels;
  if (channels && filter === ItemType.NotSet) {
    for (const channel of channels) {
      filteredData.push({
        key: `${channel.channel?.name}_${channel.channel?.id}_${workspaceId}`,
        type: 'channel',
        icon: <Channel24Regular />,
        label: channel.channel?.name || 'undefined',
        buttons: [          
          {
            icon: <Open24Regular />,
            onClick: () => window.open(channel.originalUrl, '_blank'),
            tooltip: "Open Channel"
          },
        ],
      });
    }
  }
  const contacts = currentWorkspaceData.contacts;
  if (contacts && (filter === ItemType.NotSet || filter === ItemType.Contact)) {
    for (const contact of contacts) {
      const email = contact.contact?.email;
      const name = contact.contact?.name;
      const url = contact.originalUrl;
      filteredData.push({
        key: `${contact.contact?.name}_${contact.contact?.id}_${workspaceId}`,
        type: 'contact',
        icon: <ContactCard24Regular />,
        label: name || 'undefined',
        buttons: [
          ...(email && name
            ? [
                {
                  icon: <LinkDismiss24Regular />,
                  onClick: () =>
                    dispatch(
                      linkUnlinkContactToWorkspace(
                        email,
                        name,
                        url,
                        contact.serviceProvider,
                        true
                      )
                    ),
                  oneTime: true,
                  tooltip: "Remove Contact from Workspace"
                },
              ]
            : []),
          {
            icon: <Open24Regular />,
            onClick: () => window.open(url, '_blank'),
            tooltip: "Open Contact"
          },
        ],
      });
    }
  }
  const emails = currentWorkspaceData.emails;
  if (emails && filter === ItemType.NotSet) {
    for (const email of emails) {
      filteredData.push({
        key: `${email.email?.senderEmail}_${email.email?.id}_${workspaceId}`,
        type: 'email',

        icon: <Mail24Regular />,
        label: email.email?.senderName || 'undefined',
        buttons: [          
          {
            icon: <Open24Regular />,
            onClick: () => window.open(email.originalUrl, '_blank'),
            tooltip: "Open Email"
          },
        ],
      });
    }
  }
  const tasks = currentWorkspaceData.tasks;
  if (tasks && (filter === ItemType.NotSet || filter === ItemType.Task)) {
    for (const task of tasks) {
      const fullId = task.activityId;
      filteredData.push({
        key: `${task.activity?.name}_${task.activity?.id}_${workspaceId}`,
        type: 'task',
        icon: <ClipboardTask24Regular />,
        label: task.activity?.name || 'undefined',
        buttons: [
          ...(fullId
            ? [
                {
                  icon: <LinkDismiss24Regular />,
                  onClick: () =>
                    dispatch(linkUnlinkTaskToWorkspace(fullId, true)),
                  oneTime: true,
                  tooltip: "Remove Task from Workspace"
                },
              ]
            : []),
          {
            icon: <Open24Regular />,
            onClick: () => window.open(task.activity?.originalUrl, '_blank'),
            tooltip: "Open Task"
          },
        ],
      });
    }
  }

  return filteredData;
};
