import styled from 'styled-components';
import { NEW_ORG_TEAM_HOVER_COLOR, WARNING_INPUT, WHITE_COLOR } from '../../../consts/theme';
import { OrganizationInput } from '../style';

export const TeamsContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const AddNewTeamSection = styled.section<{ displayWarning?: boolean }>`
  position: relative;
  display: flex;
  padding-right: 5px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-shadow: 0 8px 20px 4px rgb(79 79 82 / 10%);
  margin-bottom: 50px;
  border-radius: 10px;

  ${(props) => props.displayWarning && `
      border: 1px solid ${WARNING_INPUT};
   `}
`;

export const AddTeamInput = styled(OrganizationInput)`
  box-shadow: none;
  width: 100%;
  margin: 0;
`;

export const OrgListItem = styled(AddNewTeamSection)<{ isEditMode: boolean, displayWarning?: boolean, isLoading?: boolean }>`
  border-bottom: 1px solid #edeae9;
  width: 100%;
  padding: 5px;
  box-shadow: none;
  margin: 0;
  position: relative;

  ${(props) => props.displayWarning && `border: 1px solid ${WARNING_INPUT};`}
  ${(props) => props.isLoading && `
    opacity: 0.7;
    pointer-event: none;
    
    & > div:nth-child(3) {
      display: flex;
    }
    
    & > span {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 20px;
    }
  `}

  ${(props) => props.isEditMode && `
    background-color: ${WHITE_COLOR};
    box-shadow: 0px 8px 20px 4px rgb(79 79 82 / 10%);
  `}

  ${(props) => !props.isEditMode && !props.isLoading && `
    & > span {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 20px;
    }
      
    &:hover {
      background-color: ${NEW_ORG_TEAM_HOVER_COLOR};
    }
    
    & > div:nth-child(3) {
      display: none;
    }
    &:hover > div:nth-child(3) {
      display: flex;
    }
  `}
`;

export const TeamRowActionContainer = styled.div`
  display: flex;
  gap: 10px;
`;