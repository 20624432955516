import { IChoiceGroupOptionStyles } from '@fluentui/react';
import { ServiceProvider } from '../../gen/src/ahauOpenAPI';
import {
  ADOBE_DOWNLOAD_PAGE,
  CHROME_DOWNLOAD_PAGE,
  EASY_PROJECTS_LINK,
  EDGE_DOWNLOAD_PAGE,
  EXCEL_DOWNLOAD_PAGE,
  FIREFOX_DOWNLOAD_PAGE,
  OFFICE365_DOWNLOAD_PAGE,
  OUTLOOK_DOWNLOAD_PAGE,
  POWERPOINT_DOWNLOAD_PAGE,
  REDMINE_LINK,
  TEAMS_DOWNLOAD_PAGE,
  WORD_DOWNLOAD_PAGE
} from '../../utils/constants';
import OutlookLogo2 from '../../images/Outlook-Logo.svg';
import ChromeLogo2 from '../../images/Chrome-Logo.svg';
import AdobeLogo2 from '../../images/Adobe-Logo.svg';
import TeamsLogo2 from '../../images/Teams-Logo.svg';
import EdgeLogo2 from '../../images/Edge-Logo.svg';
import FirefoxLogo2 from '../../images/Firefox-Logo.svg';
import AsanaLogo from '../../images/asana-logo.svg';
import EasyProjectsLogo from '../../images/easy-projects-logo.svg';
import JiraLogo from '../../images/jira-logo.svg';
import RedmineLogo from '../../images/redmine-logo.svg';
import WordLogo from '../../images/Word-Logo.svg';
import ExcelLogo from '../../images/Excel-Logo.svg';
import PowerPointLogo from '../../images/PowerPoint-Logo.svg';
import HubspotLogo from '../../images/hubspot-logo.svg';
import Office365Logo from '../../images/office365-logo.svg';
import GoogleLogo from '../../images/google-cloud-logo.svg';

interface IConnection {
  title: string;
  image: {
    src: string;
  };
  link: string;
  email: string;
}

interface IIntegration {
  title: string;
  image: {
    src: string;
    width: string;
  };
  path: string;
}

// Mockup data for connections
export const connection1: IConnection = {
  title: 'BirdView PSA',
  image: {
    src: EasyProjectsLogo
  },
  link: EASY_PROJECTS_LINK,
  email: 'paulo.cunha@ahausoftware.com'
};

export const connection2: IConnection = {
  title: 'Redmine',
  image: {
    src: RedmineLogo
  },
  link: REDMINE_LINK,
  email: 'paulo.cunha@ahausoftware.com'
};

export const integration1: IIntegration = {
  title: 'Microsoft Outlook',
  image: {
    src: OutlookLogo2,
    width: '80px'
  },
  path: OUTLOOK_DOWNLOAD_PAGE
};

export const integration2: IIntegration = {
  title: 'Google Chrome',
  image: {
    src: ChromeLogo2,
    width: '70px'
  },
  path: CHROME_DOWNLOAD_PAGE
};

export const integration3: IIntegration = {
  title: 'Adobe Creative Cloud',
  image: {
    src: AdobeLogo2,
    width: '70px'
  },
  path: ADOBE_DOWNLOAD_PAGE
};

export const integration4: IIntegration = {
  title: 'Microsoft Teams',
  image: {
    src: TeamsLogo2,
    width: '90px'
  },
  path: TEAMS_DOWNLOAD_PAGE
};

// * Not implemented as of now
// export const integration5: IIntegration = {
//   title: 'Opera Browser',
//   image: {
//     src: OperaLogo2,
//     width: '135px'
//   },
//   path: OPERA_DOWNLOAD_PAGE
// };

export const integration6: IIntegration = {
  title: 'Microsoft Edge',
  image: {
    src: EdgeLogo2,
    width: '70px'
  },
  path: EDGE_DOWNLOAD_PAGE
};

export const integration7: IIntegration = {
  title: 'Mozilla Firefox',
  image: {
    src: FirefoxLogo2,
    width: '70px'
  },
  path: FIREFOX_DOWNLOAD_PAGE
};

export const integration8: IIntegration = {
  title: 'Word',
  image: {
    src: WordLogo,
    width: '70px'
  },
  path: WORD_DOWNLOAD_PAGE
};

export const integration9: IIntegration = {
  title: 'Excel',
  image: {
    src: ExcelLogo,
    width: '70px'
  },
  path: EXCEL_DOWNLOAD_PAGE
};

export const integration10: IIntegration = {
  title: 'PowerPoint',
  image: {
    src: PowerPointLogo,
    width: '70px'
  },
  path: POWERPOINT_DOWNLOAD_PAGE
};

export const integration11: IIntegration = {
  title: 'Office365',
  image: {
    src: Office365Logo,
    width: '70px'
  },
  path: OFFICE365_DOWNLOAD_PAGE
};

export const integrations = [
  integration1,
  integration2,
  integration3,
  integration4,
  // integration5,
  integration6,
  integration7,
  integration8,
  integration9,
  integration10,
  integration11
];

export const connections = [connection1, connection2];

export const connectionImages = {
  [ServiceProvider.Asana]: AsanaLogo,
  [ServiceProvider.EasyProjects]: EasyProjectsLogo,
  [ServiceProvider.Jira]: JiraLogo,
  [ServiceProvider.Redmine]: RedmineLogo,
  [ServiceProvider.HubSpot]: HubspotLogo,
  [ServiceProvider.Office365]: Office365Logo,
  [ServiceProvider.Google]: GoogleLogo
};

export const choiceGroupOptionsStyles: Partial<IChoiceGroupOptionStyles> = {
  choiceFieldWrapper: {
    height: '100%',
    width: '100%',
    selectors: {
      // Todo: Review colors for account selection
      '.is-checked': {
        background: '',
        selectors: {
          ':hover::before': {
            borderColor: 'unset'
          },
          '::after': {
            background: '',
            borderColor: ''
          }
        }
      }
    }
  },
  field: {
    padding: 0,
    height: '100%'
  },
  imageWrapper: {
    filter: 'grayscale(100%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  selectedImageWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  labelWrapper: {
    display: 'none'
  }
};

export const ASANA_DEFAULT_WEBROOT = 'https://app.asana.com';
export const HUBSPOT_DEFAULT_WEBROOT = 'https://hubspot.com';
export const OFFICE365_DEFAULT_WEBROOT = 'https://office365.com';

export const ACCOUNTS_TITLE = 'Accounts';
export const CRM_TITLE = 'CRM';
export const DATA_SOURCES_TITLE = 'Cloud Platforms';
