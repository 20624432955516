import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import Button from '../../components/Button';
import AccountSelectorCircle from '../../images/AccountSelectorCircle.svg';
import AccountSelectorCheckbox from '../../images/AccountSelectorCheckbox.svg';
import {
  BLACK_COLOR,
  BLUE_COLOR,
  GREY_COLOR,
  LIGHT_BLUE_COLOR,
  LIGHT_GREY_COLOR,
  WHITE_COLOR
} from '../../consts/theme';

export const ConnectionPageContainer = styled.div`
  margin-top: 100px;
  min-height: 680px;

  background: ${WHITE_COLOR};
  box-shadow: -5px 10px 20px rgba(70, 70, 80, 0.1);
  border-radius: 10px;
  padding-bottom: 40px;
  margin-bottom: 100px;
`;

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 65px 0 65px;
`;

export const BackButtonContainer = styled.div`
  width: 65px;
  position: absolute;
  cursor: pointer;

  a {
    text-decoration: none;
    color: ${GREY_COLOR};
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
`;

export const StepperContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const Step = styled.div<{ isCurrent: boolean; isPassedStep: boolean }>`
  width: 40px;
  height: 40px;
  background: ${(props) => (props.isCurrent ? BLUE_COLOR : LIGHT_GREY_COLOR)};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isCurrent ? WHITE_COLOR : `${BLACK_COLOR}8A`)};
  font-family: 'segoeuii';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  ${(props) =>
    props.isPassedStep &&
  `
    background: ${LIGHT_BLUE_COLOR};
    color: ${BLUE_COLOR};
  `}
`;

export const ProvidersContainer = styled.div`
  margin-top: 40px;
`;

export const AccountsSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 800px;
  margin: auto;
`;

export const AccountSelectorContainer = styled.div<{
  isHover: boolean;
  isSelected?: boolean;
  disabled?: boolean;
}>`
  width: 180px;
  height: 180px;
  border: 2px solid #d8e8fc;
  border-radius: 10px;
  cursor: pointer;
  ${(props) => props.disabled && 'cursor: not-allowed;'}
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${WHITE_COLOR};
  position: relative;

  img {
    width: 100px;
    height: 100px;
  }

  &:before {
    position: absolute;
    content: '';
    ${(props) =>
    props.isHover &&
  !props.isSelected &&
  !props.disabled &&
  `background-image: url(${AccountSelectorCircle});`}
    ${(props) =>
    props.isSelected &&
  !props.disabled &&
  `background-image: url(${AccountSelectorCheckbox});`}
    width: 24px;
    height: 24px;
    top: 12px;
    right: 12px;
  }

  &:after {
    position: absolute;
    content: '';
    background: ${(props) => (props.disabled ? WHITE_COLOR : BLUE_COLOR)};
    width: 180px;
    height: 180px;
    opacity: ${(props) => (props.disabled ? 0.5 : 0.1)};
    border-radius: 10px;
    display: ${(props) =>
    props.isSelected || props.disabled ? 'unset' : 'none'};
  }
`;

export const ConnectionTitle = styled.h2`
  font-family: 'TTHoves-DemiBold';
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: ${BLACK_COLOR};
  margin: 0;
`;

export const ConnectionSubtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: 'segoeuii';
  color: ${GREY_COLOR};
  margin-top: 12px;
  margin-bottom: 40px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export const ActionButton = styled(Button)`
  width: 100px;
  margin: 0 15px;
`;

export const ContinueButton = styled(Button)`
  width: 100px;
  margin-right: 10px;
`;

export const ConnectActionContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConnectActionWitApiKeyContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Link = styled(HashLink)`
  text-decoration: none !important;

  button {
    margin: 0 10px;
  }
`;

export const ConnectionHeaderTitle = styled.p`
  font-family: 'segoeuii';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${GREY_COLOR};
  margin-bottom: 0;
`;

export const ConnectionHeaderUrlContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConnectionHeaderChangeButton = styled.p`
  font-family: 'segoeuii';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${GREY_COLOR};
  text-decoration-line: underline;
  cursor: pointer;
  margin: 0;
`;

export const MessageBar = styled.div<{ isSuccessfull?: boolean }>`
  position: relative;
  width: 420px;
  display: flex;
  align-items: center;
  justify-content: right;
  background: ${(props) =>
    props.isSuccessfull ? 'rgba(39, 174, 96, 0.12)' : 'rgb(255, 244, 206)'};
  border-radius: 10px;
  padding: 30px 0;
  padding-right: 20px;

  div {
    position: absolute;
    background: ${(props) =>
    props.isSuccessfull ? '#27AE6033' : 'rgb(252, 233, 167)'};
    width: 56px;
    height: 56px;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 22px;
    color: rgb(247, 205, 57);
    font-weight: bold;
    font-size: 26px;
  }

  p {
    font-family: 'segoeuii',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${BLACK_COLOR};
    width: 300px;
    margin: 0;
  }
`;

export const CloseCross = styled.div`
  width: 32px;
  height: 32px;
  background: #d3d3d3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 600px) {
    right: -236px;
  }

  @media (max-width: 510px) {
    top: -85px;
    right: -215px;
  }

  @media (max-width: 470px) {
    display: none;
    right: -190px;
  }

  @media (max-width: 410px) {
    display: none;
  }
`;

export const WorkspaceSelect = styled.select`
  width: 100%;
  height: 40px;
  padding: 0 10px;
  font-family: 'segoeuii';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${BLACK_COLOR};
`;
