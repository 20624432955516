import React, { useState } from 'react';
import { FontIcon } from '@fluentui/react';
import { historyRowStyles } from './style';

interface HistoryRowProps {
  query: string;
  onClick: (query: string) => void;
  searchQuery: string | undefined;
}

const HistoryRow: React.FC<HistoryRowProps> = ({
  query,
  onClick,
  searchQuery,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    // Call the provided click handler
    onClick(query);
  };

  // Function to generate JSX with bold characters for matched searchQuery
  const renderLabelWithBold = () => {
    if(!searchQuery) return query;
    const lowerCaseLabel = query.toLowerCase();
    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    if (!lowerCaseLabel.includes(lowerCaseSearchQuery)) {
      return query; // No match, return the original label
    }

    const startIndex = lowerCaseLabel.indexOf(lowerCaseSearchQuery);
    const endIndex = startIndex + lowerCaseSearchQuery.length;

    return (
      <>
        {query.substring(0, startIndex)}
        <strong>{query.substring(startIndex, endIndex)}</strong>
        {query.substring(endIndex)}
      </>
    );
  };

  return (
    <div
      style={{
        ...historyRowStyles.historyRow,
        ...(isHovered ? historyRowStyles.hovered : {}),
      }}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FontIcon
        aria-label="Search"
        iconName="Search"
        style={historyRowStyles.searchIcon}
      />
      <div style={historyRowStyles.labelText}>{renderLabelWithBold()}</div>
    </div>
  );
};

export default HistoryRow;
