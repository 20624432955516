import React, { HTMLAttributes } from 'react';

export const SimpleForm = (props: HTMLAttributes<HTMLDivElement>) => {
  return <div {...props}>{props.children}</div>;
};

/**
 * Adds visual separation for fields in the form
 */
export const FormFieldSection = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      style={{ marginTop: `${0.5}rem`, marginBottom: `${0.5}rem` }}
    >
      {props.children}
    </div>
  );
};
