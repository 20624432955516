import styled from 'styled-components';

const tweakpoints = {
  mediumScreen: '854px'
};

export const ItemsContainer = styled.div<{
  size?: number;
  isProfilePage?: boolean;
}>`
  display: grid;
  margin-bottom: 30px;

  ${(props) =>
    props.size === 2 &&
  `
        grid-template-columns: 1fr 1fr;
        grid-gap: 35px;    
    `}

  ${(props) =>
    props.size === 3 &&
  `
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 50px;
    `}

    ${(props) =>
    props.isProfilePage &&
  `
        grid-gap: 30px;
    `}

    @media (max-width: 921px) {
    grid-gap: ${(props) => (props.isProfilePage ? '30px' : '10px')};
  }
`;

export const Container = styled.div<{ size: number; marginTop?: string }>`
  display: grid;
  grid-template-columns: 20px 8fr 20px;
  grid-gap: 15px;
  margin-top: ${(props) => props.marginTop};

  ${(props) =>
    props.size === 3 &&
  `
        grid-template-columns: unset;
        grid-gap: unset;
    `}

  @media (max-width: 536px) {
    display: flex;
    flex-direction: column;
  }
`;

const breakpoints = {
  mediumScreen: '768px'
};

export const PaginationContainer = styled.div`
  display: grid;
  grid-template-rows: 3fr 2fr 3fr;
  padding: 0 0 0 30px;

  @media (max-width: ${breakpoints.mediumScreen}) {
    grid-template-columns: 3fr 2fr 3fr;
    grid-template-rows: unset;
    padding: 30px 0 0 0;
  }

  @media (max-width: 550px) {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 0 30px 0;
  }
`;

export const Arrow = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.size === 3 &&
  `
        display: none;
    `}
`;

export const PointsList = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) =>
    props.size === 3 &&
  `
        display: none;
    `}

  ${(props) =>
    props.size === 2 &&
  `
        & > div:nth-child(3) {
            display: none;
        }
    `}

    @media (max-width: ${tweakpoints.mediumScreen}) {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const Point = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;

  ${(props) =>
    props.isActive
      ? `
       background: #3A86FF;  `
      : `
       background: #EFEFEF;
       `}

  margin: 7px 0;
  cursor: pointer;

  @media (max-width: ${tweakpoints.mediumScreen}) {
    margin: 0 7px;
  }

  @media (max-width: 768px) {
    margin: 0 7px;
  }
`;
