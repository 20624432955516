/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { memo } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as EmptyAccountsListIcon } from '../../../images/EmptyAccountsListIcon.svg';
import { EmptyListContainer, EmptyListDescription } from './style';
import Button from '../../../components/StyledButton';

interface IEmptyListProps {
}

const EmptyList: React.FC<IEmptyListProps> = (props) => {

  const history = useHistory();

  const connectionHandler = () => {
    history.push('/connection');
  };

  return (
    <EmptyListContainer>
      <EmptyAccountsListIcon />
      <EmptyListDescription>
        Start managing your activities. Connected accounts will show up here.
      </EmptyListDescription>
      <Button title="Connect Account" onClick={connectionHandler} />
    </EmptyListContainer>
  );
};

export default memo(EmptyList);
