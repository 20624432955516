/* eslint-disable import/no-cycle */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { AssignTeamMatesContainer, LoadingContainer, StyledButton, TeamMatesInputLabel, TeamMatesList } from '../style';
import MultipleEmailsInput from '../MultipleEmailsInput';
import TeamMatesOrganizationRow from './TeamMatesOrganizationRow';
import { InviteNewTeamContainer, InviteNewTeamMates } from './style';
import {
  fetchInviteAdditionalMembers,
  fetchOrganizationDetails,
  selectOrganizations
} from '../../../utils/reducers/organizations';
import { SetupOrgSteps } from '../../../consts';
import { selectAppState } from '../../../utils/appReducer';
import { isValidEmail } from '../../../helpers/validators';
import OrganizationSection from '../OrganizationSection';
import { LoadSpinner } from '../../Profile/Loading';
import { NEW_ORG_TEAM_COLORS } from '../../../consts/theme';
import { OrganizationInvite } from '../../../gen/src/ahauOpenAPI';
import mixpanelService, { EventName } from '../../../services/MixpanelService';

enum LoadingType {
  PAGE_LOADING,
  TEAM_MATES_INVITING
}

interface IContentLoading {
  isLoading: boolean;
  loadingType: LoadingType;
}

export interface AssignTeamMatesProps {
  id: string
}

const OrganizationOfTeamMates: React.FC<AssignTeamMatesProps> = (props) => {
  const { id } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const { initialized } = useSelector(selectAppState);
  const { organization: { invites, members } } = useSelector(selectOrganizations);

  const [loadingState, setLoadingState] = useState<IContentLoading | null>(null);
  const [emails, setEmails] = useState<string[]>([]);

  React.useEffect(() => {
    if (initialized && id) {
      setLoadingState({ isLoading: true, loadingType: LoadingType.PAGE_LOADING });
      dispatch(fetchOrganizationDetails(Number(id)));
    }
  }, [id, initialized]);

  React.useEffect(() => {
    loadingState?.isLoading && setLoadingState(null);
  }, [invites, members]);

  const sendInviteAction = useCallback(() => {
    mixpanelService.sendEvent(EventName.MEMBER, 'Invite Member(s)');
    setLoadingState({ isLoading: true, loadingType: LoadingType.TEAM_MATES_INVITING });
    dispatch(fetchInviteAdditionalMembers(Number(id), emails, ''));
    setEmails([]);
  }, [id, emails]);

  return (
    <OrganizationSection
      extraStyles={{ maxWidth: '1000px' }}
      currentStep={SetupOrgSteps.ORGANIZE_USERS}
      title="Organization Members"
      orgActions={(
        <StyledButton onClick={() => history.push('/')}>Close</StyledButton>
      )}
    >
      <AssignTeamMatesContainer>
        {loadingState?.isLoading && loadingState.loadingType === LoadingType.PAGE_LOADING ? (
          <LoadingContainer style={{ position: 'relative', height: 100 }}>
            <LoadSpinner />
          </LoadingContainer>
        ) : (
          <TeamMatesList>
            {
              [...invites, ...members].map((invite, index) => (
                <TeamMatesOrganizationRow
                  orgId={Number(id)}
                  key={invite.email}
                  member={invite}
                  memberStatus={(invite instanceof OrganizationInvite) ? 'Invite not accepted' : 'Member'}
                  color={NEW_ORG_TEAM_COLORS[index % 10]}
                />
              ))
            }
          </TeamMatesList>
        )}
        <InviteNewTeamContainer>
          <TeamMatesInputLabel>Invite with emails</TeamMatesInputLabel>
          <InviteNewTeamMates>
            <MultipleEmailsInput
              emails={emails}
              setEmails={setEmails}
            />
            <StyledButton
              disabled={
                !emails.length || emails.some((email) => !isValidEmail(email))
              }
              onClick={sendInviteAction}
            >
              {
                loadingState?.isLoading && loadingState.loadingType === LoadingType.TEAM_MATES_INVITING ? (
                  <>
                    <Spinner as="div" animation="border" role="status" size="sm" color="blue" />
                    <span>Sending...</span>
                  </>
                ) : (
                  <span>Send</span>
                )
              }
            </StyledButton>
          </InviteNewTeamMates>
        </InviteNewTeamContainer>
      </AssignTeamMatesContainer>
    </OrganizationSection>
  );
};

export default OrganizationOfTeamMates;
