/* eslint-disable import/no-cycle */
import React, { memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../Loading';
import { selectSettings, updateUserSettings } from '../../../utils/reducers/settings';
import { PreferencesSection } from './style';
import PreferenceRow from './PreferencesRow';

enum PreferencesItem {
  CALENDAR,
  CHECKLIST
}

const PreferencesList: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const settings = useSelector(selectSettings);
  const dispatch = useDispatch();

  const onChange = (isChecked: boolean, item: PreferencesItem) => {
    if (item === PreferencesItem.CALENDAR) {
      dispatch(
        updateUserSettings(isChecked, settings.userSettings.syncChecklists)
      );
    } else if (item === PreferencesItem.CHECKLIST) {
      dispatch(
        updateUserSettings(settings.userSettings.syncCalendar, isChecked)
      );
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {isAuthenticated && (
        <PreferencesSection>
          <h1>Preferences</h1>
          <div>
            <PreferenceRow
              syncTypeStatus={settings.userSettings.syncCalendar}
              onCheckboxClick={(isChecked: boolean) =>
                onChange(isChecked, PreferencesItem.CALENDAR)}
              description="Sync Calendar"
              infoDescription="Sync all your assigned tasks"
            />
            <PreferenceRow
              syncTypeStatus={settings.userSettings.syncChecklists}
              onCheckboxClick={(isChecked: boolean) =>
                onChange(isChecked, PreferencesItem.CHECKLIST)}
              description="Sync Checklists"
              infoDescription="Sync your checklists to your Microsoft 365 personal To Do"
            />
          </div>
        </PreferencesSection>
      )}
    </>
  );
};

export default memo(PreferencesList);
