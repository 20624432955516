import styled from 'styled-components';
import { Icon } from '@fluentui/react';
import { BLUE_COLOR, GREY_COLOR } from '../../../consts/theme';

export const PreferencesSection = styled.div`
  margin-top: 90px;
  h1 {
    color: #464650;
    font-size: 32px;
    font-weight: 700;
  }

  & > div {
    margin-top: 30px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    background: #fff;
    border: 2px solid #d8e8fc;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    padding: 30px;
    transition: filter 0.15s ease-out;
  }

  & > div:hover {
    filter: drop-shadow(0px 0px 8px rgba(144, 144, 144, 0.2));
  }
`;

export const PreferenceRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const PreferencesRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  span {
    color: #464650;
    font-size: 20px;
  }
`;

export const CheckboxIcon = styled(Icon)<{ isSelected: boolean }>`
  font-size: 16px;
  cursor: default;
  color: ${(props) => (props.isSelected ? BLUE_COLOR : GREY_COLOR)};
  line-height: unset;
`;

export const PreferenceDescription = styled.div`
  color: #909096;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

export const InfoIcon = styled(Icon)`
  font-size: 16px;
  color: ${GREY_COLOR};
  line-height: unset;
`;
