import { IButtonStyles, IPersonaStyles } from '@fluentui/react';
import { CSSProperties } from 'react';
import { COLORS } from '../style';

export const containerStyle = (isHovered: boolean): CSSProperties => ({
  maxWidth: '100%',
  minWidth: '75%',
  height: 'auto',
  marginTop: '2%',
  marginBottom: '3%',
  padding: '1%',
  boxShadow: isHovered
    ? '-4px 6px 12px rgba(0, 86, 179, 0.4)'
    : '-4px 6px 12px rgba(144,144,144,0.4)',
  transition: 'box-shadow 0.1s ease-in-out',
  backgroundColor: 'white',
  borderRadius: '8px',
});

export const filterButtonStyle = (
  isSelected: boolean,
  ishovered: boolean
): CSSProperties => ({
  backgroundColor: isSelected ? 'black' : ishovered ? '#e0e0e0' : COLORS.lightGray,
  color: isSelected ? 'white' : 'gray',
  width: 'auto',
  minWidth: '48px',
  height: '24px',
  fontSize: '12px',
  textAlign: 'center',
  borderRadius: '12px',
  border: 'none',
  lineHeight: '12px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 8px',
  fontWeight: 'bold',
  cursor: 'pointer',
});

export const dataResultButtonStyle = (isHovered: boolean, disabled: boolean): CSSProperties => ({
  display: 'flex',
  height: '42px',
  width: '42px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isHovered ? 'white' : 'transparent',
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.25 : 1,
});

export const resultsContainerStyle: CSSProperties = {
  width: '100%',
  backgroundColor: COLORS.lightestGray,
};

export const mainFiltersStyle: CSSProperties = {
  display: 'flex',
  maxWidth: '100%',
  padding: '6px',
  marginBottom: '5%',
  flexWrap: 'wrap',
  gap: '6px',
};

export const createWorkspaceButtonStyle: IButtonStyles = {
  root: {
    backgroundColor: 'transparent',
    color: 'gray',
    minWidth: '12px',
    height: '24px',
    width: '24px',
    textAlign: 'center',
    borderRadius: '12px',
    border: 'none',
    marginRight: '24px',
  },
  icon: {
    fontSize: '10px',
    fontWeight: 'bold',
    width: 'auto',
    height: 'auto',
  },
  rootHovered: {
    backgroundColor: '#e0e0e0',
    color: 'gray',
  },
  rootPressed: {
    backgroundColor: 'transparent',
    color: 'gray',
  },
};

export const mainFiltersContainerStyle: CSSProperties = {
  backgroundColor: COLORS.lightestGray,
  display: 'flex',
  maxWidth: '100%',
  width: '100%',
  padding: '6px',
  marginBottom: '1%',
  flexWrap: 'wrap',
  gap: '6px',
};

export const cellPersonaStyle: Partial<IPersonaStyles> = {
  root: {
    width: '24px',
  },
  primaryText: {
    display: 'none',
  },
};

export const filterPersonaStyle: Partial<IPersonaStyles> = {
  root: {
    width: '24px',
    marginLeft: 'auto'
  },
  primaryText: {
    display: 'none',
  },
};
