/* eslint-disable import/no-named-as-default */ 
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
import React, { memo, useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { AuthMethods, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import Providers from './Providers';
import { deleteAccountAsync, selectServiceProvied } from '../../utils/reducers/accounts';
import {
  connectCredentials,
  connectOAuth,
  fetchConnection,
  IConnectionCredentials,
  modifyConnectionEditData,
  onWebRootContinueCreateClick,
  selectConnection,
  startSync,
  verifyUrlAsync
} from '../../utils/reducers/connection';
import { StepsHeader } from '../../components/Header/StepsHeader';
import { ConnectionAuthorizeForm } from './ConnectionAuthorizeForm';
import { ConnectionPageContainer } from './style';
import { ConnectionStatus } from './ConnectionStatus';
import styles from './styles.module.css';
import { ConnectionStepNumbers, ConnectionSteps } from '../../consts';
import { selectAppState } from '../../utils/appReducer';
import { Loading } from '../Profile/Loading';
import { getConnectionType } from '../../helpers/connectionHelper';
import ConnectionAddressForm from './ConnectionAddressForm';

const Connection: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams() as { id?: number };
  
  const { initialized } = useSelector(selectAppState);

  const [isChanged, setChangedStatus] = useState<boolean>(false);
  const [step, setStep] = useState<ConnectionSteps>(
    id ? ConnectionSteps.EDIT : ConnectionSteps.SELECT
  );
  const [stepList, setStepList] = useState<ConnectionSteps[]>([
    id ? ConnectionSteps.AUTORIZE : ConnectionSteps.SELECT
  ]);
  const [isAddressChanging, setIsAddressChanging] = useState<boolean>(false);

  const { serviceProvider } = useSelector(selectServiceProvied);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (initialized) {
      dispatch(
        fetchConnection({
          accountId: id,
          serviceProvider
        })
      );
    }
  }, [
    initialized,
    dispatch,
    id,
    serviceProvider
  ]);

  const connectionState = useSelector(selectConnection);

  const {
    isLoading,
    isVerifiedAccount,
    isVerifiedWebroot,
    isVerifyingWebroot,
    verifyUrlData,
    verifyAccountErrorMessage,
    verifyUrlErrorMessage,
    connectorCreateData: {
      defaultWebRoot = '',
      serviceProvider: accountCreationProvider = undefined,
      serviceProviderName: accountCreationProviderName = undefined
    } = {},
    connectionEditData,
    accountInfo,
    accountInfo: {
      serviceProvider: accountInfoProvider = ServiceProvider.EasyProjects,
      id: accountId = 0
    } = {}
  } = connectionState;

  useEffect(() => {
    id &&
      initialized &&
      isVerifiedWebroot &&
      isVerifiedAccount &&
      dispatch(startSync(id));
  }, [id, isVerifiedAccount, isVerifiedWebroot, initialized]);

  const onCloseCallback = useCallback(() => {
    if (step === ConnectionSteps.AUTORIZE && !!stepList.length) {
      deleteAccount();
    }
  }, [step, accountId, stepList]);

  const connectCredentialsHandle = (
    connectionSettings: IConnectionCredentials
  ) => {
    dispatch(connectCredentials(Number(accountId), connectionSettings, (response: number) => {
      history.push(
        `/connection/${response}`
      );
    }));
    
    setNextStep(ConnectionSteps.EDIT);
  };

  const deleteAccount = () => {
    setIsAddressChanging(true);
    dispatch(
      fetchConnection({
        accountId: 0,
        serviceProvider,
        defaultWebRoot: connectionEditData?.webRoot
      })
    );
    dispatch(deleteAccountAsync(accountId));
  };

  const onChangeAddress = () => {
    deleteAccount();
    setNextStep(ConnectionSteps.ADDRESS_SELECTION);
  };

  const setPrevStep = useCallback(() => {
    if (!stepList.length) return;
    const newStepList = Object.assign(stepList);
    const prevStep: ConnectionSteps | undefined = newStepList.pop();

    if (prevStep) {
      step === ConnectionSteps.AUTORIZE && deleteAccount();
      setStep(prevStep);
      setStepList(newStepList);
    }
  }, [step, stepList]);

  const setNextStep = (nextStep: ConnectionSteps) => {
    setStep(nextStep);
    !stepList.includes(step) &&
    setStepList((prevStepList) => [...prevStepList, step]);
  };

  if (!initialized) {
    return <Loading />;
  }

  return (
    <ConnectionPageContainer className={styles.providersChoiceGroupContainer}>
      <StepsHeader
        closeLinkTo={`/#${getConnectionType(location.search)}`}
        currentStep={get(ConnectionStepNumbers, step, 0)}
        displayGoBack={
          get(ConnectionStepNumbers, step, 0) > 1 && !!stepList.length
        }
        steps={4}
        goBack={setPrevStep}
        onCLose={onCloseCallback}
      />
      {step === ConnectionSteps.SELECT && (
        <Providers
          isChanged={isChanged}
          setChangedStatus={setChangedStatus}
          setStep={setNextStep}
        />
      )}
      {step === ConnectionSteps.ADDRESS_SELECTION && (
        <ConnectionAddressForm
          {...connectionState}
          isLoading={isLoading}
          defaultWebRoot={
            defaultWebRoot === 'https://'
              ? 'https://your_domain.eg/'
              : defaultWebRoot
          }
          isCreationMode
          serviceProvider={accountCreationProvider}
          serviceProviderName={accountCreationProviderName}
          editData={connectionEditData}
          onContinueClick={(webRoot) => {
            dispatch(onWebRootContinueCreateClick({ webRoot }));
            setNextStep(ConnectionSteps.AUTORIZE);
          }}
          verifyUrl={(value) => dispatch(verifyUrlAsync(undefined, value))}
          webRootIsVerified={isVerifiedWebroot}
          webRootIsVerifying={isVerifyingWebroot}
          error={verifyUrlErrorMessage}
          isAddressChanging={isAddressChanging}
        />
      )}
      {step === ConnectionSteps.AUTORIZE && (
        <ConnectionAuthorizeForm
          {...connectionState}
          isCreationMode={!id}
          serviceProvider={accountInfoProvider}
          editData={connectionEditData}
          accountId={accountId}
          changeAuthMethod={(newMethod) =>
            dispatch(modifyConnectionEditData({ authMethod: newMethod }))}
          showAuthorizeOAuthForm={verifyUrlData?.isSingleSignOnEnabled}
          showPasswordCredentialsForm={!verifyUrlData?.isSingleSignOnEnabled}
          errorOAuth={
            connectionEditData?.authMethod === AuthMethods.OAuthToken &&
            verifyAccountErrorMessage
          }
          connectOAuth={() => dispatch(connectOAuth(Number(accountId)))}
          connectCredentials={connectCredentialsHandle}
          verifyErrorMessage={verifyAccountErrorMessage}
          onChangeAddress={onChangeAddress}
        />
      )}
      {step === ConnectionSteps.EDIT && (
        <ConnectionStatus
          {...connectionState}
          accountId={accountId}
          isVerifiedAccount={isVerifiedAccount}
          editData={connectionEditData}
          verifyErrorMessage={verifyAccountErrorMessage}
          serviceProvider={
            accountInfo?.serviceProvider
              ? accountInfo?.serviceProvider
              : ServiceProvider.EasyProjects
          }
          onChangeConnectionClick={() => {
            setPrevStep();
          }}
          backToSection={getConnectionType(location.search)}
        />
      )}
    </ConnectionPageContainer>
  );
};

export default memo(Connection);
