/* eslint-disable import/no-cycle */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from './Button';
import mixpanelService, { EventName } from '../services/MixpanelService';

const tweakpoints = {
  largeScreenA: '1250px',
  largeScreenB: '1060px',

  smallScreenA: '667px',
  smallScreenB: '576px',
  smallScreenC: '330px'
};

export interface IQuestion {
  image: string;
  buttonText: string;
  id: number;
  modalType?: string;
  redirectDestination?: string;
}

interface QuestionProps {
  item: IQuestion;
  index: number;
  siblingsNumber?: number;
}

const Container = styled.div<{ siblingsNumber?: number }>`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  background: #fff;
  height: 400px;
  border: 2px solid #d8e8fc;
  display: flex;
  justify-content: space-around;
  transition: filter 0.15s ease-out;

  img {
    width: 300px;
    margin-bottom: 35px;
  }

  a {
    text-decoration: none;
  }

  &:hover {
    filter: drop-shadow(0px 0px 8px rgba(144, 144, 144, 0.2));
  }

  ${(props) =>
    props.siblingsNumber &&
  props.siblingsNumber > 2 &&
  `   
      @media (max-width: ${tweakpoints.largeScreenA}) {
        height: 370px;

        img {
          width: 280px;
        }
      }

      @media (max-width: ${tweakpoints.largeScreenB}) {
        img {
          width: 250px;
        }
      } 

      @media (max-width: ${tweakpoints.smallScreenA}) {
        img {
          max-width: 210px;
        }
      }

      @media (max-width: 590px) {
        img {
          max-width: 250px;
        }
      }

      @media (max-width: ${tweakpoints.smallScreenB}) {
        img {
          max-width: 250px;
        }
      }

       @media (max-width: 380px) {
        img {
          max-width: 235px;
        }
      }
    `};

  ${(props) =>
    props.siblingsNumber &&
  props.siblingsNumber === 2 &&
  `  
      @media (max-width: 380px) {
        img {
          max-width: 235px;
        }
      }
    `};
`;

const QuestionButton = styled(Button)`
  width: fit-content;
`;

const Question: React.FC<QuestionProps> = (props) => {
  const {
    image: QuestionImage,
    buttonText,
    redirectDestination
  } = props.item;

  return (
    <Container siblingsNumber={props.siblingsNumber}>
      <img src={QuestionImage} title={buttonText} alt={buttonText} />

      {/* // Button for links */}
      {redirectDestination && (
        <Link 
          to={{ pathname: redirectDestination }} 
          target="_blank"
          onClick={() => mixpanelService.sendEvent(EventName.NAVIGATION, `Question - ${buttonText}`)}
        >
          <QuestionButton isTransparent>{buttonText}</QuestionButton>
        </Link>
      )}
    </Container>
  );
};

export default Question;
