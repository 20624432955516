import React from 'react';
import { Tooltip } from '@fluentui/react-components';

interface DefaultTooltipProps {
  children: React.ReactElement;
  text: string;
}

const DefaultTooltip: React.FC<DefaultTooltipProps> = ({
  text,
  children,
}) => {
  return (
    <Tooltip
      content={text}
      relationship={'label'}
      positioning={'below'}
      showDelay={500}
      hideDelay={0}
      appearance='inverted'
    >
      {children}
    </Tooltip>
  );
};

export default DefaultTooltip;
