import React from 'react';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

import ReactDOM from 'react-dom';
import FontFaceObserver from 'fontfaceobserver';
import { Provider } from 'react-redux';
import './index.css';
import { ConnectedRouter } from 'connected-react-router';
import { initializeIcons } from '@fluentui/react';
import { Auth0ProviderOptions } from '@auth0/auth0-react/dist/auth0-provider';
import ReactGA from 'react-ga4';

import store from './utils/store';
import routerHistory from './utils/history';

import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

import reportWebVitals from './reportWebVitals';
import { getConfig } from './config';
import { paidPlans } from './pages/Pricing/data';

ReactGA.initialize('G-FX1NJ6M6YK');

initializeIcons();

const onRedirectCallback = (appState: AppState) => {
  if (appState?.returnTo) {
    routerHistory.push(appState?.returnTo);
  } else if (appState?.connectionHint) {
    routerHistory.push(
      `/connection?accountType=crm&&selectedProvider=${appState.connectionHint}`
    );
  } else {
    routerHistory.push(
      `${
        paidPlans.includes(appState?.billingTerm)
          ? `?billingTerm=${appState.billingTerm}`
          : ''
      }`
    );
  }
};

const config = getConfig();

const searchQuery = new URLSearchParams(window.location.search);

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: `${window.location.origin}/callback`,
  // redirectUri: `${window.location.protocol}//${window.location.hostname}:${
  //   window.location.port ? window.location.port : ''
  // }/callback`,
  onRedirectCallback,
  login_hint: searchQuery.get('prefiled_email') ?? ''
};

const TTHovesFont = new FontFaceObserver('TTHoves-DemiBold');
const SegoeuiiFont = new FontFaceObserver('segoeuii');

Promise.all([TTHovesFont.load(), SegoeuiiFont.load()]).finally(() => {
  ReactDOM.render(
    <Auth0Provider {...providerConfig as Auth0ProviderOptions}>
      <Provider store={store}>
        {/* <PersistGate persistor={persistor}> */}
        <ConnectedRouter history={routerHistory}>
          <FluentProvider theme={teamsLightTheme}>
            <App />
          </FluentProvider>
        </ConnectedRouter>
        {/* </PersistGate> */}
      </Provider>
    </Auth0Provider>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
