import * as React from 'react';
import { CommandButton } from '@fluentui/react/lib/Button';
import { showMoreContainerStyle, showMoreIconStyle, showMoreStyles } from './style';

interface ShowMoreProps {
  onClick: () => void;
}

export const ShowMore: React.FunctionComponent<ShowMoreProps> = ({
  onClick,
}) => {
  return (
    <div style={showMoreContainerStyle}>
      <CommandButton
        styles={showMoreStyles}
        text="Show more"
        iconProps={{
          iconName: 'DoubleChevronDown12',
          style: showMoreIconStyle,
        }}
        onClick={onClick}
      />
    </div>
  );
};
