/* eslint-disable import/no-cycle */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LeftHeaderContainer } from './styled';
import { closeModal } from '../../utils/appReducer';
import logo from '../../images/logo-white.svg';
import { STATIC_APP_URL } from '../../utils/constants';
import mixpanelService, { EventName } from '../../services/MixpanelService';

const LeftHeader: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogoClick = () => {
    mixpanelService.sendEvent(EventName.NAVIGATION, 'Logo - Home');
    dispatch(closeModal());
    history.push('/');
    window.scrollTo(0, 0);
  };

  return (
    <LeftHeaderContainer>
      <a href={STATIC_APP_URL} rel="noreferrer" target="_blank" onClick={() => onLogoClick()}>
        <img src={logo} alt="tr" />
      </a>
    </LeftHeaderContainer>
  );
};

export default LeftHeader;
