/* eslint-disable import/no-cycle */
import React from 'react';
import { ConnectionSettingsOutputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { ConnectionHeaderSection } from './ConnectionHeaderSection';
import { UserCredentialsSection } from './UserCredentialsSection';
import { SimpleForm } from '../../components/Forms';
import { ActionFlexSection, SeparatorSection } from '../../components/Sections';
import { IConnectionCredentials } from './ConnectionAuthorizeForm';
import { AuthorizeOAuthSection } from './AuthorizeOAuthSection';
import Button from '../../components/StyledButton';

interface IJiraAuthorizeFormProps {
  serviceProvider: ServiceProvider;
  showAuthorizeOAuthForm?: boolean;
  errorOAuth?: unknown;
  showPasswordCredentialsForm?: boolean;
  verifyErrorMessage?: unknown;
  userName?: string;
  password?: string;
  passwordLength?: number;
  editData?: ConnectionSettingsOutputDto;

  onChange?: (args: IConnectionCredentials | undefined) => void;
  connectCredentials?: () => void;
  connectOAuth?: () => void;
}

export const JiraAuthorizeForm: React.FC<IJiraAuthorizeFormProps> = (props) => {
  return (
    <SimpleForm>
      <ConnectionHeaderSection
        {...props}
        userName={props.editData?.userName}
        webRoot={props.editData?.webRoot}
      />

      {props.showAuthorizeOAuthForm && (
        <>
          <AuthorizeOAuthSection {...props} connect={props.connectOAuth} />
          <br />
          <SeparatorSection>or</SeparatorSection>
          <br />
        </>
      )}
      <UserCredentialsSection
        {...props}
        passwordLength={props.passwordLength}
        label="E-mail"
        onChange={props.onChange}
        connectCredentials={props.connectCredentials}
        secondfieldLabel="API Token"
      />
      <ActionFlexSection>
        <Button onClick={props.connectCredentials} title="Connect" />
      </ActionFlexSection>
    </SimpleForm>
  );
};
