
/* eslint-disable max-len */
/* eslint-disable no-debugger */
/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import './fontawesome';
import { Route, Switch, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// @ts-ignore
import { NotificationContainer } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { History, Location } from 'history';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './components/Footer';
import MainContainer from './MainContainer';
import UserAction from './pages/UserAction';
import 'react-notifications/lib/notifications.css';
import Header from './components/Header';
import Copyright from './components/Copyright';
import { checkIsModalRouting, generateDocumentTitle, handleRouterAction, showFooter } from './utils/utilsFunctions';
import {
  getProfileData,
  getReturnUri,
  selectAppState,
  selectLoginModal,
  selectRouterAction
} from './utils/appReducer';
import useWindowSize from './hooks/useWindowResize';
import { Routes } from './consts/routes';
import { fetchAccountCreateData, fetchAccountsList } from './utils/reducers/accounts';
import { fetchUserSettings } from './utils/reducers/settings';
import { fetchOrganizations } from './utils/reducers/organizations';
import { fetchWorkspacesList } from './utils/reducers/workspaces';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

export const WidthContext = createContext(0);

function App() {
  const dispatch = useDispatch();
  const location = useLocation<Location>();
  const history = useHistory<History>();

  const routerAction = useSelector(selectRouterAction);
  const isModal = useSelector(selectLoginModal);

  const [width] = useWindowSize();
  const isMobile = useMemo(() => width <= 768, [width]);

  const [documentTitle, setDocumentTitle] = useState<string>('');

  const { initialized } = useSelector(selectAppState);

  useEffect(() => {
    handleRouterAction(routerAction, isMobile, location, history);
  }, [history, location, width, routerAction, isMobile]);

  useEffect(() => {
    if (width > 768 && isModal) {
      document.body.style.overflow = 'hidden';
    }
  }, [isModal, width]);

  useEffect(() => {
    const returnUri = getReturnUri(location.search);
    const pathname = !checkIsModalRouting(location.pathname)
      ? location.pathname
      : returnUri;

    setDocumentTitle(generateDocumentTitle(pathname ?? ''));
  }, [location.pathname, isModal]);

  const {
    getAccessTokenSilently,
    loginWithRedirect
  } = useAuth0();

  useEffect(() => {
    if (!initialized) {
      dispatch(getProfileData(getAccessTokenSilently, loginWithRedirect));
    }
    if (initialized) {
      initializeFileTypeIcons();
      dispatch(fetchAccountsList());
      dispatch(fetchAccountCreateData());
      dispatch(fetchUserSettings());
      dispatch(fetchOrganizations());
      dispatch(fetchWorkspacesList());
    }
  }, [dispatch, getAccessTokenSilently, loginWithRedirect, initialized]);

  const isFooterVisible = showFooter(location.pathname);

  return (
    <WidthContext.Provider value={width}>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <MainContainer>
        <Header />
        <Switch>
        <Route
            exact
            path={Routes.workspaces.path}
            component={Routes.workspaces.component}
          />
          <Route
            exact
            path={Routes.profile.path}
            component={Routes.profile.component}
          />
          <Route
            exact
            path={Routes.profileConnection.path}
            component={Routes.profileConnection.component}
          />
          <Route
            exact
            path={`${Routes.profileConnection.path}/:id`}
            component={Routes.profileConnection.component}
          />
          <Route
            exact
            path={Routes.newOrganization.path}
            component={Routes.newOrganization.component}
          />
          <Route
            exact
            path={Routes.membersOrganization.path}
            render={Routes.membersOrganization.render}
          />
          <Route
            exact
            path={Routes.membersManagement.path}
            render={Routes.membersManagement.render}
          />
          <Route
            exact
            path={Routes.teamsOrganization.path}
            render={Routes.teamsOrganization.render}
          />
          <Route
            exact
            path={Routes.inviteOrganization.path}
            render={Routes.inviteOrganization.render}
          />
          {isMobile && <Route path={Routes.contactUs.path} component={Routes.contactUs.component} />}

          <Route
            exact
            path={`${Routes.order.path}`}
            component={Routes.order.component}
          />
          <Route
            exact
            path={`${Routes.invite.path}`}
            component={Routes.invite.component}
          />

          <Route
            exact
            path={Routes.error.path}
            component={Routes.error.component}
          />

          <Route path={Routes.contactUs.path} component={Routes.contactUs.component} />
          
          <Route path={Routes.search.path} component={Routes.search.component} />

          <Route render={Routes.default.render} />
        </Switch>

        {isFooterVisible && (
          <>
            <Footer />
            <Copyright>
              © Copyright {new Date().getFullYear()} - Ahau Software
            </Copyright>
          </>
        )}

        {!isMobile && <UserAction />}
        <NotificationContainer />
      </MainContainer>
    </WidthContext.Provider>
  );
}

export default App;
