/* eslint-disable import/no-cycle */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { StyledButton, TeamMatesComment, TeamMatesInputContainer, TeamMatesInputLabel } from './style';
import MultipleEmailsInput from './MultipleEmailsInput';
import { fetchInviteMembers } from '../../utils/reducers/organizations';
import { isValidEmail } from '../../helpers/validators';
import { SetupOrgSteps } from '../../consts';
import OrganizationSection from './OrganizationSection';
import { Routes } from '../../consts/routes';
import mixpanelService, { EventName } from '../../services/MixpanelService';

export interface AddTeamMatesProps {
  id: string
}

const InviteTeamMates: React.FC<AddTeamMatesProps> = (props) => {
  const dispatch = useDispatch();
  const { id } = props;

  const [emails, setEmails] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addTeamMates = useCallback(() => {
    mixpanelService.sendEvent(EventName.MEMBER, 'Invite Member(s)');
    setIsLoading(true);
    dispatch(fetchInviteMembers(Number(id), emails, message));
  }, [id, emails, message]);

  return (
    <OrganizationSection
      currentStep={SetupOrgSteps.ADD_TEAM_MATES}
      isLoading={isLoading}
      title="Add your team mates"
      orgActions={(
        <>
          <StyledButton
            disabled={emails.some((e) => !isValidEmail(e)) || !emails.length}
            onClick={addTeamMates}
          >
            Send
          </StyledButton>
          <Link to={Routes.membersOrganization.getPath!(id)}>
            <StyledButton isTransparent>Skip</StyledButton>
          </Link>
        </>
      )}
    >
      <TeamMatesInputContainer>
        <div>
          <TeamMatesInputLabel>Invite with emails</TeamMatesInputLabel>
          <MultipleEmailsInput
            emails={emails}
            setEmails={setEmails}
          />
        </div>
        <div>
          <TeamMatesInputLabel optional>Message (optional)</TeamMatesInputLabel>
          <TeamMatesComment placeholder="Add a message" onChange={(event) => setMessage(event.target.value)} />
        </div>
      </TeamMatesInputContainer>
    </OrganizationSection>
  );
};

export default InviteTeamMates;
