import React from 'react';
import styled from 'styled-components';

// very little space in width.
// don't put 20 px on the sides, do it on the form where it's needed.
// don't put mfgin-bottom, a scroll appears because of the footer
const StyledContainer = styled.section`
  margin: 10px 10px 10px 10px;
`;

interface IContainerProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const Container: React.FC<IContainerProps> = (props) => {
  return (
    <StyledContainer style={props.style}>{props.children}</StyledContainer>
  );
};
