import React from 'react';
import { MemberDto, OrganizationInvite } from '../../../gen/src/ahauOpenAPI';
import { UserAvatar } from '../style';
import { Avatar } from '../../../components/Header/styled';

export interface TeamMateAvatarProps {
  member: OrganizationInvite | MemberDto;
  color: string;
}

const TeamMateAvatar: React.FC<TeamMateAvatarProps> = (props) => {
  const  { member, color }  = props;

  if (member instanceof OrganizationInvite) {
    return (
      <UserAvatar color={color}>
        {member.email && member.email[0]}
      </UserAvatar>
    );
  }

  return (
    <Avatar
      src={member?.avatar}
      alt={member?.name ?? member.email}
      customStyles={{
        cursor: 'default'
      }}
    />
  );
};

export default TeamMateAvatar;