import React from 'react';
import { IStackTokens, Stack } from '@fluentui/react';
import {
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
} from '@fluentui/react-components';
import { resultsContainerStyle } from './style';
import { DataGridColumns, DataGridItem } from './DataGridColumns';
import { ProcessData } from './DataManager';
import { ItemType } from '../../../gen/src/ahauOpenAPI';

const stackTokens: Partial<IStackTokens> = { childrenGap: 8 };

interface DataResultsContainerProps {
  filter: ItemType;
}

export const DataResultsContainer: React.FC<DataResultsContainerProps> = ({
  filter,
}) => {
  return (
    <Stack horizontalAlign="center" verticalAlign="start" tokens={stackTokens}>
      <div style={resultsContainerStyle}>
        <DataGrid
          items={ProcessData(filter)}
          columns={DataGridColumns}
          sortable
          getRowId={(item) => item.key}
          focusMode="composite"
          size="medium"
          resizableColumns
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell }) => {
                const columnId = renderHeaderCell()?.toString();
                return (
                  <DataGridHeaderCell
                    style={
                      columnId === 'Label'
                        ? { flex: '1' }
                        : { maxWidth: '40px' }
                    }
                  >
                    {columnId}
                  </DataGridHeaderCell>
                );
              }}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<DataGridItem>>
            {({ item, rowId }) => (
              <div
                onClick={() => {
                  //window.open(item.url, '_blank');
                }}
              >
                <DataGridRow<DataGridItem> key={rowId}>
                  {({ renderCell }) => {
                    const cellContent = renderCell(item);

                    return (
                      <DataGridCell
                        style={
                          (cellContent as React.ReactElement)?.props?.fillSpace
                            ? {
                                wordBreak: 'break-all',
                                flex: '1',
                                paddingRight: '1px',
                              }
                            : {
                                wordBreak: 'break-all',
                                maxWidth: '40px',
                              }
                        }
                      >
                        {cellContent}
                      </DataGridCell>
                    );
                  }}
                </DataGridRow>
              </div>
            )}
          </DataGridBody>
        </DataGrid>
      </div>
    </Stack>
  );
};
