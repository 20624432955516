/* eslint-disable import/no-cycle */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from './Loading';
import Button from '../../components/StyledButton';
import { AccountSettingsSection } from './style';
import DeleteAccountModal from './DeleteAccountModal';

const AccountSettings: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const [isDeleteModal, showDeleteModal] = React.useState<boolean>(false);

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {isAuthenticated && (
        <>
          <AccountSettingsSection>
            <h1>Account Settings</h1>
            <div>
              <h4>Delete Account</h4>
              <span>
                Once you delete your account, there is no going back. Please be
                certain.
              </span>
              <Button
                title="Delete"
                isDanger
                onClick={() => {
                  showDeleteModal(true);
                }}
              />
            </div>
          </AccountSettingsSection>
          <DeleteAccountModal
            isDeleteModal={isDeleteModal}
            showDeleteModal={showDeleteModal}
          />
        </>
      )}
    </>
  );
};

export default AccountSettings;
