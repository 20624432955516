export const BLUE_COLOR = '#3A86FF';
export const LIGHT_BLUE_COLOR = '#E7F0FF';
export const DARK_BLUE_COLOR = '#296DD9';
export const GREY_COLOR = '#909096';
export const LIGHT_GREY_COLOR = '#EFEFEF';
export const WHITE_COLOR = '#FFFFFF';
export const BLACK_COLOR = '#464650';
export const RED_COLOR = '#C82333';
export const SEPARATOR_COLOR = 'rgba(144,144,150,0.67)';

export const NEW_ORG_TEAM_COLORS = [
  '#f06a6a',
  '#ec8d71',
  '#f8df72',
  '#5da283',
  '#9ee7e3',
  '#4573d2',
  '#8d84e8',
  '#b36bd4',
  '#f9aaef',
  '#fc979a'
];

export const WARNING_INPUT = '#e35555';

export const NEW_ORG_TEAM_HOVER_COLOR = '#f9f8f8';
