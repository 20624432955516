/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, StickyContainer } from './styled';
import { clearProfileData, selectLicenseData, sendGoogleAnalyticsEvent } from '../../utils/appReducer';
import useIsMounted from '../../hooks/useIsMounted';
import { WidthContext } from '../../App';
import LeftHeader from './LeftHeader';
import RightHeader from './RightHeader';
import { USER_ACTIONS } from '../../utils/constants';
import { toggleScroll } from '../../utils/utilsFunctions';
import { AuthScreenHint } from '../../consts';

const Header = () => {
  const { loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();

  const dispatch = useDispatch();
  const location = useLocation<Location>();
  const history = useHistory();

  const isMounted = useIsMounted();

  const { data: licenseData } = useSelector(selectLicenseData);

  const width = useContext(WidthContext);
  const isMobile = width <= 876;

  const [isSticky, setSticky] = useState(false);
  const [isMobileMenuOpen, changeMobileMenuVisibility] = useState<boolean>(
    false
  );
  const [isProfileMenuOpen, openProfileMenu] = useState<boolean>(false);

  const stickyContainerRef = useRef<HTMLDivElement>(null);

  const handleUserLogin = () => {
    sendGoogleAnalyticsEvent(
      USER_ACTIONS.login,
      `User clicked on ${USER_ACTIONS.login} button`
    );

    if (licenseData?.statusInfo === 'error' && location.pathname === '/error') {
      logout();
      return history.push({
        pathname: '/',
        state: {
          retryLogin: true
        }
      });
    }

    return loginWithRedirect();
  };

  const handleUserSignUp = () => {
    sendGoogleAnalyticsEvent(
      USER_ACTIONS.signUp,
      `User clicked on ${USER_ACTIONS.signUp} button`
    );
    loginWithRedirect({ screen_hint: AuthScreenHint.SIGN_UP });
  };

  const handleUserLogout = () => {
    sendGoogleAnalyticsEvent(
      USER_ACTIONS.logout,
      `User clicked on ${USER_ACTIONS.logout} button`
    );

    logout({ returnTo: window.location.origin });
    dispatch(clearProfileData());
  };

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setSticky(true);
      document.querySelector('#root')?.classList.add('sticky-header');
    } else {
      setSticky(false);
      document.querySelector('#root')?.classList.remove('sticky-header');
    }
    openProfileMenu(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (!isMobile) {
        changeMobileMenuVisibility(false);
      }

      toggleScroll(isMobileMenuOpen);
    }
  }, [width, isMobileMenuOpen, isMobile, isMounted]);

  return (
    <>
      <StickyContainer className={isSticky ? 'sticky-container' : ''}>
        <Container ref={stickyContainerRef} isSticky={isSticky}>
          <LeftHeader />
          <RightHeader
            isAuthenticated={isAuthenticated}
            isSticky={isSticky}
            isLoading={isLoading}
            onUserSignUp={handleUserSignUp}
            onUserLogin={handleUserLogin}
            onUserLogout={handleUserLogout}
            changeMobileMenuVisibility={changeMobileMenuVisibility}
            isProfileMenuOpen={isProfileMenuOpen}
            openProfileMenu={openProfileMenu}
          />
        </Container>
      </StickyContainer>
    </>
  );
};

export default Header;
