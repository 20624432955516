import React, { useEffect, useState } from 'react';
import {
  buttonIconStyle,
  centerDivStyle,
  resultRowContentContainerStyle,
  resultRowContentStyle,
  resultRowIconButtonStyle,
  resultRowStyle,
} from '../style';
import { Icon, IconButton } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import {
  linkUnlinkDocumentToWorkspace,
  selectWorkspacesList,
} from '../../../../../utils/reducers/workspaces';
import { useDispatch, useSelector } from 'react-redux';
import { ServiceProvider } from '../../../../../gen/src/ahauOpenAPI';

interface DocumentResultProps {
  filename: string | undefined;
  fileSize: number | undefined;
  url: string | undefined;
  clientId: string | undefined;
  service: ServiceProvider | undefined;
}

const DocumentResult: React.FC<DocumentResultProps> = ({
  filename,
  url,
  fileSize,
  clientId,
  service,
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { currentWorkspaceData } = useSelector(selectWorkspacesList);

  useEffect(() => {
    if (currentWorkspaceData && currentWorkspaceData.documents && url) {
      setDisabled(
        currentWorkspaceData.documents.some(
          (document) => document.originalUrl === url
        )
      );
    } else if (!currentWorkspaceData) setDisabled(false);
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(url, '_blank');
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...resultRowStyle,
        ...{
          borderLeft: isHovered ? '2px solid YellowGreen' : '2px solid white',
        },
      }}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <div style={resultRowContentContainerStyle}>
        <div style={centerDivStyle}>
          <Icon
            {...getFileTypeIconProps({
              extension: filename?.split('.').pop()?.toLowerCase() || '',
              size: 16,
              imageFileType: 'svg',
            })}
            style={{ display: 'flex' }}
          />
        </div>
        <div style={resultRowContentStyle}>{filename}</div>
        <div style={{ display: 'flex' }}>
          {filename && (
            <IconButton
              onClick={(e) => {
                dispatch(
                  linkUnlinkDocumentToWorkspace(
                    url,
                    clientId,
                    filename,
                    fileSize,
                    service
                  )
                );
                setDisabled(true);
                e.stopPropagation();
              }}
              title="Add to Workspace"
              disabled={disabled}
              iconProps={{
                iconName: 'AddTo',
                style: buttonIconStyle(disabled),
              }}
              styles={resultRowIconButtonStyle}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentResult;
