/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */

/* tslint:disable:no-any */
/**
 * Returns a validator that checks for null and whitespace
 * @param errorMessage Required error message to display
 */
export function required(
  errorMessage: string
): (value?: unknown | null) => string | undefined {
  return (value?: unknown): string | undefined => {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (Array.isArray(value) && value.length === 0)
    ) {
      return errorMessage;
    }
    return '';
  };
}

export function url(
  errorMessage: string
): (value?: any | null) => string | undefined {
  return (value?: any): string | undefined => {
    const urlRegexp = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    if (value && urlRegexp.test(value)) {
      return '';
    }
    return errorMessage;
  };
}

/**
 * Returns a validator that checks the length of a string and ensures its equal to a value. If input null return -1
 * @param desiredLength The length of the string
 * @param formatError a callback which takes the length and formats an appropriate error message for validation failed
 */
export function length(
  desiredLength: number,
  formatError: (length: number) => string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    // eslint-disable-next-line no-param-reassign
    value = value !== null && value !== undefined ? value : '';
    if (value.length !== desiredLength) {
      return formatError(value.length);
    }
    return undefined;
  };
}

/**
 * Returns a validator that checks the length of a string and ensures its greater than a value (inclusive)
 * @param lengthBound The min length of the string
 * @param formatError a callback which takes the values Length and formats an appropriate error message for validation failed
 */
export function minLength(
  lengthBound: number,
  formatError: (length: number) => string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    // eslint-disable-next-line no-param-reassign
    value = value !== null && value !== undefined ? value : '';
    if (value.length < lengthBound) {
      return formatError(value.length);
    }
    return undefined;
  };
}

/**
 * Returns a validator that checks the length of a string and ensures its less than a value (inclusive)
 * @param lengthBound The max length of the string
 * @param formatError a callback which takes the values length and formats an appropriate error message for validation failed
 */
export function maxLength(
  lengthBound: number,
  formatError: (length: number) => string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    // eslint-disable-next-line no-param-reassign
    value = value !== null && value !== undefined ? value : '';
    if (value.length > lengthBound) {
      return formatError(value.length);
    }
    return undefined;
  };
}

/**
 * Returns a validator that calls the passed in regular expression aganist the string using exec()
 * @param expression The regular expression to use.
 * @param errorMessage Required error message to display
 */
export function regex(
  expression: RegExp,
  errorMessage: string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    if (value) {
      const match = expression.exec(value);
      if (match === null || match === undefined) {
        return errorMessage;
      }
    }
    return undefined;
  };
}

/**
 * Returns a validator that checks if a number is greater than the provided bound
 * @param bound The bound
 * @param formatError a callback which takes the length and formats an appropriate error message for validation failed
 */
export function minValue(
  bound: number,
  formatError: (length: number) => string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    if (value) {
      const intValue: number = Number(value);
      if (!isNaN(intValue) && intValue < bound) {
        return formatError(intValue);
      }
    }
    return undefined;
  };
}

/**
 * Returns a validator that checks if a number is less than the provided bound
 * @param bound The bound
 * @param formatError a callback which takes the length and formats an appropriate error message for validation failed
 */
export function maxValue(
  bound: number,
  formatError: (length: number) => string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    if (value) {
      const intValue: number = Number(value);
      if (!isNaN(intValue) && intValue > bound) {
        return formatError(intValue);
      }
    }
    return undefined;
  };
}

/**
 * Returns a validator that checks if a number is an integer
 * @param errorMessage Required error message to display
 */
export function isInteger(
  errorMessage: string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    if (value) {
      if (Number(value) % 1 !== 0) {
        return errorMessage;
      }
    }
    return undefined;
  };
}

/**
 * Returns a validator that ensures the value is a number
 * @param errorMessage Required error message to display
 */
export function isNumber(
  errorMessage: string
): (value?: string | null) => string | undefined {
  return (value?: string | null): string | undefined => {
    if (value) {
      if (isNaN(Number(value))) {
        return errorMessage;
      }
    }
    return undefined;
  };
}

export function isValidEmail(
  email: string
): boolean {
  return !!String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

/**
 * Returns a validator that compares start and end date accordingly
 * @param errorMessage Required error message to display
 */
export function checkDateValidation(
  errorMessage: string
): (
    startDate?: Date | undefined,
    endDate?: Date | undefined
  ) => string | undefined {
  return (startDate?: Date, endDate?: Date): string | undefined => {
    if (startDate && endDate) {
      // Start date must not be more recent than end date or the same (returns 'true' if it is)
      if (startDate >= endDate) {
        return errorMessage;
      }
    }
    return '';
  };
}

export type IValidator = (value?: unknown) => string | undefined;
