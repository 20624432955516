export function getConfig() {
  return {
    domain: window.REACT_APP_AUTH0DOMAIN || 'login.ahau260.com',
    clientId: window.REACT_APP_AUTH0CLIENTID || 'WXPbRhrHN5Gj9ozi0ZsVZ9NQZKWWkHew',
    audience: window.REACT_APP_AUTH0AUDIENCE || 'https://api.ahau260.com/',
    clientUrl: window.REACT_APP_CLIENT_URL || 'http://localhost:3000/',
    clientApiUrl: window.REACT_APP_CLIENT_URL || 'http://localhost:7071/',
    apiUrl: window.REACT_APP_API_URL || 'https://api-dev.ahau260.com',
    checkoutUrl: window.REACT_APP_CHECKOUT_URL || 'https://release.app.ahau260.com/',
    stripeClientPortalUrl: window.REACT_APP_STRIPE_CLIENT_PORTAL_URL || 'https://billing.stripe.com/p/login/test_14k6rz7wl2CCb7i6oo',
    webAppUrl: window.REACT_APP_WEB_URL || 'https://ahau260.com',
    mixpanelToken: window.REACT_APP_MIXPANELTOKEN || 'efeb3e9550ebd308d8192d7317ccce7c'
  };
}

export const config = getConfig();
