/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationContainer } from './style';
import { fetchOrganizationDetails } from '../../utils/reducers/organizations';
import { selectAppState } from '../../utils/appReducer';
import { Loading } from '../Profile/Loading';

const SetupOrganization: React.FC = () => {
  const { id } = useParams() as { id?: number };
  const dispatch = useDispatch();
  const location = useLocation();

  const { initialized } = useSelector(selectAppState);

  // WTF?
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    id && initialized && dispatch(fetchOrganizationDetails(id));
  }, [id, initialized]);

  if (!initialized) {
    return <Loading />;
  }
  return (
    <OrganizationContainer />
  );
};

export default SetupOrganization;
