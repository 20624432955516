import React, { useState } from 'react';
import {
  centerDivStyle,
  resultRowContentContainerStyle,
  resultRowContentStyle,
  resultRowIconButtonStyle,
  resultRowStyle,
} from '../style';
import { FontIcon, IconButton } from '@fluentui/react';

interface ChannelResultProps {
  name: string | undefined;
  url: string | undefined;
  serviceProvider: string | undefined;
}

const getServiceProviderIcon = (filename: string): string => {
  const iconMappings: Record<string, string> = {
    Redmine: 'CDS',
  };

  const fileExtension = filename.split('.').pop()?.toLowerCase() || '';
  return iconMappings[fileExtension] || 'ExternalUser';
};

const ChannelResult: React.FC<ChannelResultProps> = ({
  name,
  url,
  serviceProvider,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(url, '_blank');
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...resultRowStyle,
        ...{
          borderLeft: isHovered ? '2px solid Teal' : '2px solid white',
        },
      }}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <div style={resultRowContentContainerStyle}>
        <div style={centerDivStyle}>
          <FontIcon
            aria-label="Search"
            iconName={
              serviceProvider
                ? getServiceProviderIcon(serviceProvider)
                : 'Group'
            }
            style={{ display: 'flex' }}
          />
        </div>
        <div style={resultRowContentStyle}>{name}</div>
        <div style={{ display: 'flex' }}>
          <IconButton
            iconProps={{
              iconName: 'Group',
              style: { color: 'Teal' },
            }}
            title='Open Channel'
            styles={resultRowIconButtonStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default ChannelResult;
