/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { matchPath } from 'react-router';
import { Loading } from '../Profile/Loading';
import {
  NavActionsContainer,
  OgrCreationStepContainer,
  OrganizationBody,
  OrganizationContainer,
  OrgCreationStepTitle
} from './style';
import { SetupOrgSteps } from '../../consts';
import { StepsHeader } from '../../components/Header/StepsHeader';
import { selectAppState } from '../../utils/appReducer';
import { Routes } from '../../consts/routes';

interface OrganizationContainerWrapperProps {
  title: string;
  currentStep: number;
  isLoading?: boolean;
  children: React.ReactNode;
  extraStyles?: CSSProperties;
  orgActions?: React.ReactNode;
}

const OrganizationSection: React.FC<OrganizationContainerWrapperProps> = (props) => {
  const { children, currentStep, isLoading, title, orgActions } = props;
  const { initialized } = useSelector(selectAppState);


  if (!initialized) {
    return <Loading />;
  }

  const isManageScreen = !!matchPath(
    window.location.pathname,
    Routes.membersManagement.path ?? ''
  );

  return (
    <OrganizationContainer>
      <OrganizationBody>
        {
          !isManageScreen && (
            <StepsHeader
              closeLinkTo="/"
              steps={SetupOrgSteps.ORGANIZE_USERS}
              currentStep={currentStep}
              displayGoBack={false}
            />
          )
        }
        <OgrCreationStepContainer style={props.extraStyles}>
          {
            isLoading ? (
              <Loading />
            ) : (
              <>
                <OrgCreationStepTitle>{title}</OrgCreationStepTitle>
                {children}
              </>
            )
          }
        </OgrCreationStepContainer>
      </OrganizationBody>
      {
        !isLoading && (
          <NavActionsContainer>
            {orgActions}
          </NavActionsContainer>
        )
      }
    </OrganizationContainer>
  );
};

export default OrganizationSection;