/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { SimpleForm } from '../../components/Forms';
import { AuthMethods, ConnectionSettingsOutputDto, ServiceProvider } from '../../gen/src/ahauOpenAPI';
import { IConnectionCredentials } from './ConnectionAuthorizeForm';
import { ConnectionHeaderSection } from './ConnectionHeaderSection';
import { UserCredentialsSection } from './UserCredentialsSection';
import { ConnectActionWitApiKeyContainer, ConnectionHeaderChangeButton } from './style';
import Input from '../Profile/Input';
import Button from '../../components/StyledButton';

interface IRedmineAuthorizeFormProps {
  serviceProvider: ServiceProvider;
  verifyErrorMessage?: unknown;
  userName?: string;
  password?: string;
  authMethod: AuthMethods;
  editData?: ConnectionSettingsOutputDto;
  onChange?: (args: IConnectionCredentials | undefined) => void;
  changeAuthMethod?: (authMethod: AuthMethods) => void;
  connectCredentials?: () => void;
}

export const RedmineAuthorizeForm: React.FC<IRedmineAuthorizeFormProps> = (
  props
) => {
  const [currentAuthMethod, setAuthMethod] = useState<AuthMethods>(
    props.authMethod
  );
  const [apiKeyValue, setApiKeyValue] = useState<string>('');
  const [
    credentialsValue,
    setCredentialsValue
  ] = useState<IConnectionCredentials>({
    userName: props.userName,
    password: props.password
  });

  React.useEffect(() => {
    setCredentialsValue({
      userName: props.userName
    });
  }, [props.userName]);

  const switchAuthMethod = () => {
    if (currentAuthMethod === AuthMethods.ApiKey) {
      return setAuthMethod(AuthMethods.Password);
    }
    return setAuthMethod(AuthMethods.ApiKey);
  };

  const onApiKeyChanged = (value?: string) => {
    if (value) {
      setApiKeyValue(value);
      return (
        props.onChange &&
        props.onChange({
          apiKey: value
        })
      );
    }
    return setApiKeyValue('');
  };

  const onCredentialsChanged = (value?: IConnectionCredentials) => {
    if (value) {
      setCredentialsValue(value);
      return (
        props.onChange &&
        props.onChange({
          userName: value.userName,
          password: value.password
        })
      );
    }
    return setCredentialsValue({});
  };

  return (
    <SimpleForm>
      <ConnectionHeaderSection
        {...props}
        webRoot={props.editData?.webRoot}
        headerContainerMargin="0.5rem 0 1.5rem"
      />

      <>
        {currentAuthMethod === AuthMethods.ApiKey && (
          <>
            <Input
              label="API Key"
              value={apiKeyValue}
              onChange={(e, value) => onApiKeyChanged(value)}
            />
          </>
        )}

        {currentAuthMethod === AuthMethods.Password && (
          <>
            <UserCredentialsSection
              {...props}
              userName={credentialsValue.userName}
              password={credentialsValue.password}
              passwordLength={props.editData?.passwordLength}
              label="Login"
              onChange={onCredentialsChanged}
              connectCredentials={props.connectCredentials}
              secondfieldLabel="Password"
            />
          </>
        )}
        {/* // Todo: In future add validation summary */}
        {/* <ValidationSummary messages={[props.verifyErrorMessage?.message]} /> */}
      </>
      <ConnectActionWitApiKeyContainer>
        <ConnectionHeaderChangeButton onClick={switchAuthMethod}>
          {currentAuthMethod === AuthMethods.ApiKey
            ? 'Switch to Login/Password'
            : 'Switch to API Access Key'}
        </ConnectionHeaderChangeButton>

        <Button
          onClick={() => {
            props.connectCredentials && props.connectCredentials();
          }}
          title="Connect"
        />
      </ConnectActionWitApiKeyContainer>
    </SimpleForm>
  );
};
